import React from 'react';
import PropTypes from 'prop-types';
import { Popover } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { COLORS } from '../../constants/colors';

function InfoPopover({ content, title }) {
  return (
    <Popover content={content} title={title} overlayStyle={{ maxWidth: '380px' }}>
      <InfoCircleOutlined style={{ color: COLORS.crateBlue }} />
    </Popover>
  );
}

InfoPopover.propTypes = {
  content: PropTypes.node.isRequired,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
};

export default InfoPopover;
