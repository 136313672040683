import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import { Input, Modal } from 'antd';
import { DeleteOutlined, PlusOutlined, WarningOutlined } from '@ant-design/icons';
import { Button, SubmitButtonGroup, Text } from '@cratedb/crate-gc-admin';
import CloudUITable from '../../../components/CloudUITable';
import SectionContainer from '../../../components/SectionContainer';
import { useGetClustersId, useGetMetaIpaddress } from '../../../swrHooks';
import {
  getClusterAsyncInProgress,
  getClusterAsyncIPAllowlistInProgress,
  getClusterIpWhitelist,
} from '../../../utils/data/cluster';
import { apiPut } from '../../../api';
import { isValidCIDR, normalizeWhitelist } from '../../../utils/data/IPs';

function IPAllowlist() {
  const { formatMessage } = useIntl();
  const { clusterId } = useParams();
  const { data: cluster, mutate: mutateCluster } = useGetClustersId(clusterId);
  const { data: userIPAddress } = useGetMetaIpaddress();
  const [isEditingMode, setIsEditingMode] = useState(false);
  const [draftWhitelist, setDraftWhitelist] = useState([]);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [updateInProgress, setUpdateInProgress] = useState(false);
  const asyncInProgress = getClusterAsyncInProgress(cluster);
  const allowlistAsyncInProgress = getClusterAsyncIPAllowlistInProgress(cluster);

  const handleAddRange = cidr => {
    // when adding a named range, place it in the first empty range if one exists
    const emptyRangeIndex = draftWhitelist.findIndex(range => range.cidr === '');
    if (cidr !== '' && emptyRangeIndex !== -1) {
      setDraftWhitelist(
        normalizeWhitelist([
          ...draftWhitelist.map((ipRange, index) => {
            if (index === emptyRangeIndex) {
              return { ...ipRange, cidr };
            }
            return ipRange;
          }),
        ]),
      );
      return;
    }

    // otherwise, just append it to the end
    setDraftWhitelist(
      normalizeWhitelist([...draftWhitelist, { cidr, description: '' }]),
    );
  };

  const handleCancelEditing = () => {
    setDraftWhitelist([]);
    setIsEditingMode(false);
  };

  const handleRemoveRange = ipRange => {
    setDraftWhitelist(
      normalizeWhitelist(draftWhitelist.filter(range => range.key !== ipRange.key)),
    );
  };

  const handleSave = async () => {
    // bail out if any of the values are invalid
    if (draftWhitelist.some(ipRange => !ipRange.isValid)) {
      return;
    }

    // show the confirmation modal if it's not already open
    if (!showConfirmationModal) {
      setDraftWhitelist(draftWhitelist.filter(ipRange => ipRange.cidr !== ''));
      setShowConfirmationModal(true);
      return;
    }

    // block the UI
    setShowConfirmationModal(false);
    setUpdateInProgress(true);

    // PUT to api
    const { success, data } = await apiPut(
      `/api/v2/clusters/${clusterId}/ip-restrictions/`,
      {
        ip_whitelist: draftWhitelist.map(ipRange => ({
          cidr: ipRange.cidr,
          description: ipRange.description,
        })),
      },
    );

    // if successful, update the cluster object and reset the form
    if (success) {
      mutateCluster(data);
      setDraftWhitelist([]);
      setIsEditingMode(false);
    }

    // reset the UI
    setUpdateInProgress(false);
  };

  const updateCIDR = (key, value) => {
    setDraftWhitelist(
      draftWhitelist.map(range => {
        if (range.key === key) {
          return {
            ...range,
            cidr: value.trim(),
            isValid: isValidCIDR(value.trim()),
          };
        }
        return range;
      }),
    );
  };

  const updateDescription = (key, value) => {
    setDraftWhitelist(
      draftWhitelist.map(range => {
        if (range.key === key) {
          return { ...range, description: value };
        }
        return range;
      }),
    );
  };

  const getColumns = () => {
    const columns = [
      {
        key: 'cidr',
        render: ipRange =>
          isEditingMode ? (
            <>
              <Input
                onChange={e => updateCIDR(ipRange.key, e.target.value)}
                onPressEnter={handleSave}
                placeholder={formatMessage({
                  id: 'cluster.clusterManage.ipAllowlistCIDRPlaceholder',
                })}
                status={!ipRange.isValid ? 'error' : null}
                value={ipRange.cidr}
                autoFocus
              />{' '}
              {!ipRange.isValid && (
                <div className="mt-1 text-sm text-red-500">
                  <FormattedMessage id="cluster.clusterManage.ipAllowlistCIDRErrorText" />
                </div>
              )}
            </>
          ) : (
            ipRange.cidr
          ),
        title: formatMessage({
          id: 'cluster.clusterManage.ipAllowlistCIDRColumnHeading',
        }),
        width: '35%',
      },
      {
        key: 'description',
        render: ipRange =>
          isEditingMode ? (
            <Input
              onChange={e => updateDescription(ipRange.key, e.target.value)}
              onPressEnter={handleSave}
              placeholder={formatMessage({
                id: 'cluster.clusterManage.ipAllowlistDescriptionPlaceholder',
              })}
              value={ipRange.description}
            />
          ) : (
            ipRange.description || '-'
          ),
        title: formatMessage({
          id: 'cluster.clusterManage.ipAllowlistDescriptionColumnHeading',
        }),
        width: isEditingMode ? '60%' : '70%',
      },
    ];

    if (isEditingMode) {
      columns.push({
        key: 'actions',
        render: ipRange => (
          <button
            className="mt-1 w-full text-crate-border-mid hover:text-crate-blue"
            data-testid="delete-existing-cidr-button"
            onClick={() => handleRemoveRange(ipRange)}
            type="button"
          >
            <DeleteOutlined />
          </button>
        ),
        width: '5%',
      });
    }

    return columns;
  };

  const renderIPAddressButtons = () => {
    if (!isEditingMode) {
      return null;
    }

    // disable the add button when there is an empty range in the editing form
    const disableAddButton = draftWhitelist.some(
      ipRange => ipRange.cidr.trim() === '',
    );

    // only display the "add user's ip" button if the user's address does not already exist in the list
    const showAddUserIPButton = !draftWhitelist.some(
      ipRange => ipRange.cidr === `${userIPAddress?.ip}/32`,
    );

    return (
      <div className="flex gap-8">
        <Button
          disabled={disableAddButton}
          kind={Button.kinds.TERTIARY}
          onClick={() => handleAddRange('')}
        >
          <PlusOutlined
            className={`${disableAddButton ? 'text-crate-border-mid' : 'text-crate-blue'} mr-1 text-sm`}
          />
          <span>
            <FormattedMessage id="cluster.clusterManage.addAnotherAddressButton" />
          </span>
        </Button>
        {showAddUserIPButton && (
          <Button
            kind={Button.kinds.TERTIARY}
            onClick={() => handleAddRange(`${userIPAddress.ip}/32`)}
          >
            <PlusOutlined className="mr-1 text-sm text-crate-blue" />
            <span>
              <FormattedMessage id="cluster.clusterManage.addPublicIPButton" />
            </span>
          </Button>
        )}
      </div>
    );
  };

  const renderMainButtons = () => {
    const isDisabled = asyncInProgress || cluster?.suspended;

    if (isEditingMode) {
      return (
        <SubmitButtonGroup
          cancelLabel={formatMessage({ id: 'common.cancel' })}
          confirmLabel={formatMessage({ id: 'common.save' })}
          disabled={isDisabled || draftWhitelist.some(ipRange => !ipRange.isValid)}
          onCancel={handleCancelEditing}
          onSubmit={handleSave}
        />
      );
    }

    return (
      <Button
        disabled={isDisabled}
        onClick={() => {
          const tmpWhitelist = getClusterIpWhitelist(cluster);
          if (tmpWhitelist.length === 0) {
            handleAddRange('');
          } else {
            setDraftWhitelist(tmpWhitelist);
          }
          setIsEditingMode(true);
        }}
        kind={Button.kinds.TERTIARY}
      >
        <FormattedMessage id="common.manage" />
      </Button>
    );
  };

  // if the cluster is suspended or an async operation is in progress, exit editing mode
  useEffect(() => {
    if (isEditingMode && (asyncInProgress || cluster?.suspended)) {
      setIsEditingMode(false);
    }
  }, [asyncInProgress, cluster, isEditingMode]);

  return (
    <>
      <SectionContainer
        actions={renderMainButtons()}
        loading={!cluster || updateInProgress || allowlistAsyncInProgress}
        title={
          <FormattedMessage id="cluster.clusterManage.ipAllowlistSectionTitle" />
        }
      >
        <Text className="mb-4">
          <FormattedMessage id="cluster.clusterManage.ipAllowlistSectionDescription" />
        </Text>

        <CloudUITable
          columns={getColumns()}
          dataSource={
            isEditingMode ? draftWhitelist : getClusterIpWhitelist(cluster)
          }
          emptyText={formatMessage({
            id: 'cluster.clusterManage.ipAllowlistEmptyTitle',
          })}
          rowClassName={() => 'align-top'}
          rowKey="key"
          showHeader
          testId="ipallowlist-table"
        />
        {renderIPAddressButtons()}
      </SectionContainer>

      <Modal
        closable={false}
        onCancel={() => setShowConfirmationModal(false)}
        onOk={handleSave}
        open={showConfirmationModal}
        title="Update IP allowlist"
      >
        <div className="flex gap-4">
          <div>
            <WarningOutlined
              className={`${draftWhitelist.length === 0 ? 'text-red-600' : 'text-orange-400'} text-2xl`}
            />
          </div>
          <Text className="w-full">
            {draftWhitelist.length === 0 && (
              <FormattedMessage id="cluster.clusterManage.saveEmptyWarningText" />
            )}
            {draftWhitelist.length > 0 && (
              <FormattedMessage id="cluster.clusterManage.saveWarningText" />
            )}
          </Text>
        </div>
      </Modal>
    </>
  );
}

export default IPAllowlist;
