import { PropsWithChildren, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { Loader, useJWTManagerStore } from '@cratedb/crate-gc-admin';
import cx from 'classnames';
import LoadingContainer from '../LoadingContainer';
import {
  useGCGetHealth,
  useGetClustersId,
  useGetMetaIpaddress,
} from '../../swrHooks';
import { getClusterGcUrl, isIpInClusterIpWhiteList } from '../../utils/data/cluster';
import GRANDCENTRAL_HEALTH from '../../constants/grandCentral';
import { CRATEDB_SUPPORT } from '../../constants/links';
import { clusterManage } from '../../constants/paths';

type JWTManagerWrapperProps = PropsWithChildren;

function JWTManagerWrapper({ children }: JWTManagerWrapperProps) {
  const { clusterId: urlClusterId, organizationId } = useParams();
  const { data: cluster, isLoading: clusterLoading } =
    useGetClustersId(urlClusterId);
  const { data: userIPAddress, isLoading: ipLoading } = useGetMetaIpaddress();
  const {
    data: grandCentralHealth,
    isLoading: healthLoading,
    error: healthError,
  } = useGCGetHealth(cluster ? getClusterGcUrl(cluster) : null);

  // JWTManager state
  const stateClusterId = useJWTManagerStore(state => state.clusterId);
  const tokenIsReady = useJWTManagerStore(state => state.tokenIsReady);
  const updateCluster = useJWTManagerStore(state => state.updateCluster);

  // only display the "add user's ip" button if the user's address does not already exist in the list
  const ipNotInWhitelist = !isIpInClusterIpWhiteList(cluster, userIPAddress);

  // update state when the cluster loads
  useEffect(() => {
    if (cluster?.id === urlClusterId) {
      updateCluster(cluster);
    }
  }, [cluster]);

  const renderIpNotInAllowlistPage = () => {
    return (
      <div className="absolute left-0 top-0 flex h-full w-full flex-col items-center justify-center gap-1 text-center">
        <div className="text-xl font-bold">
          <FormattedMessage id="jwtManagerWrapper.ipNotInAllowlist" />
        </div>
        <div>
          <FormattedMessage id="jwtManagerWrapper.yourIpAddress" /> (
          {userIPAddress.ip}){' '}
          <FormattedMessage id="jwtManagerWrapper.notInClusterAllowList" />{' '}
          <div>
            <Link
              to={clusterManage.build({
                clusterId: cluster.id,
                projectId: cluster.project_id,
                organizationId,
              })}
            >
              <FormattedMessage id="jwtManagerWrapper.manageAllowList" />
            </Link>{' '}
            <FormattedMessage id="jwtManagerWrapper.or" />{' '}
            <a href={CRATEDB_SUPPORT}>
              <FormattedMessage id="jwtManagerWrapper.contactOurSupportTeam" />
            </a>{' '}
            <FormattedMessage id="jwtManagerWrapper.forAssistance" />
          </div>
        </div>
      </div>
    );
  };

  const renderUnhealthyPage = () => {
    return (
      <div className="absolute left-0 top-0 flex h-full w-full flex-col items-center justify-center gap-1 text-center">
        <div className="text-xl font-bold">
          <FormattedMessage id="jwtManagerWrapper.serviceCurrentlyUnavailable" />
        </div>
        <div>
          <FormattedMessage id="jwtManagerWrapper.ifIssuePersists" />
          <a href={CRATEDB_SUPPORT}>
            <FormattedMessage id="jwtManagerWrapper.contactOurSupportTeam" />
          </a>{' '}
          <FormattedMessage id="jwtManagerWrapper.forAssistance" />
        </div>
        <div
          className={cx({
            invisible: !healthLoading,
          })}
        >
          <Loader />
        </div>
      </div>
    );
  };

  const renderPage = () => {
    // gc unhealthy
    if (
      !grandCentralHealth ||
      grandCentralHealth.health !== GRANDCENTRAL_HEALTH.OK ||
      healthError
    ) {
      // ip not in whitelist
      if (ipNotInWhitelist) {
        return renderIpNotInAllowlistPage();
      }

      return renderUnhealthyPage();
    }

    return children;
  };

  return (
    <LoadingContainer
      isViewContainer
      loaderAlignment={LoadingContainer.loaderAlignment.CENTER}
      loading={
        clusterLoading ||
        ipLoading ||
        (!grandCentralHealth && !healthError) ||
        !tokenIsReady ||
        urlClusterId != stateClusterId
      }
      render={renderPage}
    />
  );
}

export default JWTManagerWrapper;
