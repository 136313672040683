import { MenuTheme } from 'antd/lib/menu';
import { locationPathname } from 'src/utils';

function useThemedMenuProps(): {
  mode: 'inline';
  currentURLPath: string;
  theme: MenuTheme;
} {
  return {
    mode: 'inline',
    currentURLPath: locationPathname(),
    theme: 'dark',
  };
}

export default useThemedMenuProps;
