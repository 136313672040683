import React from 'react';
import { Code, Text } from '@cratedb/crate-gc-admin';
import CloudUISyntaxHighlighter from '../../../components/CloudUISyntaxHighlighter';

export const renderAnchor = id => <div id={id} className="relative top-[-45px]" />;

export const renderAnchorLink = (id, label) => (
  <a className="block pb-3" href={`#${id}`}>
    {label}
  </a>
);

export const renderBullet = (code, text) => (
  <li className="pl-2 pt-0.5">
    <div className="flex items-center gap-2">
      {code && (
        <>
          <Code>{code}</Code>
          <Text>-</Text>
        </>
      )}
      <Text>{text}</Text>
    </div>
  </li>
);

export const renderSQL = (cluster, code, onClick) => (
  <div className="pb-5 pt-3">
    <CloudUISyntaxHighlighter
      language="sql"
      linkUrl={`${cluster?.url}/#!/console?query=${encodeURIComponent(code)}`}
      onClick={onClick}
    >
      {code}
    </CloudUISyntaxHighlighter>
  </div>
);
