import React from 'react';
import { useParams } from 'react-router-dom';
import { Code, Heading, Text } from '@cratedb/crate-gc-admin';
import CloudUISyntaxHighlighter from '../../../../components/CloudUISyntaxHighlighter';
import OneClickImport from '../OneClickImport';
import { useGetClustersId } from '../../../../swrHooks';
import {
  renderAnchor,
  renderAnchorLink,
  renderBullet,
  renderSQL,
} from '../articleComponents';
import { useAnalytics } from '../../../../hooks';
import { TUTORIALS } from '../../../../constants/defaults';
import { USER_TRACKING_EVENTS } from '../../../../constants/segment';

const IMPORT_DATA_POST = [
  {
    destination: {
      create_table: true,
      table: 'marketing_data',
      table_definition: [
        {
          name: 'campaign_id',
          type: 'TEXT',
          extra: 'PRIMARY KEY',
        },
        {
          name: 'source',
          type: 'TEXT',
        },
        {
          name: 'metrics',
          type: 'OBJECT(DYNAMIC)',
          extra:
            'AS (clicks INTEGER, impressions INTEGER, conversion_rate DOUBLE PRECISION)',
        },
        {
          name: 'landing_page_url',
          type: 'TEXT',
        },
        {
          name: 'url_parts',
          type: 'GENERATED ALWAYS',
          extra: 'AS parse_url(landing_page_url)',
        },
      ],
    },
    compression: 'gzip',
    format: 'json',
    type: 'url',
    url: {
      url: 'https://github.com/crate/cratedb-datasets/raw/main/cloud-tutorials/data_marketing.json.gz',
    },
  },
];

function ObjectTypeMarketingArticle() {
  const { trackEvent } = useAnalytics();
  const { clusterId } = useParams();
  const { data: cluster } = useGetClustersId(clusterId);

  const trackRunQuery = id => {
    trackEvent(USER_TRACKING_EVENTS.RUN_QUERY_ADMIN_UI, {
      query: `${TUTORIALS.JSON_MARKETING_DATA} - ${id}`,
    });
  };

  return (
    <div className="flex items-start" data-testid="article-full-object-marketing">
      <div>
        {renderAnchor('introduction')}
        <Text className="pb-2">
          Marketers often need to handle multi-structured data from different
          platforms. CrateDB’s dynamic OBJECT data type allows us to store and
          analyze this complex, nested data efficiently. In this tutorial, we’ll
          explore how to leverage this feature in marketing data analysis, along with
          the use of generated columns to parse and manage URLs.
        </Text>
        <Text className="pb-2">
          Consider marketing data that captures details of various campaigns:
        </Text>
        <CloudUISyntaxHighlighter language="json">
          {`{
    "campaign_id": "c123",
    "source": "Google Ads",
    "metrics": {
        "clicks": 500,
        "impressions": 10000,
        "conversion_rate": 0.05
    },
    "landing_page_url": "https://example.com/products?utm_source=google"
}`}
        </CloudUISyntaxHighlighter>
        <Text className="pb-2">
          To begin, let’s create the schema for this dataset:
        </Text>
        {renderAnchor('create-table')}
        <OneClickImport
          buttonContents={
            <>
              <div className="leading-snug">Import marketing data</div>
              <div className="text-xs leading-snug opacity-80">
                1,000 rows, 214KiB
              </div>
            </>
          }
          cluster={cluster}
          importPOST={IMPORT_DATA_POST}
          manualContent={
            <>
              <Heading className="pb-2 pt-4" level={Heading.levels.h4}>
                Creating the table
              </Heading>
              <Text className="pb-2">
                CrateDB uses SQL, a powerful and familiar language for database
                management. To store the marketing data, create a table with columns
                tailored to the dataset using the <Code>CREATE TABLE</Code> command:
              </Text>
              {renderSQL(
                cluster,
                `CREATE TABLE marketing_data (
campaign_id TEXT PRIMARY KEY,
source TEXT,
metrics OBJECT(DYNAMIC) AS (
clicks INTEGER,
impressions INTEGER,
conversion_rate DOUBLE PRECISION
),
landing_page_url TEXT,
url_parts GENERATED ALWAYS AS parse_url(landing_page_url)
);`,
                () => trackRunQuery(1),
              )}
              <Text className="pb-2">In this table definition:</Text>
              <ol className="list-decimal pb-2 pl-10">
                {renderBullet(
                  null,
                  <span>
                    The <Code>metrics</Code> column is set up as an{' '}
                    <Code>OBJECT</Code>
                    featuring a dynamic structure. This enables you to perform
                    flexible queries on its nested attributes like clicks,
                    impressions, and conversion rate.
                  </span>,
                )}
                {renderBullet(
                  null,
                  <span>
                    Additionally, a generated column named <Code>url_parts</Code> is
                    configured to automatically parse the{' '}
                    <Code>landing_page_url</Code>. This makes it more convenient for
                    you to query specific components of the URL later on.
                  </span>,
                )}
              </ol>
              <Text className="pb-2">
                The table is designed to accommodate both fixed and dynamic
                attributes, providing a robust and flexible structure for storing
                your marketing data.
              </Text>
              <Heading className="pb-2 pt-4" level={Heading.levels.h4}>
                Inserting data
              </Heading>
              <Text className="pb-2">
                Now, insert the data using the <Code>COPY FROM</Code> SQL statement.
              </Text>
              {renderSQL(
                cluster,
                `COPY marketing_data
FROM 'https://github.com/crate/cratedb-datasets/raw/main/cloud-tutorials/data_marketing.json.gz'
WITH (format = 'json', compression='gzip');`,
                () => trackRunQuery(2),
              )}
            </>
          }
          successMessage={<div>Marketing data imported</div>}
          tutorialTrackingName={TUTORIALS.JSON_MARKETING_DATA}
        />

        {/* analysing data */}
        {renderAnchor('analyze-data')}
        <Heading className="pb-2 pt-4" level={Heading.levels.h4}>
          Analyzing data
        </Heading>
        <Text className="pb-2">
          Start with a basic <Code>SELECT</Code> statement on the{' '}
          <Code>metrics</Code> column, and limit the output to display only 10
          records, in order to quickly explore a few samples worth of data.
        </Text>
        {renderSQL(
          cluster,
          `SELECT metrics
FROM marketing_data
LIMIT 10;`,
          () => trackRunQuery(3),
        )}
        <Text className="pb-2">
          You can see that the <Code>metrics</Code> column returns an object in the
          form of a JSON. If you just want to return a single property of this
          object, you can adjust the query slightly by adding the property to the
          selection using bracket notation.
        </Text>
        {renderSQL(
          cluster,
          `SELECT metrics['clicks']
FROM marketing_data
LIMIT 10;`,
          () => trackRunQuery(4),
        )}
        <Text className="pb-2">
          It’s helpful to select individual properties from a nested object, but what
          if you also want to filter results based on these properties? For instance,
          to find <Code>campaign_id</Code> and <Code>source</Code> where
          <Code>conversion_rate</Code> exceeds <Code>0.09</Code>, employ the same
          bracket notation for filtering as well.
        </Text>
        {renderSQL(
          cluster,
          `SELECT campaign_id, source
FROM marketing_data
WHERE metrics['conversion_rate'] > 0.09
LIMIT 50;`,
          () => trackRunQuery(5),
        )}
        <Text className="pb-2">
          This allows you to narrow down the query results while still leveraging
          CrateDB’s ability to query nested objects effectively.
        </Text>
        <Text className="pb-2">
          Finally, let’s explore data aggregation based on UTM source parameters. The
          <Code>url_parts</Code> generated column, which is populated using the{' '}
          <Code>parse_url()</Code>
          function, automatically splits the URL into its constituent parts upon data
          insertion.
        </Text>
        <Text className="pb-2">
          To analyze the UTM source, you can directly query these parsed parameters.
          The goal is to count the occurrences of each UTM source and sort them in
          descending order. This lets you easily gauge marketing effectiveness for
          different sources, all while taking advantage of CrateDB’s powerful
          generated columns feature.
        </Text>
        {renderSQL(
          cluster,
          `SELECT
    url_parts['parameters']['utm_source'] AS utm_source,
    COUNT(*)
FROM marketing_data
GROUP BY 1
ORDER BY 2 DESC;`,
          () => trackRunQuery(6),
        )}
        <Text className="pb-2">
          In this tutorial, we explored the versatility and power of CrateDB’s
          dynamic <Code>OBJECT</Code> data type for handling complex, nested
          marketing data.
        </Text>
      </div>
      <div className="hidden min-w-[250px] pl-8 lg:block">
        {renderAnchorLink('top', 'Introduction')}
        {renderAnchorLink('create-table', 'Creating the table')}
        {renderAnchorLink('analyze-data', 'Analyzing data')}
      </div>
    </div>
  );
}

export default ObjectTypeMarketingArticle;
