import { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Form, Input, Select } from 'antd';
import { Button } from '@cratedb/crate-gc-admin';
import { postHubspotForm } from '../../../api';
import {
  HUBSPOT_FORMS,
  HUBSPOT_REGIONS_AWS,
  HUBSPOT_REGIONS_AZURE,
  HUBSPOT_REGIONS_GOOGLE,
  HubspotRegion,
} from '../../../constants/hubspot';
import { CRATEDB_EDGE_TUTORIALS_DOCUMENTATION } from '../../../constants/links';
import { useGetUsersMe } from '../../../swrHooks';
import useMessage from '../../../hooks/useMessage';
import awsLogo from '../../../assets/logo_aws.svg';
import azureLogo from '../../../assets/logo_azure_2021.svg';
import gcpLogo from '../../../assets/logo_gcp.svg';
import INPUT_SANITIZATION from '../../../constants/inputSanitization';

type HubspotFormRequestNewRegionFields = {
  custom_region?: string;
  email?: string;
  name?: string;
  phone?: string;
  predefined_region?: string;
  tell_us?: string;
};

export type HubspotFormRequestNewRegionProps = {
  hiddenFields?: { cluster_configuration?: string; description?: string };
  initialValues?: HubspotFormRequestNewRegionFields;
  onFormCancel: () => void;
  onFormComplete: () => void;
};

const predefinedRegions = () => {
  const appendRegionGroup = (
    providerName: string,
    providerAbbreviation: string,
    logo: React.ReactNode,
    regions: HubspotRegion[],
  ) => {
    return {
      label: providerName,
      options: regions
        .sort((a, b) => (a.name > b.name ? 1 : -1))
        .map(region => {
          return {
            label: (
              <div className="flex flex-row items-center">
                {logo}
                <span>{region.name}</span>
                <span className="truncate pl-2 text-crate-border-mid">
                  {region.code}
                </span>
              </div>
            ),
            value: `${providerAbbreviation.toLowerCase()}: ${region.code}`,
          };
        }),
    };
  };

  return [
    appendRegionGroup(
      'Amazon Web Services',
      'AWS',
      <img src={awsLogo} className="mr-2 h-8 w-8" alt="AWS logo" />,
      HUBSPOT_REGIONS_AWS,
    ),
    appendRegionGroup(
      'Azure',
      'Azure',
      <img src={azureLogo} className="my-1.5 mr-2 h-5 w-8" alt="Azure logo" />,
      HUBSPOT_REGIONS_AZURE,
    ),
    appendRegionGroup(
      'Google Cloud Platform',
      'GCP',
      <img src={gcpLogo} className="my-1.5 mr-2 h-5 w-8" alt="GCP logo" />,
      HUBSPOT_REGIONS_GOOGLE,
    ),
  ];
};

function HubspotFormRequestNewRegion({
  onFormCancel,
  onFormComplete,
  initialValues,
  hiddenFields,
}: HubspotFormRequestNewRegionProps) {
  const [form] = Form.useForm();
  const [showReset, setShowReset] = useState(false);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const { data: user } = useGetUsersMe();
  const { formatMessage } = useIntl();
  const { showErrorMessage, showSuccessMessage } = useMessage();

  const onFinish = async (values: HubspotFormRequestNewRegionFields) => {
    const response = await postHubspotForm(
      HUBSPOT_FORMS.CLOUD_CONSOLE_REQUEST_NEW_REGION,
      {
        ...values,
        ...hiddenFields,
      },
    );

    if (response.success) {
      showSuccessMessage(formatMessage({ id: 'hubspot.common.messageSuccess' }));
      setIsFormSubmitted(true);
    } else {
      showErrorMessage(formatMessage({ id: 'hubspot.common.messageFail' }));
    }
  };

  if (!user) {
    return null;
  }

  if (isFormSubmitted) {
    return (
      <div>
        <p className="pb-4">
          <FormattedMessage id="organization.regions.requestNewRegionConfirm1Text" />
        </p>
        <p className="pb-8">
          <FormattedMessage id="organization.regions.requestNewRegionConfirm2Text" />
        </p>
        <a
          className="block pb-8"
          href={CRATEDB_EDGE_TUTORIALS_DOCUMENTATION}
          target="_blank"
          rel="noreferrer"
        >
          <FormattedMessage id="organization.regions.requestNewRegionConfirm3Text" />
        </a>
        <div className="text-right" data-testid="close-modal-button">
          <Button
            onClick={() => {
              onFormComplete();
              setIsFormSubmitted(false);
            }}
          >
            <FormattedMessage id="common.ok" />
          </Button>
        </div>
      </div>
    );
  }

  return (
    <Form
      name="hubspot-request-private-link-form"
      aria-label="hubspot-request-private-link-form"
      data-testid="hubspot-request-private-link-form"
      onFinish={onFinish}
      autoComplete="off"
      layout="vertical"
      initialValues={{ email: user.email, name: user.name, ...initialValues }}
      form={form}
    >
      <div className="pb-4">
        <FormattedMessage id="organization.regions.requestNewRegionModalTitle" />
      </div>

      {/* name */}
      <Form.Item
        label={<FormattedMessage id="hubspot.common.name" />}
        name="name"
        rules={[INPUT_SANITIZATION.HUBSPOT_NAME]}
      >
        <Input />
      </Form.Item>

      {/* email */}
      <Form.Item
        label={<FormattedMessage id="common.email" />}
        name="email"
        rules={[INPUT_SANITIZATION.GENERIC_EMAIL]}
      >
        <Input />
      </Form.Item>

      {/* phone */}
      <Form.Item
        label={<FormattedMessage id="hubspot.common.phone" />}
        name="phone"
        rules={[INPUT_SANITIZATION.PHONE_OPTIONAL]}
      >
        <Input
          placeholder={formatMessage({
            id: 'hubspot.common.phoneIncludeCountryCode',
          })}
        />
      </Form.Item>

      {/* predefined regions */}
      <div className="flex flex-row items-center">
        <Form.Item
          className="flex-auto"
          label={
            <FormattedMessage id="organization.regions.requestNewRegionPredefinedListLabel" />
          }
          name="predefined_region"
        >
          <Select
            options={predefinedRegions()}
            onChange={() => {
              setShowReset(true);
            }}
          />
        </Form.Item>
        {showReset && (
          <Button
            className="mt-1 flex-none pr-0"
            type={Button.types.BUTTON}
            kind={Button.kinds.TERTIARY}
            size={Button.sizes.SMALL}
            onClick={() => {
              setShowReset(false);
              form.setFieldsValue({ predefined_region: null });
            }}
          >
            <FormattedMessage id="common.reset" />
          </Button>
        )}
      </div>

      {/* custom region */}
      <Form.Item
        label={
          <FormattedMessage id="organization.regions.requestNewRegionFreeText" />
        }
        name="custom_region"
        rules={[INPUT_SANITIZATION.GENERIC_OPTIONAL_TEXT]}
      >
        <Input
          placeholder={formatMessage({
            id: 'hubspot.common.optional',
          })}
        />
      </Form.Item>

      {/* message */}
      <Form.Item
        label={<FormattedMessage id="hubspot.common.message" />}
        name="tell_us"
        rules={[
          {
            ...INPUT_SANITIZATION.HUBSPOT_MESSAGE,
            required: false,
            whitespace: false,
          },
        ]}
      >
        <Input.TextArea
          autoSize={{ minRows: 4, maxRows: 8 }}
          placeholder={formatMessage({
            id: 'hubspot.common.optional',
          })}
        />
      </Form.Item>

      <div className="flex justify-end">
        <Button
          type={Button.types.BUTTON}
          onClick={onFormCancel}
          kind={Button.kinds.SECONDARY}
          size={Button.sizes.SMALL}
        >
          <FormattedMessage id="common.cancel" />
        </Button>
        <Button
          type={Button.types.SUBMIT}
          size={Button.sizes.SMALL}
          className="ml-2"
        >
          <FormattedMessage id="common.ok" />
        </Button>
      </div>
    </Form>
  );
}

export default HubspotFormRequestNewRegion;
