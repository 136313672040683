import React, { useState, useMemo } from 'react';
import { isEmpty } from 'lodash';
import { useIntl, FormattedMessage } from 'react-intl';
import moment from 'moment';
import { Select, Modal, Form, Checkbox } from 'antd';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { identity, orderBy } from 'lodash/fp';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Button, Heading, SubmitButtonGroup, Text } from '@cratedb/crate-gc-admin';

import { useAnalytics } from '../../../hooks';
import ConstrainWidth from '../../../components/ConstrainWidth/ConstrainWidth';
import CloudUITable from '../../../components/CloudUITable';
import SectionContainer from '../../../components/SectionContainer';
import NotificationAside from '../../../components/NotificationAside';
import TestSWRIsFetching from '../../../components/TestSWRIsFetching';
import CloudUISyntaxHighlighter from '../../../components/CloudUISyntaxHighlighter';
import {
  createFullClusterRestoreBackupSnippet,
  createTablesRestoreBackupSnippet,
} from '../../../constants/snippets/sql';
import EditBackupSchedule from '../EditBackupSchedule';
import { USER_TRACKING_EVENTS } from '../../../constants/segment';
import getTimeFilters from './timeFilters';
import {
  deployOrganizationCluster,
  organizationClustersList,
} from '../../../constants/paths';
import {
  useGetClustersId,
  useGetClustersIdSnapshots,
  useGetOrganizationsIdClusters,
  useGetProducts,
  useGetUsersMe,
} from '../../../swrHooks';
import {
  getClusterAsyncInProgress,
  getClusterIsShared,
} from '../../../utils/data/cluster';
import { getUserIsOrganizationAdmin } from '../../../utils/data/user';
import { DATETIME_FORMAT } from '../../../constants/dates';
import RichRadioInput from '../../../components/RichRadioInput/RichRadioInput';
import { apiPost } from '../../../api';
import useMessage from '../../../hooks/useMessage';

const RESTORE_SCHEMES = {
  ALL: 'ALL',
  TABLE: 'TABLE',
};

const getISODate = dayDiff => {
  const date = new Date();
  date.setDate(date.getDate() + dayDiff);
  return date.toISOString();
};

function ClusterBackups() {
  const navigate = useNavigate();
  const { clusterId, organizationId } = useParams();
  const { formatMessage } = useIntl();
  const [form] = Form.useForm();
  const { getFieldValue, resetFields } = form;
  const { trackEvent } = useAnalytics();
  const { showSuccessMessage } = useMessage();
  const [startDate] = useState(getISODate(0));
  const [endDate] = useState(getISODate(-14));

  const { data: user } = useGetUsersMe();
  const { data: cluster, isValidating: isClusterValidating } =
    useGetClustersId(clusterId);
  const { data: products } = useGetProducts();
  const { data: clusters, isValidating: isClustersValidating } =
    useGetOrganizationsIdClusters(organizationId);
  const { data: backups, isValidating: isBackupsValidating } =
    useGetClustersIdSnapshots(clusterId, startDate, endDate);

  const asyncOperationInProgress = getClusterAsyncInProgress(cluster);

  const userIsOrgAdmin = useMemo(
    () => getUserIsOrganizationAdmin(user, organizationId),
    [user, organizationId],
  );

  const [restoreBackupModalIsVisible, setRestoreBackupModalIsVisible] =
    useState(false);
  const [cloneClusterModalIsVisible, setCloneClusterModalIsVisible] =
    useState(false);
  const [
    cloneClusterModalSubmitButtonIsDisabled,
    setCloneClusterModalSubmitButtonIsDisabled,
  ] = useState(true);
  const [tablesModalIsVisible, setTablesModalIsVisible] = useState(false);
  const [selectedBackup, setSelectedBackup] = useState(null);
  const [selectedTables, setSelectedTables] = useState(null);
  const [editBackupScheduleDrawerIsVisible, setEditBackupScheduleDrawerIsVisible] =
    useState(false);
  const [restoreScheme, setRestoreScheme] = useState(RESTORE_SCHEMES.ALL);
  const [isSubmittingCloneForm, setIsSubmittingCloneForm] = useState(false);

  const backupsTimePeriod = 'all';
  const timeFilters = getTimeFilters(formatMessage);

  const prepareBackupsForPresentation = ({ tables, ...rest }) => ({
    tables: orderBy([identity], tables, tables),
    ...rest,
  });

  const cloneTargetClusters = useMemo(() => {
    if (cluster && clusters) {
      return clusters.filter(
        ({ id, suspended }) => id !== cluster.id && suspended === false,
      );
    }

    return [];
  }, [clusters, cluster]);

  const restoreBackupScript = useMemo(() => {
    if (!selectedBackup) {
      return '';
    }

    return restoreScheme === RESTORE_SCHEMES.ALL
      ? createFullClusterRestoreBackupSnippet(
          prepareBackupsForPresentation(selectedBackup),
        )
      : createTablesRestoreBackupSnippet(
          prepareBackupsForPresentation({
            ...selectedBackup,
            tables: selectedTables,
          }),
        );
  }, [restoreScheme, selectedBackup, selectedTables]);

  const handleCloseRestoreModal = () => {
    setRestoreBackupModalIsVisible(false);
    setRestoreScheme(RESTORE_SCHEMES.ALL);
    setSelectedBackup(null);
    setSelectedTables(null);
  };

  const handleCloneClusterTargetChanged = tables => {
    setCloneClusterModalSubmitButtonIsDisabled(
      restoreScheme === RESTORE_SCHEMES.TABLE && isEmpty(tables),
    );
  };

  const handleCloseCloneModal = () => {
    resetFields(['cloneTarget']);
    resetFields(['tables']);
    setCloneClusterModalIsVisible(false);
    setRestoreScheme(RESTORE_SCHEMES.ALL);
    setSelectedBackup(null);
    setSelectedTables(null);
    setCloneClusterModalSubmitButtonIsDisabled(true);
  };

  const handleShowTablesInBackup = backup => {
    setSelectedBackup(prepareBackupsForPresentation(backup));
    setTablesModalIsVisible(true);
  };

  const handleCloseTablesModal = () => {
    setTablesModalIsVisible(false);
    setSelectedBackup(null);
  };

  const getDisplayNameByTimePeriod = chosenValue =>
    timeFilters.find(({ value }) => value === chosenValue).displayName;

  const toggleEditBackupScheduleDrawer = () => {
    setEditBackupScheduleDrawerIsVisible(!editBackupScheduleDrawerIsVisible);
  };

  const handleCloneClusterFormFinish = async ({ cloneTarget: targetClusterId }) => {
    setIsSubmittingCloneForm(true);
    const { success } = await apiPost(
      `/api/v2/clusters/${targetClusterId}/snapshots/restore/`,
      {
        source_cluster_id: cluster.id,
        repository: selectedBackup.repository,
        snapshot: selectedBackup.snapshot,
        tables: restoreScheme === RESTORE_SCHEMES.TABLE ? selectedTables : undefined,
      },
    );

    if (success) {
      showSuccessMessage(
        `cloning ${cluster.name} to ${
          clusters.find(({ id }) => id === targetClusterId).name
        }`,
      );
    } else {
      setIsSubmittingCloneForm(false);
      return;
    }

    setIsSubmittingCloneForm(false);
    handleCloseCloneModal();
    navigate(organizationClustersList.build({ organizationId }));
  };

  const handleRestoreSchemeChange = event => {
    setRestoreScheme(event.target.value);
  };

  const handleSelectedTablesCheckboxGroupChange = checkedOptions => {
    setSelectedTables(checkedOptions);
    setCloneClusterModalSubmitButtonIsDisabled(
      !getFieldValue('cloneTarget') ||
        (restoreScheme === RESTORE_SCHEMES.TABLE && isEmpty(checkedOptions)),
    );
  };

  const handleSelectAllTables = () => {
    handleSelectedTablesCheckboxGroupChange(selectedBackup.tables);
  };
  const handleDeselectAllTables = () => {
    handleSelectedTablesCheckboxGroupChange([]);
  };

  const columns = [
    {
      title: 'Timestamp',
      key: 'timestamp',
      render: ({ created }) => {
        return <Text>{moment.utc(created).format(DATETIME_FORMAT)}</Text>;
      },
      width: '35%',
    },
    {
      title: 'Tables',
      key: 'tables',
      render: backup => {
        return (
          <>
            <FormattedMessage
              id="cluster.clusterBackups.tablesInBackupText"
              values={{ num: backup.tables.length }}
            />

            {!isEmpty(backup.tables) && (
              <button
                className="ml-1 text-crate-blue"
                kind={Button.kinds.TERTIARY}
                onClick={() => handleShowTablesInBackup(backup)}
                type="button"
              >
                <FormattedMessage id="cluster.clusterBackups.showTablesInBackupButton" />
              </button>
            )}
          </>
        );
      },
      width: '55%',
    },
    {
      title: 'Action',
      key: 'actions',
      // eslint-disable-next-line react/prop-types
      render: backup => {
        return (
          <div className="flex gap-4">
            <Button
              kind={Button.kinds.TERTIARY}
              onClick={() => {
                setRestoreBackupModalIsVisible(true);
                setSelectedBackup(prepareBackupsForPresentation(backup));
                setSelectedTables(backup.tables);
              }}
              size={Button.sizes.SMALL}
            >
              <FormattedMessage id="cluster.clusterBackups.restoreButton" />
            </Button>
            <Button
              kind={Button.kinds.TERTIARY}
              onClick={() => {
                setCloneClusterModalIsVisible(true);
                setSelectedBackup(prepareBackupsForPresentation(backup));
                setSelectedTables(backup.tables);
              }}
              size={Button.sizes.SMALL}
            >
              <FormattedMessage id="cluster.clusterBackups.cloneFromBackupButton" />
            </Button>
          </div>
        );
      },
      width: '10%',
    },
  ];

  return (
    <ConstrainWidth>
      <SectionContainer
        actions={
          userIsOrgAdmin &&
          cluster &&
          products &&
          !getClusterIsShared(cluster, products) && (
            <Button
              disabled={
                !(cluster && clusters && backups) || asyncOperationInProgress
              }
              id="edit-backup-schedule"
              kind={Button.kinds.TERTIARY}
              onClick={toggleEditBackupScheduleDrawer}
            >
              <FormattedMessage id="cluster.clusterBackups.editBackupScheduleButton" />
            </Button>
          )
        }
        loading={!(cluster && clusters && backups)}
        title={formatMessage({ id: 'cluster.clusterBackups.sectionTitle' })}
      >
        <CloudUITable
          showHeader
          columns={columns}
          dataSource={backups}
          emptyText={formatMessage({
            id: 'cluster.clusterBackups.noBackupsFailoverText',
          })}
          rowKey="created"
          bordered
          pagination={!!backups && backups.length > 10}
        />
      </SectionContainer>

      {/* Restore backup dialog */}
      <Modal
        onCancel={handleCloseRestoreModal}
        open={restoreBackupModalIsVisible}
        title={
          <FormattedMessage id="cluster.clusterBackups.restoreBackupModalTitle" />
        }
        okButtonProps={{ visible: false }}
        cancelButtonProps={{ disabled: true }}
        footer={
          <Button kind={Button.kinds.SECONDARY} onClick={handleCloseRestoreModal}>
            <FormattedMessage id="common.close" />
          </Button>
        }
        width={900}
      >
        <div className="flex gap-4">
          <RichRadioInput
            ariaControls="backupScheme"
            checked={restoreScheme === RESTORE_SCHEMES.ALL}
            description={
              <FormattedMessage id="cluster.clusterBackups.restoreSchemeRadioAllText" />
            }
            onClick={handleRestoreSchemeChange}
            title={
              <FormattedMessage id="cluster.clusterBackups.restoreSchemeRadioAllTitle" />
            }
            value={RESTORE_SCHEMES.ALL}
          />
          <RichRadioInput
            ariaControls="backupScheme"
            checked={restoreScheme === RESTORE_SCHEMES.TABLE}
            description={
              <FormattedMessage id="cluster.clusterBackups.restoreSchemeRadioTablesText" />
            }
            disabled={selectedBackup?.tables.length === 0}
            disabledDescription={
              <FormattedMessage id="cluster.clusterBackups.restoreSchemeRadioTablesDisabledText" />
            }
            onClick={handleRestoreSchemeChange}
            title={
              <FormattedMessage id="cluster.clusterBackups.restoreSchemeRadioTablesTitle" />
            }
            value={RESTORE_SCHEMES.TABLE}
          />
        </div>

        <div role="region" id="backupScheme" aria-live="polite">
          {restoreScheme === RESTORE_SCHEMES.TABLE && selectedBackup && (
            <div className="mb-6">
              <div className="max-h-[180px] overflow-auto rounded bg-neutral-100 p-4">
                <Checkbox.Group
                  onChange={handleSelectedTablesCheckboxGroupChange}
                  value={selectedTables}
                >
                  {selectedBackup.tables.map(table => (
                    <div key={table} className="mb-2 last:mb-0">
                      <Checkbox checked value={table}>
                        {table}
                      </Checkbox>
                    </div>
                  ))}
                </Checkbox.Group>
              </div>

              <div className="flex items-center justify-end gap-2">
                <Button
                  disabled={selectedTables.length === 0}
                  id="deselect-all-button"
                  kind={Button.kinds.TERTIARY}
                  onClick={handleDeselectAllTables}
                >
                  <FormattedMessage id="cluster.clusterBackups.deselectAllOptionsButton" />
                </Button>

                <Button
                  disabled={selectedTables.length === selectedBackup.tables.length}
                  id="select-all-button"
                  kind={Button.kinds.TERTIARY}
                  onClick={handleSelectAllTables}
                >
                  <FormattedMessage id="cluster.clusterBackups.selectAllOptionsButton" />
                </Button>
              </div>
            </div>
          )}

          <div className="mb-6">
            <Heading className="mb-2" level={Heading.levels.h4}>
              <FormattedMessage id="cluster.clusterBackups.restoreChecklistTitle" />
            </Heading>
            <ul className="pl-6 text-base text-neutral-500">
              {restoreScheme === RESTORE_SCHEMES.ALL && (
                <li className="mb-2">
                  <ExclamationCircleOutlined className="mr-2 text-base text-amber-500" />
                  <FormattedMessage id="cluster.clusterBackups.restoreChecklistFullClusterBreakdownText" />
                </li>
              )}
              <li className="mb-2">
                <ExclamationCircleOutlined className="mr-2 text-base text-amber-500" />
                <FormattedMessage id="cluster.clusterBackups.restoreChecklistDiskSpaceWarningText" />
              </li>
              <li className="mb-2">
                <ExclamationCircleOutlined className="mr-2 text-base text-amber-500" />
                <FormattedMessage id="cluster.clusterBackups.restoreChecklistExistingTablesWarningText" />
              </li>
            </ul>
          </div>

          {restoreScheme === RESTORE_SCHEMES.TABLE &&
          selectedTables?.length === 0 ? (
            <div>
              <NotificationAside
                message={formatMessage({
                  id: 'cluster.clusterBackups.noTablesSelectedForBackupWarningHelp',
                })}
                type={NotificationAside.types.WARN}
              />
            </div>
          ) : (
            <CloudUISyntaxHighlighter
              language="pgsql"
              title={
                <FormattedMessage id="cluster.clusterBackups.restoreBackupModalSnippetTitle" />
              }
              linkUrl={`${cluster?.url}/#!/console?query=${encodeURI(
                restoreBackupScript,
              )}`}
              onClick={() => trackEvent(USER_TRACKING_EVENTS.RESTORE_BACKUP_CLICKED)}
            >
              {restoreBackupScript}
            </CloudUISyntaxHighlighter>
          )}
        </div>
      </Modal>

      {/* Clone cluster dialog */}
      <Modal
        onCancel={handleCloseCloneModal}
        open={cloneClusterModalIsVisible}
        title={
          <FormattedMessage id="cluster.clusterBackups.cloneFromBackupModalTitle" />
        }
        okButtonProps={{ visible: false }}
        cancelButtonProps={{ disabled: true }}
        footer={
          isEmpty(cloneTargetClusters) ? (
            <Button kind={Button.kinds.SECONDARY} onClick={handleCloseCloneModal}>
              <FormattedMessage id="common.close" />
            </Button>
          ) : (
            <SubmitButtonGroup
              confirmLabel={
                <FormattedMessage id="cluster.clusterBackups.cloneFromBackupModalConfirmButton" />
              }
              cancelLabel={<FormattedMessage id="common.close" />}
              disabled={cloneClusterModalSubmitButtonIsDisabled}
              form="clone-cluster-form"
              onCancel={handleCloseCloneModal}
              loading={isSubmittingCloneForm}
            />
          )
        }
        width={900}
      >
        {isEmpty(cloneTargetClusters) ? (
          <FormattedMessage
            id="cluster.clusterBackups.cloneFromBackupModalNoClustersText"
            values={{
              link: (
                <Link
                  to={deployOrganizationCluster.build({
                    organizationId,
                  })}
                >
                  <FormattedMessage id="cluster.clusterBackups.cloneFromBackupModalDeployNewLink" />
                </Link>
              ),
            }}
          />
        ) : (
          <Form
            id="clone-cluster-form"
            layout="vertical"
            form={form}
            name="clone cluster form"
            onFinish={handleCloneClusterFormFinish}
          >
            <div className="flex gap-4">
              <RichRadioInput
                ariaControls="cloneScheme"
                checked={restoreScheme === RESTORE_SCHEMES.ALL}
                description={
                  <FormattedMessage id="cluster.clusterBackups.restoreSchemeRadioAllText" />
                }
                onClick={handleRestoreSchemeChange}
                title={
                  <FormattedMessage id="cluster.clusterBackups.restoreSchemeRadioAllTitle" />
                }
                value={RESTORE_SCHEMES.ALL}
              />
              <RichRadioInput
                ariaControls="cloneScheme"
                checked={restoreScheme === RESTORE_SCHEMES.TABLE}
                description={
                  <FormattedMessage id="cluster.clusterBackups.restoreSchemeRadioTablesText" />
                }
                disabled={selectedBackup?.tables.length === 0}
                disabledDescription={
                  <FormattedMessage id="cluster.clusterBackups.restoreSchemeRadioTablesDisabledText" />
                }
                onClick={handleRestoreSchemeChange}
                title={
                  <FormattedMessage id="cluster.clusterBackups.restoreSchemeRadioTablesTitle" />
                }
                value={RESTORE_SCHEMES.TABLE}
              />
            </div>

            <div aria-live="polite" id="cloneScheme" role="region">
              {restoreScheme === RESTORE_SCHEMES.TABLE && selectedBackup && (
                <div className="mb-6">
                  <Checkbox.Group
                    onChange={handleSelectedTablesCheckboxGroupChange}
                    style={{ width: '100%' }}
                    value={selectedTables}
                  >
                    <div className="rounded bg-neutral-100 px-6 py-4">
                      <div className="max-h-[144px] overflow-auto">
                        {selectedBackup.tables.map(table => (
                          <div key={table} className="mb-2 last:mb-0">
                            <Checkbox checked value={table}>
                              {table}
                            </Checkbox>
                          </div>
                        ))}
                      </div>
                    </div>
                  </Checkbox.Group>
                  <div className="flex items-center justify-end gap-2">
                    <Button
                      disabled={selectedTables.length === 0}
                      id="deselect-all-button"
                      kind={Button.kinds.TERTIARY}
                      onClick={handleDeselectAllTables}
                    >
                      <FormattedMessage id="cluster.clusterBackups.deselectAllOptionsButton" />
                    </Button>

                    <Button
                      disabled={
                        selectedTables.length === selectedBackup.tables.length
                      }
                      id="select-all-button"
                      kind={Button.kinds.TERTIARY}
                      onClick={handleSelectAllTables}
                    >
                      <FormattedMessage id="cluster.clusterBackups.selectAllOptionsButton" />
                    </Button>
                  </div>
                </div>
              )}
              <div className="mb-6">
                <Heading className="mb-2" level={Heading.levels.h4}>
                  <FormattedMessage id="cluster.clusterBackups.restoreChecklistTitle" />
                </Heading>
                <ul className="pl-6 text-base text-neutral-500">
                  {restoreScheme === RESTORE_SCHEMES.ALL && (
                    <li className="mb-2">
                      <ExclamationCircleOutlined className="mr-2 text-base text-amber-500" />
                      <FormattedMessage id="cluster.clusterBackups.restoreChecklistFullClusterBreakdownText" />
                    </li>
                  )}
                  <li className="mb-2">
                    <ExclamationCircleOutlined className="mr-2 text-base text-amber-500" />
                    <FormattedMessage id="cluster.clusterBackups.restoreChecklistDiskSpaceWarningText" />
                  </li>
                  <li className="mb-2">
                    <ExclamationCircleOutlined className="mr-2 text-base text-amber-500" />
                    <FormattedMessage id="cluster.clusterBackups.restoreChecklistExistingTablesWarningText" />
                  </li>
                </ul>
              </div>
              {selectedTables?.length === 0 &&
              restoreScheme === RESTORE_SCHEMES.TABLE ? (
                <div className="mb-7">
                  <NotificationAside
                    message={formatMessage({
                      id: 'cluster.clusterBackups.noTablesSelectedForBackupWarningHelp',
                    })}
                    type={NotificationAside.types.WARN}
                  />
                </div>
              ) : (
                <>
                  <Form.Item colon={false} name="cloneTarget">
                    <Select
                      onChange={() =>
                        handleCloneClusterTargetChanged(selectedTables)
                      }
                      placeholder={
                        <FormattedMessage id="cluster.clusterBackups.cloneFromBackupModalSelectPlaceholder" />
                      }
                      value={getDisplayNameByTimePeriod(backupsTimePeriod)}
                    >
                      {cloneTargetClusters.map(({ name, id }) => (
                        <Select.Option key={id} value={id}>
                          {name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <div className="relative bottom-4 text-right text-neutral-500">
                    <FormattedMessage
                      id="cluster.clusterBackups.cloneFromBackupModalDeployNewText"
                      values={{
                        link: (
                          <Link
                            to={deployOrganizationCluster.build({
                              organizationId,
                            })}
                          >
                            <FormattedMessage id="cluster.clusterBackups.cloneFromBackupModalDeployNewLink" />
                          </Link>
                        ),
                      }}
                    />
                  </div>
                </>
              )}
            </div>
          </Form>
        )}
      </Modal>

      {/* Tables in the backup dialog */}
      <Modal
        onCancel={handleCloseTablesModal}
        open={tablesModalIsVisible}
        title={
          selectedBackup && (
            <FormattedMessage
              id="cluster.clusterBackups.tablesInBackupModalTitle"
              values={{
                length: selectedBackup.tables.length,
                created: moment.utc(selectedBackup.created).format(DATETIME_FORMAT),
              }}
            />
          )
        }
        okButtonProps={{ visible: false }}
        cancelButtonProps={{ disabled: true }}
        footer={
          <Button kind={Button.kinds.SECONDARY} onClick={handleCloseTablesModal}>
            <FormattedMessage id="common.close" />
          </Button>
        }
        width={900}
      >
        <div className="max-h-[300px] overflow-auto border border-crate-border-light">
          {selectedBackup &&
            selectedBackup.tables.map(table => (
              <div
                key={table}
                className="border-b border-crate-border-light p-2 odd:bg-neutral-100 even:bg-neutral-200"
              >
                {table}
              </div>
            ))}
        </div>
      </Modal>

      {/* backup schedule popout */}
      {cluster && (
        <EditBackupSchedule
          cluster={cluster}
          onClose={toggleEditBackupScheduleDrawer}
          isVisible={editBackupScheduleDrawerIsVisible}
        />
      )}

      {/* testing only */}
      <TestSWRIsFetching
        fetchStatusList={[
          isClusterValidating,
          isClustersValidating,
          isBackupsValidating,
        ]}
      />
    </ConstrainWidth>
  );
}

export default ClusterBackups;
