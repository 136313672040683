import { Heading, UsersTable } from '@cratedb/crate-gc-admin';
import JWTManagerWrapper from 'src/components/JWTManagerWrapper';
import SectionContainer from 'src/components/SectionContainer';
import { FormattedMessage } from 'react-intl';

function ClusterUsers() {
  return (
    <JWTManagerWrapper>
      <SectionContainer className="absolute !mb-0 h-full w-full overflow-x-auto rounded-none !pb-0">
        <Heading className="mb-2" level={Heading.levels.h2}>
          <FormattedMessage id="clusterUsers.clusterUsers" />
        </Heading>
        <UsersTable />
      </SectionContainer>
    </JWTManagerWrapper>
  );
}

export default ClusterUsers;
