import { Text } from '@cratedb/crate-gc-admin';

export type SettingsOptionProps = {
  label?: string;
  title: string;
  read?: string;
  edit?: React.ReactNode | React.ReactNode[];
  isEditing?: boolean;
};

function SettingsOption({
  label,
  title,
  read,
  edit,
  isEditing = false,
}: SettingsOptionProps) {
  return (
    <div aria-label={label} className="mt-4">
      <div className="mb-2">{title}</div>
      {isEditing && edit ? edit : <Text pale>{read}</Text>}
    </div>
  );
}

export default SettingsOption;
