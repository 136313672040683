import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Checkbox, Form } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import moment from 'moment';
import { isEmpty, isNull } from 'lodash/fp';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Button, SubmitButtonGroup, Text } from '@cratedb/crate-gc-admin';
import CloudUIDrawer, {
  CloudUIDrawerFooterControlsContainer,
} from '../../../components/CloudUIDrawer';
import { clusterPropType } from '../../../models';
import { useGetClustersId } from '../../../swrHooks';
import { apiPut } from '../../../api';
import useMessage from '../../../hooks/useMessage';

function EditBackupSchedule({ cluster, isVisible, onClose }) {
  const { formatMessage } = useIntl();
  const [submitIsDisabled, setSubmitIsDisabled] = useState(true);
  const [showDeselectedValidation, setShowDeselectedValidation] = useState(false);
  const [form] = Form.useForm();
  const { setFieldsValue, resetFields } = form;
  const { showSuccessMessage } = useMessage();
  const { mutate: mutateCluster } = useGetClustersId(cluster.id);

  const allHours = useMemo(() => [...Array(24).keys()], []);

  const existingSchedule = useMemo(() => {
    if (isNull(cluster.backup_schedule)) {
      return allHours;
    }
    // backup schedule _may_ be null in which case we
    // assume the default schedule of every hour.
    // backup schedule hours may also be a glob where the
    // second item is '*' e.g. 45 * * * * in which case select all options.
    // Or it may be a list of hours e.g. 45 1,2,7,17,23 * * * in which case
    // parse the hours into a list of preselected options.
    const scheduledHours = cluster.backup_schedule.split(' ').slice(1, 2)[0];
    return scheduledHours === '*'
      ? allHours
      : scheduledHours.split(',').map(hour => parseInt(hour, 10));
  }, [allHours, cluster.backup_schedule]);

  useEffect(() => {
    // useEffect is required for when the async operation succeeds
    // an you need to update the value of the checked checkboxes
    // as the form is reset on close.
    setFieldsValue({ backupSchedule: existingSchedule });
  }, [setFieldsValue, existingSchedule]);

  const handleClose = () => {
    onClose();
    resetFields();
    setSubmitIsDisabled(true);
    setShowDeselectedValidation(false);
  };

  const handleSelectAll = () => {
    setFieldsValue({ backupSchedule: allHours });
    setSubmitIsDisabled(false);
    setShowDeselectedValidation(false);
  };

  const handleDeselectAll = () => {
    setFieldsValue({ backupSchedule: [] });
    setSubmitIsDisabled(true);
    setShowDeselectedValidation(true);
  };

  const handleCheckboxChange = newSchedule => {
    setSubmitIsDisabled(false);
    setShowDeselectedValidation(isEmpty(newSchedule));
  };

  const handleFormFinish = async ({ backupSchedule }) => {
    handleClose();

    const { success, data } = await apiPut(
      `/api/v2/clusters/${cluster.id}/backup-schedule/`,
      {
        backup_hours: backupSchedule.join(','),
      },
    );

    if (success) {
      showSuccessMessage(
        formatMessage({
          id: 'cluster.clusterBackups.backupScheduleUpdateInProgressText',
        }),
      );
      mutateCluster(data);
    }
  };

  return (
    <CloudUIDrawer
      onClose={handleClose}
      title={
        <FormattedMessage id="cluster.clusterBackups.editBackupScheduleUITitle" />
      }
      visible={isVisible}
    >
      <Form
        layout="vertical"
        aria-label="edit a clusters backup schedule form"
        form={form}
        onFinish={handleFormFinish}
      >
        <Text className="mb-6">
          <FormattedMessage id="cluster.clusterBackups.backupStartTimeText" />
        </Text>
        <Form.Item name="backupSchedule" initialValue={existingSchedule}>
          <Checkbox.Group style={{ width: '100%' }} onChange={handleCheckboxChange}>
            <div className="flex h-[140px] flex-col flex-wrap gap-4">
              {allHours.map(hour => (
                <div key={hour}>
                  <Checkbox value={hour}>
                    {moment().startOf('day').add(hour, 'hours').format('HH:mm')}
                  </Checkbox>
                </div>
              ))}
            </div>
          </Checkbox.Group>
        </Form.Item>
        <div className="flex items-center justify-between">
          <div className="flex gap-2">
            <Button kind={Button.kinds.TERTIARY} onClick={handleSelectAll}>
              <FormattedMessage id="cluster.clusterBackups.selectAllOptionsButton" />
            </Button>
            <Button kind={Button.kinds.TERTIARY} onClick={handleDeselectAll}>
              <FormattedMessage id="cluster.clusterBackups.deSelectAllTimeSlotsButton" />
            </Button>
          </div>
          <div>
            <div className="text-base">
              <FormattedMessage id="cluster.clusterBackups.timeFormatHelp" />
            </div>
          </div>
        </div>
        {showDeselectedValidation && (
          <div
            className="relative mt-4 bg-red-400 py-4 pl-10 text-neutral-100"
            role="alert"
          >
            <ExclamationCircleOutlined className="absolute left-4 top-1/2 mr-2 -translate-y-1/2 text-base text-neutral-100" />
            <div className="text-sm leading-4">
              <FormattedMessage id="cluster.clusterBackups.allSlotsDeselectedHelp" />
            </div>
          </div>
        )}
        <CloudUIDrawerFooterControlsContainer>
          <SubmitButtonGroup
            confirmLabel={<FormattedMessage id="common.save" />}
            disabled={submitIsDisabled}
            onCancel={handleClose}
          />
        </CloudUIDrawerFooterControlsContainer>
      </Form>
    </CloudUIDrawer>
  );
}

EditBackupSchedule.propTypes = {
  cluster: clusterPropType.isRequired,
  onClose: PropTypes.func.isRequired,
  isVisible: PropTypes.bool.isRequired,
};

export default EditBackupSchedule;
