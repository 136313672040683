import { FormattedMessage } from 'react-intl';
import {
  ArrowUpOutlined,
  CommentOutlined,
  FileTextOutlined,
  PlaySquareOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons';
import {
  CRATEDB_COMMUNITY,
  CRATEDB_CONSOLE_DOCUMENTATION,
  CRATEDB_SUPPORT,
  CRATEDB_ACADEMY,
} from './links';
import { ItemType } from 'antd/es/menu/interface';

export default [
  {
    icon: <FileTextOutlined />,
    key: 'documentation',
    label: (
      <a href={CRATEDB_CONSOLE_DOCUMENTATION} target="_blank" rel="noreferrer">
        <FormattedMessage id="sideMenu.documentationLink" />
        <ArrowUpOutlined
          rotate={45}
          className="relative bottom-1 left-0.5 opacity-50"
        />
      </a>
    ),
  },
  {
    icon: <CommentOutlined />,
    key: 'community',
    label: (
      <a href={CRATEDB_COMMUNITY} target="_blank" rel="noreferrer">
        <FormattedMessage id="sideMenu.communityLink" />
        <ArrowUpOutlined
          rotate={45}
          className="relative bottom-1 left-0.5 opacity-50"
        />
      </a>
    ),
  },
  {
    icon: <QuestionCircleOutlined />,
    key: 'support',
    label: (
      <a href={CRATEDB_SUPPORT} target="_blank" rel="noreferrer">
        <FormattedMessage id="sideMenu.supportLink" />
        <ArrowUpOutlined
          rotate={45}
          className="relative bottom-1 left-0.5 opacity-50"
        />
      </a>
    ),
  },
  {
    icon: <PlaySquareOutlined />,
    key: 'academy',
    label: (
      <a href={CRATEDB_ACADEMY} target="_blank" rel="noreferrer">
        <FormattedMessage id="sideMenu.academyLink" />
        <ArrowUpOutlined
          rotate={45}
          className="relative bottom-1 left-0.5 opacity-50"
        />
      </a>
    ),
  },
] satisfies ItemType[];
