import { ConfigProvider } from 'antd';
import { PropsWithChildren } from 'react';
import { COLORS } from 'src/constants/colors';

type AntdProviderProps = PropsWithChildren;

const AntdProvider = ({ children }: AntdProviderProps) => {
  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: COLORS.blue,
          colorSplit: '#D4D4D4', // tailwind neutral-300
          colorError: '#ef4444', // tailwind red-500
          borderRadius: 4,
          paddingContentVertical: 6,
        },
        components: {
          Switch: {
            colorPrimary: COLORS.blue, // change active color
          },
          Layout: {
            siderBg: '#262626', // Tailwind Neutral 800
          },
          Menu: {
            darkItemBg: '#262626', // Tailwind Neutral 800
            darkSubMenuItemBg: '#262626', // Tailwind Neutral 800,
            colorHighlight: COLORS.blue,
            darkItemColor: '#e5e5e5', // Tailwind Neutral 300
            itemMarginInline: 0,
            itemBorderRadius: 0,
          },
          Slider: {
            trackBg: COLORS.blue,
            trackHoverBg: COLORS.blue,
            dotActiveBorderColor: COLORS.blue,
            handleColor: COLORS.blue,
          },
          Tabs: {
            colorHighlight: COLORS.blue,
          },
          Table: {
            headerColor: '#737373', // tailwind neutral-500
            borderColor: '#e5e5e5', // tailwind neutral-200
          },
          Input: {
            colorBorder: '#a3a3a3', // tailwind neutral-400
          },
          Select: {
            colorBorder: '#a3a3a3', // tailwind neutral-400
          },
        },
      }}
    >
      {children}
    </ConfigProvider>
  );
};

export default AntdProvider;
