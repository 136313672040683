import { Button, Heading, Text } from '@cratedb/crate-gc-admin';
import noData from '../../assets/no_data.svg';

export type ErrorScreenProps = {
  buttonLabel: React.ReactNode;
  errorMessage?: React.ReactNode | React.ReactNode[];
  message: React.ReactNode;
  onClick: () => void;
  title?: React.ReactNode;
};

function ErrorScreen({
  buttonLabel,
  errorMessage,
  message,
  onClick,
  title,
}: ErrorScreenProps) {
  return (
    <div className="relative h-screen bg-neutral-800" role="alert">
      <div className="absolute left-1/2 top-1/2 flex min-w-[32rem] -translate-x-1/2 -translate-y-1/2 flex-col items-center gap-3 rounded-md bg-neutral-100 py-6">
        {!title && <img src={noData} alt="An error has occurred" />}
        {title && (
          <Heading
            className="select-none"
            displayAs={Heading.levels.h2}
            level={Heading.levels.h1}
          >
            {title}
          </Heading>
        )}
        {errorMessage && (
          <div className="max-w-md rounded bg-neutral-700 px-5 py-1">
            <Text className="text-center font-mono text-neutral-200">
              {errorMessage}
            </Text>
          </div>
        )}
        <Text className="max-w-md select-none text-center" pale>
          {message}
        </Text>
        <Button className="mt-4" onClick={onClick} type="button">
          {buttonLabel}
        </Button>
      </div>
    </div>
  );
}

export default ErrorScreen;
