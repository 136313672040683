import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate, useParams } from 'react-router-dom';
import {
  AVAILABLE_LIGHT_COLORS,
  Button,
  COLOR_STYLES_MAP,
  SolidLight,
} from '@cratedb/crate-gc-admin';
import RegionDescription from '../../../components/RegionDescription';
import SectionContainer from '../../../components/SectionContainer';
import { useAnalytics } from '../../../hooks';
import { regionPropType } from '../../../models';
import { getUserIsOrganizationAdmin } from '../../../utils/data/user';
import { useGetUsersMe } from '../../../swrHooks';
import {
  QUERY_PARAM_KEY_REGION,
  REGION_STATES,
  SINGLE_SCREEN_DEPLOYMENT_ANALYTICS_ORIGIN,
} from '../../../constants/defaults';
import { USER_TRACKING_EVENTS } from '../../../constants/segment';

const statusColors = {
  DOWN: COLOR_STYLES_MAP[AVAILABLE_LIGHT_COLORS.RED],
  UNKNOWN: COLOR_STYLES_MAP[AVAILABLE_LIGHT_COLORS.GRAY],
  UP: COLOR_STYLES_MAP[AVAILABLE_LIGHT_COLORS.GREEN],
};

const statusLabels = {
  DOWN: 'organization.regions.downStatusText',
  UNKNOWN: 'organization.regions.unknownStatusText',
  UP: 'organization.regions.upStatusText',
};

function CloudRegion({ region }) {
  const navigate = useNavigate();
  const { organizationId } = useParams();
  const { trackEvent } = useAnalytics();
  const { data: user } = useGetUsersMe();
  const userIsOrgAdmin = getUserIsOrganizationAdmin(user, organizationId);

  return (
    <SectionContainer key={region.name}>
      <div
        className="grid grid-cols-3 items-center gap-x-4 xl:grid-cols-5"
        data-testid="cloud-region"
      >
        <div className="col-span-2">
          <RegionDescription region={region} />
        </div>

        {/* status */}
        <div className="flex items-center justify-end xl:justify-start">
          <SolidLight className={statusColors[region.status]} />
          <div className="pl-1">
            <FormattedMessage id={statusLabels[region.status]} />
          </div>
        </div>

        {/* deploy cluster button */}
        <div className="col-span-3 flex justify-end align-middle xl:col-span-2">
          <Button
            disabled={!userIsOrgAdmin || region.status === REGION_STATES.DOWN}
            kind={Button.kinds.TERTIARY}
            onClick={() => {
              trackEvent(USER_TRACKING_EVENTS.CLICKED_DEPLOY, {
                origin: 'regions',
                destination: SINGLE_SCREEN_DEPLOYMENT_ANALYTICS_ORIGIN,
              });

              navigate(
                `/account/organizations/${organizationId}/deploy?${QUERY_PARAM_KEY_REGION}=${region.name}`,
              );
            }}
          >
            <FormattedMessage id="organization.regions.deployClusterButton" />
          </Button>
        </div>
      </div>
    </SectionContainer>
  );
}

CloudRegion.propTypes = {
  region: regionPropType.isRequired,
};

export default CloudRegion;
