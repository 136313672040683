import { Label, Text } from '@cratedb/crate-gc-admin';
import { FIELD_NAMES, TYPE_MAPPING_OPTIONS } from '../../constants';
import { FormattedMessage, useIntl } from 'react-intl';
import { CreateIntegrationMongoDBBody } from './forms';

export type TableStepSummaryProps = {
  values: CreateIntegrationMongoDBBody;
};

function TableStepSummary({ values }: TableStepSummaryProps) {
  const { formatMessage } = useIntl();
  const tableName =
    values[FIELD_NAMES.DESTINATION_NAMESPACE][FIELD_NAMES.TABLE_NAME];
  const typeMapping = TYPE_MAPPING_OPTIONS.find(
    option =>
      option.value ===
      values[FIELD_NAMES.DESTINATION_NAMESPACE][FIELD_NAMES.OBJECT_TYPE_STRATEGY],
  )!.label;

  const renderValue = (label: string, value: string) => {
    return (
      <div className="flex gap-2">
        <Text className="font-bold">{label}:</Text>
        <Text>{value}</Text>
      </div>
    );
  };

  return (
    <div>
      {renderValue(
        formatMessage({
          id: 'cluster.clusterImportIntegration.tableStep.tableLabel',
        }),
        tableName,
      )}

      <div className="flex flex-col gap-2 lg:flex-row">
        <div>
          <Label className="font-bold">
            <FormattedMessage id="cluster.clusterImportIntegration.tableStep.sourceLabel" />
          </Label>
          <div className="flex gap-2">
            <Text className="max-w-[100px]">record</Text>

            <Text className="max-w-[100px]">BSON</Text>
          </div>
        </div>

        <div className="mb-1 flex items-end">
          <Text className="!text-[30px]">→</Text>
        </div>

        <div>
          <Label className="font-bold">
            <FormattedMessage id="cluster.clusterImportIntegration.tableStep.targetLabel" />
          </Label>
          <div className="flex gap-2">
            <Text className="max-w-[120px]">document</Text>

            <Text className="w-[200px]">{typeMapping} </Text>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TableStepSummary;
