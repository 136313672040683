import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Switch } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { Button, ConfirmDelete, DisplayDate } from '@cratedb/crate-gc-admin';
import { apiDelete, apiPatch, apiPost } from '../../../api';
import { useGetUsersMeApikeys } from '../../../swrHooks';
import SectionContainer from '../../../components/SectionContainer';
import CloudUITable from '../../../components/CloudUITable';
import ShowTemporaryCredentials from '../../../components/ShowTemporaryCredentials';

function ApiKeyList() {
  const { formatMessage } = useIntl();
  const [keyToBeDeleted, setKeyToBeDeleted] = useState(null);
  const [generatedKey, setGeneratedKey] = useState(null);
  const { data: apiKeys, mutate } = useGetUsersMeApikeys();

  const deleteApiKey = async apiKey => {
    setKeyToBeDeleted(null);
    await apiDelete(`/api/v2/users/me/api-keys/${apiKey}/`);

    mutate(apiKeys.filter(key => key.key !== apiKey));
  };

  const toggleApiKey = async (apiKey, active) => {
    await apiPatch(`/api/v2/users/me/api-keys/${apiKey}/`, {
      active,
    });
    mutate(apiKeys.map(key => (key.key === apiKey ? { ...key, active } : key)));
  };

  const handleDeleteApiKeyCancel = () => {
    setKeyToBeDeleted(null);
  };

  const generateKey = async () => {
    const { success, data } = await apiPost('/api/v2/users/me/api-keys/', {});
    if (success) {
      setGeneratedKey(data);
      mutate();
    }
  };

  const clearGeneratedKey = () => {
    setGeneratedKey(null);
  };

  const columns = [
    {
      title: 'Key',
      key: 'key',
      className: 'truncate',
      render: apiKey => apiKey.key,
    },
    {
      title: 'Created',
      key: 'created',
      render: apiKey => <DisplayDate isoDate={apiKey.dc.created} />,
    },
    {
      title: 'Last Used',
      key: 'last_used',
      render: apiKey =>
        apiKey.last_used ? (
          <DisplayDate isoDate={apiKey.last_used} />
        ) : (
          <FormattedMessage id="account.apiKeys.keyNeverUsedText" />
        ),
    },
    {
      title: 'Active',
      key: 'active',
      width: '130px',
      render: apiKey => (
        <Switch
          checked={apiKey.active}
          onChange={() => {
            toggleApiKey(apiKey.key, !apiKey.active);
          }}
        />
      ),
    },
    {
      title: 'Action',
      key: 'action',
      render: apiKey => (
        <div className="mr-2 inline-block">
          <button
            aria-label={formatMessage({ id: 'common.delete' })}
            data-testid="delete-api-key-button"
            onClick={() => {
              setKeyToBeDeleted(apiKey);
            }}
            type="button"
          >
            <DeleteOutlined className="text-lg text-crate-blue" />
          </button>
        </div>
      ),
    },
  ];

  return (
    <>
      <SectionContainer
        title={<FormattedMessage id="account.apiKeys.apiKeysTitle" />}
        description={
          <FormattedMessage
            id="account.apiKeys.apiKeysSectionDescriptionText"
            values={{
              docslink: (
                <a href="/api/docs/" target="_blank" rel="noopener noreferrer">
                  <FormattedMessage id="account.apiKeys.apiKeysSectionDocsLinkText" />
                </a>
              ),
            }}
          />
        }
        actions={
          <Button
            disabled={apiKeys?.length === 2}
            onClick={generateKey}
            id="generate-key-button"
            kind={Button.kinds.TERTIARY}
            loading={!apiKeys}
          >
            <FormattedMessage id="account.apiKeys.generateNewApiKeyText" />
          </Button>
        }
      >
        <div className="overflow-auto">
          <CloudUITable
            columns={columns}
            dataSource={apiKeys}
            emptyText={formatMessage({
              id: 'account.apiKeys.noApiKeysYetText',
            })}
            rowKey="key"
            showHeader
          />
        </div>
      </SectionContainer>
      {generatedKey && (
        <ShowTemporaryCredentials
          password={generatedKey.secret}
          clearCredentialsCallback={clearGeneratedKey}
          username={generatedKey.key}
          translationPrefix="account.apiKeys"
        />
      )}
      <ConfirmDelete
        confirmText={keyToBeDeleted?.key || ''}
        prompt={formatMessage({
          id: 'account.apiKeys.deleteApiKeyConfirmationText',
        })}
        onCancel={handleDeleteApiKeyCancel}
        onConfirm={() => {
          deleteApiKey(keyToBeDeleted?.key);
        }}
        title={formatMessage({
          id: 'account.apiKeys.deleteApiKeyTitle',
        })}
        visible={keyToBeDeleted !== null}
      />
    </>
  );
}

export default ApiKeyList;
