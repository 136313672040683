import { ValueOf } from 'src/types';

const NOTIFICATION_TYPES = {
  TOOLTIP: 'TOOLTIP',
  INFO: 'INFO',
  WARN: 'WARN',
};
export type NotificationType = ValueOf<typeof NOTIFICATION_TYPES>;

export default NOTIFICATION_TYPES;
