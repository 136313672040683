const messages = {
  'jwtManagerWrapper.or': 'or',
  'jwtManagerWrapper.contactOurSupportTeam': 'contact our support team',
  'jwtManagerWrapper.forAssistance': 'for assistance.',

  // IP Not in whitelist
  'jwtManagerWrapper.ipNotInAllowlist': 'Service access restricted',
  'jwtManagerWrapper.yourIpAddress': 'Your IP address',
  'jwtManagerWrapper.notInClusterAllowList': "is not in the cluster's allow list.",
  'jwtManagerWrapper.manageAllowList': 'Manage allow list',

  // Grand Central Unhealthy
  'jwtManagerWrapper.serviceCurrentlyUnavailable': 'Service temporarily unavailable',
  'jwtManagerWrapper.ifIssuePersists': 'If the issue persists, please ',
  'jwtManagerWrapper.contactSupportTeam': 'If the issue persists, please ',
};

export default messages;
