import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import { Form, Input, message, Modal } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Button, Heading, SubmitButtonGroup, Text } from '@cratedb/crate-gc-admin';
import ConstrainWidth from '../../../components/ConstrainWidth';
import CloudRegion from './CloudRegion';
import EdgeRegion from './EdgeRegion';
import HubspotFormRequestNewRegion from '../../../components/HubspotForms/HubspotFormRequestNewRegion';
import SectionContainer from '../../../components/SectionContainer';
import ViewContainer from '../../../components/ViewContainer';
import { getUserIsOrganizationAdmin } from '../../../utils/data/user';
import { useGetOrganizationsIdRegions, useGetUsersMe } from '../../../swrHooks';
import useMessage from '../../../hooks/useMessage';
import { apiPost } from '../../../api';

function Regions() {
  const { formatMessage } = useIntl();
  const { organizationId } = useParams();
  const [form] = Form.useForm();
  const { showLoadingMessage, showSuccessMessage } = useMessage();
  const [submitFormInProgress, setSubmitFormInProgress] = useState(false);
  const [showCreateRegionModal, setShowCreateRegionModal] = useState(false);
  const [showRequestRegionModal, setShowRequestRegionModal] = useState(false);
  const { data: regions, mutate: mutateRegions } =
    useGetOrganizationsIdRegions(organizationId);
  const { data: user } = useGetUsersMe();
  const userIsOrgAdmin = getUserIsOrganizationAdmin(user, organizationId);

  const handleSubmitCreateRegion = async ({ description }) => {
    if (!submitFormInProgress) {
      setSubmitFormInProgress(true);

      showLoadingMessage(
        formatMessage({
          id: 'organization.regions.createRegionStartedText',
        }),
      );

      const { success } = await apiPost(`/api/v2/regions/`, {
        description,
        organization_id: organizationId,
      });

      if (success) {
        message.destroy();
        showSuccessMessage(
          formatMessage({
            id: 'organization.regions.createRegionSuccessText',
          }),
        );
        mutateRegions();
        form.resetFields();
        setShowCreateRegionModal(false);
      }

      setSubmitFormInProgress(false);
    }
  };

  return (
    <ConstrainWidth>
      <ViewContainer
        extra={
          userIsOrgAdmin && (
            <div className="flex">
              <Button
                kind={Button.kinds.TERTIARY}
                onClick={() => setShowRequestRegionModal(true)}
              >
                <FormattedMessage id="organization.regions.requestNewRegionButton" />
              </Button>
              <Button
                className="ml-4"
                onClick={() => setShowCreateRegionModal(true)}
              >
                <FormattedMessage id="organization.regions.createRegionButton" />
              </Button>
            </div>
          )
        }
        heading={<FormattedMessage id="organization.regions.pageTitle" />}
        render={() => (
          <>
            {regions &&
              regions
                .filter(region => !region.deprecated)
                .sort((a, b) =>
                  a.description.toLowerCase() > b.description.toLowerCase() ? 1 : -1,
                )
                .map(region =>
                  region.is_edge_region ? (
                    <EdgeRegion region={region} key={region.name} />
                  ) : (
                    <CloudRegion region={region} key={region.name} />
                  ),
                )}
            {!regions && <SectionContainer loading />}
          </>
        )}
      />

      {/* create new region modal */}
      <Modal
        closable={false}
        footer={null}
        onCancel={() => {
          setShowCreateRegionModal(false);
        }}
        open={showCreateRegionModal}
        width={900}
      >
        <>
          <Heading className="mb-4" level={Heading.levels.h4}>
            <FormattedMessage id="organization.regions.createEdgeRegionTitle" />
          </Heading>

          <Form
            aria-label="create region form"
            disabled={submitFormInProgress}
            form={form}
            id="create-region-form"
            layout="vertical"
            onFinish={handleSubmitCreateRegion}
          >
            <Form.Item
              colon={false}
              hasFeedback
              label={
                <FormattedMessage id="organization.regions.createRegionDescriptionLabel" />
              }
              name="description"
              rules={[
                {
                  max: 50,
                  message: formatMessage({
                    id: 'organization.regions.createRegionValidationError',
                  }),
                  required: true,
                },
              ]}
            >
              <Input
                placeholder={formatMessage({
                  id: 'organization.regions.createRegionDescriptionPlaceholder',
                })}
              />
            </Form.Item>

            <div className="mb-4">
              <div className="relative mt-6 flex pl-5">
                <InfoCircleOutlined className="absolute left-0 top-1/2 -translate-y-1/2 text-base text-amber-500" />
                <Text pale>
                  <FormattedMessage id="organization.regions.edge.createRegionBetaWarningText" />
                </Text>
              </div>
              <Text className="mx-4 mt-4">
                <FormattedMessage id="organization.regions.edge.createRegionBetaParagraph1Text" />
              </Text>
              <Text className="mx-4 mt-4">
                <FormattedMessage id="organization.regions.edge.createRegionBetaParagraph2Text" />
              </Text>
            </div>

            <SubmitButtonGroup
              confirmLabel={
                <FormattedMessage id="organization.regions.createRegionButton" />
              }
              onCancel={() => {
                setShowCreateRegionModal(false);
              }}
            />
          </Form>
        </>
      </Modal>

      {/* request new region modal */}
      <Modal
        closable={false}
        footer={null}
        onCancel={() => setShowRequestRegionModal(false)}
        open={showRequestRegionModal}
      >
        <HubspotFormRequestNewRegion
          onFormComplete={() => setShowRequestRegionModal(false)}
          onFormCancel={() => setShowRequestRegionModal(false)}
          hiddenFields={{
            cluster_configuration: `organization: ${organizationId}`,
          }}
        />
      </Modal>
    </ConstrainWidth>
  );
}

export default Regions;
