import { useParams } from 'react-router-dom';
import {
  AWSSubscriptionButton,
  AzureSubscriptionButton,
  BankCardButton,
  BankTransferButton,
  ContractSubscriptionButton,
  GCPSubscriptionButton,
} from '../../components/PaymentMethods';
import useDeployStore from './state';
import { SUBSCRIPTION_PROVIDER } from '../../constants/defaults';
import {
  useGetOrganizationsIdCustomer,
  useGetOrganizationsIdPaymentmethods,
} from '../../swrHooks';
import isEU from '../../utils/isEu';
import TestSWRIsFetching from '../../components/TestSWRIsFetching';
import { PaymentMethod } from 'src/types';

const buildMethodLookup = (
  paymentMethods?: PaymentMethod[],
): { [key: string]: PaymentMethod } => {
  return (paymentMethods || []).reduce(
    (prev, next) => ({
      ...prev,
      [next.type]: next,
    }),
    {},
  );
};

export type ChoosePaymentMethodProps = {
  paymentMethods?: PaymentMethod[];
  regionProvider?: string;
};

function ChoosePaymentMethod({
  paymentMethods,
  regionProvider,
}: ChoosePaymentMethodProps) {
  const { organizationId } = useParams();
  const { data: customer, isLoading: isCustomerLoading } =
    useGetOrganizationsIdCustomer(organizationId);
  const { mutate: mutatePaymentMethods } =
    useGetOrganizationsIdPaymentmethods(organizationId);

  const paymentMethod = useDeployStore(state => state.paymentMethod);
  const setPaymentMethod = useDeployStore(state => state.setPaymentMethod);

  const methodLookup = buildMethodLookup(paymentMethods);

  return (
    <div data-testid="choose-payment-method">
      <BankCardButton
        isSelected={paymentMethod?.type === SUBSCRIPTION_PROVIDER.STRIPE}
        onClick={() => {
          setPaymentMethod(methodLookup.stripe);
        }}
        paymentMethod={methodLookup.stripe}
      />

      {regionProvider === SUBSCRIPTION_PROVIDER.AWS && (
        <AWSSubscriptionButton
          isSelected={paymentMethod?.type === SUBSCRIPTION_PROVIDER.AWS}
          onClick={() => setPaymentMethod(methodLookup.aws)}
          paymentMethod={methodLookup.aws}
        />
      )}
      {regionProvider === SUBSCRIPTION_PROVIDER.AZURE && (
        <AzureSubscriptionButton
          isSelected={paymentMethod?.type === SUBSCRIPTION_PROVIDER.AZURE}
          onClick={() => setPaymentMethod(methodLookup.azure)}
          paymentMethod={methodLookup.azure}
        />
      )}
      {regionProvider === SUBSCRIPTION_PROVIDER.GCP && (
        <GCPSubscriptionButton
          isSelected={paymentMethod?.type === SUBSCRIPTION_PROVIDER.GCP}
          onClick={() => setPaymentMethod(methodLookup.gcp)}
          paymentMethod={methodLookup.gcp}
        />
      )}

      {customer?.address?.country && isEU(customer.address.country) && (
        <BankTransferButton
          isAvailable={
            methodLookup.stripe_bank_transfer
              ? methodLookup.stripe_bank_transfer.is_available!
              : false
          }
          isSelected={
            paymentMethod?.type === SUBSCRIPTION_PROVIDER.STRIPE_BANK_TRANSFER
          }
          onClick={() => {
            setPaymentMethod(methodLookup.stripe_bank_transfer);
          }}
          paymentMethod={methodLookup.stripe_bank_transfer}
          onSubmitCallback={async success => {
            if (success) {
              const updatedPaymentMethods = await mutatePaymentMethods();
              const updatedMethodLookup = buildMethodLookup(updatedPaymentMethods);
              setPaymentMethod(updatedMethodLookup.stripe_bank_transfer);
            }
          }}
        />
      )}

      {methodLookup.contract?.is_setup && (
        <ContractSubscriptionButton
          isSelected={paymentMethod?.type === SUBSCRIPTION_PROVIDER.CONTRACT}
          onClick={() => setPaymentMethod(methodLookup.contract)}
          paymentMethod={methodLookup.contract}
        />
      )}

      <TestSWRIsFetching fetchStatusList={[isCustomerLoading]} />
    </div>
  );
}

export default ChoosePaymentMethod;
