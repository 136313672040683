import useSWR from 'swr';
import {
  Bucket,
  Connection,
  Container,
  Cluster,
  Credit,
  File,
  FeatureFlagStatus,
  ImportJobId,
  Integration,
  IntegrationId,
  Region,
  Secret,
  SecretId,
  User,
  type ClusterId,
  type ImportJob,
  type OrganizationId,
  ConnectionId,
} from 'src/types';
import { INTEGRATION_TYPES } from './constants/integrations';
import { IMPORT_JOB_TYPES } from './constants/importJobs';
import { SECRET_TYPES } from './constants/defaults';

export const useGetUsersMe = () => {
  return useSWR<User>('/api/v2/users/me/');
};

export const useGetOrganizationsIdCredits = (organizationId: OrganizationId) => {
  return useSWR<Credit[]>(
    organizationId ? `/api/v2/organizations/${organizationId}/credits/` : null,
  );
};

export const useGetOrganizationsIdFiles = (organizationId: OrganizationId) => {
  return useSWR<File[]>(
    organizationId ? `/api/v2/organizations/${organizationId}/files/` : null,
  );
};

export const useGetClustersIdImportJob = (
  clusterId: ClusterId | undefined,
  jobId: ImportJobId | null,
) => {
  return useSWR<ImportJob>(
    clusterId && jobId
      ? `/api/v2/clusters/${clusterId}/import-jobs/${jobId}/`
      : null,
  );
};

export const useGetClustersIdImportJobs = (clusterId?: ClusterId) => {
  return useSWR<ImportJob[]>(
    clusterId
      ? `/api/v2/clusters/${clusterId}/import-jobs/?type=${IMPORT_JOB_TYPES.FILE},${IMPORT_JOB_TYPES.URL},${IMPORT_JOB_TYPES.AZUREBLOB},${IMPORT_JOB_TYPES.S3}`
      : null,
    {
      refreshInterval: data => {
        // org members will not be returned an array
        if (!data || !Array.isArray(data)) {
          return 0; // do not refresh
        }

        return data.some(j =>
          ['IN_PROGRESS', 'SENT', 'REGISTERED'].includes(j.status),
        )
          ? 3 * 1000 // poll faster if imports are in progress
          : 10 * 1000;
      },
    },
  );
};

export const useGetOrganizationsIdClusters = (
  organizationId: OrganizationId,
  options = {},
) => {
  return useSWR<Cluster[]>(
    organizationId ? `/api/v2/organizations/${organizationId}/clusters/` : null,
    options,
  );
};

export const useGetOrganizationsIdRegions = (organizationId: OrganizationId) => {
  return useSWR<Region[]>(
    organizationId ? `/api/v2/organizations/${organizationId}/regions/` : null,
    {
      refreshInterval: 5 * 60 * 1000,
    },
  );
};

export const useGetOrganizationsIdSecrets = (organizationId: OrganizationId) => {
  return useSWR<Secret[]>(
    organizationId
      ? `/api/v2/organizations/${organizationId}/secrets/?type=${SECRET_TYPES.AWS},${SECRET_TYPES.AZURE}`
      : null,
  );
};

export const useGetIntegrationsAwsS3Buckets = (
  secretId: SecretId,
  endpointURL: string | undefined,
) => {
  return useSWR<{
    buckets: Bucket[];
    error?: string;
  }>(
    secretId
      ? `/api/v2/integrations/aws/s3-buckets/?secret_id=${secretId}&endpoint=${
          endpointURL ? encodeURI(endpointURL) : ''
        }`
      : null,
  );
};

export const useGetIntegrationsAwsS3objects = (
  secretId: SecretId,
  endpointURL: string | undefined,
  bucket: string,
  prefix: string | null = null,
) => {
  const params = new URLSearchParams({
    secret_id: secretId,
    bucket,
  });
  if (endpointURL) {
    params.append('endpoint', encodeURI(endpointURL));
  }
  if (prefix) {
    params.append('prefix', prefix);
  }
  return useSWR<{
    objects: string[];
    error?: string;
  }>(
    secretId && bucket
      ? `/api/v2/integrations/aws/s3-objects/?${params.toString()}`
      : null,
  );
};

export const useGetIntegrationsAzureBlobStorageContainers = (secretId: SecretId) => {
  return useSWR<{
    containers: Container[];
    error?: string;
  }>(
    secretId
      ? `/api/v2/integrations/azure/blob-storage-containers/?secret_id=${secretId}`
      : null,
  );
};

export const useGetIntegrationsAzureBlobs = (
  secretId: SecretId,
  containerName: string,
  prefix: string | null = null,
) => {
  const params = new URLSearchParams({
    secret_id: secretId,
    container_name: containerName,
  });
  if (prefix) {
    params.append('prefix', prefix);
  }
  return useSWR<{
    blobs: string[];
    error?: string;
  }>(
    secretId && containerName
      ? `/api/v2/integrations/azure/blobs/?${params.toString()}`
      : null,
  );
};

// CONNECTIONS
export const useGetClustersIdConnections = (organizationId: OrganizationId) => {
  return useSWR<Connection[]>(
    `/api/v2/organizations/${organizationId}/connections/`,
  );
};

export const useGetClustersIdConnectionsId = (
  organizationId: OrganizationId,
  connectionId: ConnectionId | null,
) => {
  return useSWR<Connection>(
    connectionId
      ? `/api/v2/organizations/${organizationId}/connections/${connectionId}/`
      : null,
  );
};

// INTEGRATIONS
export const useGetClustersIdIntegrations = (clusterId: ClusterId | null) => {
  return useSWR<Integration[]>(
    clusterId
      ? `/api/v2/clusters/${clusterId}/import-jobs/?type=${INTEGRATION_TYPES.MONGODB}`
      : null,
    {
      refreshInterval: data => {
        return data &&
          data.some(j => ['IN_PROGRESS', 'SENT', 'REGISTERED'].includes(j.status))
          ? 3 * 1000 // poll faster if imports are in progress
          : 10 * 1000;
      },
    },
  );
};

export const useGetClustersIdIntegration = (
  clusterId: ClusterId | undefined,
  integrationId: IntegrationId | null,
) => {
  return useSWR<Integration>(
    clusterId && integrationId
      ? `/api/v2/clusters/${clusterId}/import-jobs/${integrationId}/`
      : null,
  );
};

// feature flags
export const useGetFeaturesStatus = () => {
  return useSWR<FeatureFlagStatus>('/api/v2/features/status/');
};
