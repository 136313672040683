import { locationHostname } from 'src/utils';

export const DEV = 'dev';
export const PROD = 'prod';
export const LOCAL = 'local';

type Environment = typeof DEV | typeof PROD | typeof LOCAL;

export const getEnvironment = (
  hostname: string = locationHostname(),
): Environment => {
  if (/\.cratedb\.cloud$/.test(hostname)) return PROD;
  if (/\.cratedb-dev\.cloud$/.test(hostname)) return DEV;
  if (hostname === 'localhost' || hostname === '0.0.0.0' || hostname.match(/ngrok/))
    return LOCAL;
  throw new Error(
    `Unknown environment: Hostname (${hostname}) is not mapped to an environment.`,
  );
};
