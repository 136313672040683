import { PlayCircleOutlined } from '@ant-design/icons';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { Link, useParams } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import {
  CopyToClipboard,
  Heading,
  useJWTManagerStore,
  Text,
} from '@cratedb/crate-gc-admin';
import CustomPreTag from './CustomPreTag';
import { clusterSQLConsole } from '../../constants/paths';

export type CloudUISyntaxHighlighterProps = {
  title?: React.ReactNode | React.ReactNode[];
  helptext?: React.ReactNode | React.ReactNode[];
  language: string;
  linkUrl?: string;
  onClick?: () => void;
  children: React.ReactNode | React.ReactNode[] | string;
};

function CloudUISyntaxHighlighter({
  language,
  children,
  title,
  helptext,
  linkUrl,
  onClick,
}: CloudUISyntaxHighlighterProps) {
  const { clusterId, organizationId, projectId } = useParams();
  const clusterJWTEnabled = useJWTManagerStore(state => state.isJWTEnabled);

  return (
    <div>
      {title && (
        <Heading level={Heading.levels.h4} className="mb-2 mt-6">
          {title}
        </Heading>
      )}

      <div className="rounded-lg bg-neutral-100 py-1 pl-2 pr-4">
        <div className="flex items-start justify-between">
          <SyntaxHighlighter
            language={language}
            PreTag={CustomPreTag}
            style={docco}
            wrapLongLines
          >
            {children as string}
          </SyntaxHighlighter>

          <CopyToClipboard textToCopy={children as string}>
            <Text className="py-2 text-sm text-crate-blue">
              <FormattedMessage id="common.copy" />
            </Text>
          </CopyToClipboard>
        </div>
      </div>
      {(helptext || linkUrl) && (
        <div className="mt-1 flex items-start justify-between px-1">
          <div className="mr-2 text-base text-neutral-500">
            {helptext && helptext}
          </div>
          {linkUrl && clusterJWTEnabled && (
            <div className="flex items-center gap-1">
              <PlayCircleOutlined className="text-crate-blue" />
              <Link
                className="text-sm"
                to={`${clusterSQLConsole.build({
                  clusterId: clusterId,
                  projectId: projectId,
                  organizationId: organizationId,
                })}?q=${encodeURI(children as string)}`}
              >
                <FormattedMessage id="cloudUISyntaxHighlighter.runQueryInConsoleButton" />
              </Link>
            </div>
          )}
          {linkUrl && !clusterJWTEnabled && (
            <div className="flex items-center gap-1">
              <PlayCircleOutlined className="text-crate-blue" />
              <a
                className="text-sm text-crate-blue"
                href={linkUrl}
                target="_blank"
                rel="noopener noreferrer"
                onClick={onClick}
              >
                <FormattedMessage id="cloudUISyntaxHighlighter.runQueryInAdminUIButton" />
              </a>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default CloudUISyntaxHighlighter;
