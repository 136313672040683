export const REGION_IP_RANGES: { [key: string]: string[] } = {
  // AWS
  'eks1.eu-west-1.aws': ['34.255.75.224/32'],
  'eks1.us-east-1.aws': ['54.197.229.58/32'],
  'eks1.us-west-2.aws': ['54.189.16.20/32'],

  // Azure
  'aks1.eastus.azure': ['127.0.0.1/32'], // test only
  'aks1.eastus2.azure': ['52.184.241.228/32', '52.254.31.90/32'],
  'aks1.westeurope.azure': ['51.105.153.175/32', '108.142.34.5/32'],
  'aks2.westeurope.azure': ['51.105.110.104/32'],

  // GCP
  'gke1.us-central1.gcp': ['34.69.134.49'],
  'gke1.us-east1.gcp': ['127.0.0.1/32'], // test only

  // TESTING ONLY
  'north.local': ['4.4.4.4/32', '8.8.8.8/32'],
};
