import { locationAssign, locationPathname, locationSearch } from './location';

export default async (resource, init) => {
  const res = await fetch(resource, init);

  // redirect to login screen on auth error
  if ((res.status === 401 || res.status === 403) && locationPathname() !== '/') {
    locationAssign(
      `/?rd=${locationPathname()}${encodeURIComponent(locationSearch())}`,
    );
  }

  // this check handles an edge case where the user has navigated
  // to a cluster page, and that cluster is deleted from elsewhere
  // (e.g. another browser tab, croud). in this case, SWR is out of
  // sync with the api and we simply reload the app.
  if (
    res.status === 404 &&
    resource.match(/^\/api\/v2\/clusters\/[a-f0-9-]{36}\/$/)
  ) {
    locationAssign('/');
    return null;
  }

  return res.json();
};
