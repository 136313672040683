import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { Button, useButtonStyles } from '@cratedb/crate-gc-admin';
import { useAnalytics } from '../../hooks';
import ConfirmSuspendResume from '../ConfirmSuspendResume';
import { toGibiBytes } from '../../utils';
import { getClusterAdminUIIsReachable } from '../../utils/data/cluster';
import { EDGE_PRODUCT_NAME } from '../../constants/defaults';
import { clusterOverview, clusterManage } from '../../constants/paths';
import { USER_TRACKING_EVENTS } from '../../constants/segment';
import useSessionStore from '../../state/session';
import { Cluster } from 'src/types';

export type ClusterFooterProps = {
  cluster: Cluster;
  organizationId: string;
  showViewClusterButton?: boolean;
};

function ClusterFooter({
  cluster,
  organizationId,
  showViewClusterButton = false,
}: ClusterFooterProps) {
  const { trackEvent } = useAnalytics();

  const [showResumeModal, setShowResumeModal] = useState(false);
  const isSuspending =
    !cluster.suspended &&
    cluster.health?.status === 'GREEN' &&
    cluster.health.running_operation === 'SUSPEND';
  const [isResumingFromSuspension, setIsResumingFromSuspension] = useState(
    cluster.suspended && cluster.health?.running_operation === 'SUSPEND',
  );

  const clusterPasswords = useSessionStore(state => state.clusterPasswords);

  const primaryButtonStyles = useButtonStyles({
    size: Button.sizes.SMALL,
    kind: Button.kinds.PRIMARY,
    className: 'hover:text-neutral-100 w-full',
  });

  const disabledPrimaryButtonStyles = useButtonStyles({
    size: Button.sizes.SMALL,
    kind: Button.kinds.PRIMARY,
    disabled: true,
    className: 'w-full',
  });

  const secondaryButtonStyles = useButtonStyles({
    size: Button.sizes.SMALL,
    kind: Button.kinds.SECONDARY,
    className: 'hover:text-neutral-100 w-full',
  });

  const isEdgeRegion = cluster.product_name === EDGE_PRODUCT_NAME;
  const isEdgeClusterWithNoExternalIP = isEdgeRegion && !cluster.external_ip;

  useEffect(() => {
    if (
      !isResumingFromSuspension &&
      cluster.suspended &&
      cluster.health?.running_operation === 'SUSPEND'
    ) {
      setIsResumingFromSuspension(true);
    }
  }, [cluster, isResumingFromSuspension]);

  const renderAttribute = (
    label: React.ReactNode,
    value: string | number | React.ReactNode,
    testId: string,
  ) => {
    return (
      <div data-testid={testId} className="w-16">
        <div className="text-[10px] font-bold uppercase text-crate-border-mid">
          {label}
        </div>
        <div className="break-words">{value}</div>
      </div>
    );
  };

  const renderOpenAdminUIButton = (buttonStyle: string) => {
    const clusterPasswordIsAvailable = cluster.id in clusterPasswords;

    if (getClusterAdminUIIsReachable(cluster)) {
      return (
        <div className="flex flex-col items-end gap-1">
          {!getClusterAdminUIIsReachable(cluster) || isSuspending ? (
            <button className={buttonStyle} disabled type="button">
              <FormattedMessage id="accessCluster.openAdminUIButton" />
            </button>
          ) : (
            <a
              className={buttonStyle}
              href={cluster.url}
              target="_blank"
              rel="noopener noreferrer"
              onClick={() => {
                trackEvent(USER_TRACKING_EVENTS.CLICKED_ACCESS_ADMIN_UI);
              }}
            >
              <FormattedMessage id="accessCluster.openAdminUIButton" />
            </a>
          )}

          {!clusterPasswordIsAvailable && (
            <Link
              className="text-xs"
              to={{
                pathname: clusterManage.build({
                  clusterId: cluster.id,
                  projectId: cluster.project_id,
                  organizationId,
                }),
              }}
            >
              <FormattedMessage id="cluster.clusterDetail.updatePassword" />
            </Link>
          )}
        </div>
      );
    }

    return (
      <span className={disabledPrimaryButtonStyles}>
        <FormattedMessage id="accessCluster.openAdminUIButton" />
      </span>
    );
  };

  const renderClusterOverviewButtons = () => (
    <div className="flex w-full flex-col items-end">
      {/* open admin ui button */}
      <div>{renderOpenAdminUIButton(primaryButtonStyles)}</div>

      {/* open admin ui button help */}
      {(cluster.suspended || isEdgeClusterWithNoExternalIP) && (
        <div className="pl-2 pt-1 text-right text-xs text-crate-border-dark">
          {cluster.suspended ? (
            <FormattedMessage id="accessCluster.connectViaAdminUISuspendedHelp" />
          ) : (
            <FormattedMessage id="accessCluster.connectViaAdminUINoIPHelp" />
          )}
        </div>
      )}
    </div>
  );

  const renderClusterListButtons = () => {
    return (
      <div className="flex w-full justify-end">
        {/* secondary button */}
        {cluster.suspended ? (
          <>
            <ConfirmSuspendResume
              cluster={cluster}
              isVisible={showResumeModal}
              setIsVisible={setShowResumeModal}
              onCallback={() => setIsResumingFromSuspension(true)}
            />
            <Button
              size={Button.sizes.SMALL}
              onClick={() => setShowResumeModal(true)}
              disabled={isResumingFromSuspension}
            >
              <FormattedMessage id="cluster.clusterDetail.resumeClusterButton" />
            </Button>
          </>
        ) : (
          <div>{renderOpenAdminUIButton(secondaryButtonStyles)}</div>
        )}

        {/* view cluster button */}
        <Link
          to={{
            pathname: clusterOverview.build({
              clusterId: cluster.id,
              projectId: cluster.project_id,
              organizationId,
            }),
          }}
        >
          <Button className="ml-2 min-w-[90px]" size={Button.sizes.SMALL}>
            <FormattedMessage id="common.view" />
          </Button>
        </Link>
      </div>
    );
  };

  return (
    <div className="mt-4 flex flex-col items-end justify-between gap-4 lg:flex-row">
      <div className="flex flex-row items-start gap-4 text-sm leading-snug md:gap-8 lg:gap-8">
        {renderAttribute(
          <FormattedMessage id="cluster.clusterOverview.planLabel" />,
          cluster.product_name.toUpperCase(),
          'plan-attribute',
        )}
        {renderAttribute(
          <FormattedMessage id="cluster.clusterOverview.nodesLabel" />,
          cluster.num_nodes,
          'nodes-attribute',
        )}
        {renderAttribute(
          <FormattedMessage id="cluster.clusterOverview.cpuLabel" />,
          `${cluster.hardware_specs?.cpus_per_node}`,
          'cpu-attribute',
        )}
        {renderAttribute(
          <FormattedMessage id="common.ram" />,
          `${toGibiBytes(cluster.hardware_specs?.memory_per_node_bytes)} GiB`,
          'ram-attribute',
        )}
        {renderAttribute(
          <FormattedMessage id="common.storage" />,
          `${toGibiBytes(cluster.hardware_specs?.disk_size_per_node_bytes)} GiB`,
          'storage-attribute',
        )}
        {renderAttribute(
          <FormattedMessage id="common.cratedb" />,
          <>
            <div className="flex items-center gap-x-1 truncate">
              v{cluster.crate_version.replace(/nightly-/, '')}
            </div>
            {cluster.channel !== 'stable' && (
              <div className="text-xs text-crate-border-dark">{cluster.channel}</div>
            )}
          </>,
          'version-attribute',
        )}
      </div>
      {showViewClusterButton
        ? renderClusterListButtons()
        : renderClusterOverviewButtons()}
    </div>
  );
}

export default ClusterFooter;
