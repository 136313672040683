import { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Form, Input } from 'antd';
import { Button } from '@cratedb/crate-gc-admin';
import { postHubspotForm } from '../../../api';
import { HUBSPOT_FORMS } from '../../../constants/hubspot';
import { useGetUsersMe } from '../../../swrHooks';
import useMessage from '../../../hooks/useMessage';
import INPUT_SANITIZATION from '../../../constants/inputSanitization';

type HubspotFormEnterpriseClusterFields = {
  email?: string;
  name?: string;
  phone?: string;
  tell_us?: string;
};

export type HubspotFormEnterpriseClusterProps = {
  hiddenFields?: { cluster_configuration?: string };
  initialValues?: HubspotFormEnterpriseClusterFields;
};

function HubspotFormEnterpriseCluster({
  initialValues,
  hiddenFields,
}: HubspotFormEnterpriseClusterProps) {
  const [form] = Form.useForm();
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const { data: user } = useGetUsersMe();
  const { formatMessage } = useIntl();
  const { showErrorMessage, showSuccessMessage } = useMessage();

  const onFinish = async (values: HubspotFormEnterpriseClusterFields) => {
    const response = await postHubspotForm(
      HUBSPOT_FORMS.CLOUD_CONSOLE_DEPLOY_ENTERPRISE_CLUSTER,
      {
        ...values,
        ...hiddenFields,
      },
    );

    if (response.success) {
      showSuccessMessage(formatMessage({ id: 'hubspot.common.messageSuccess' }));
      setIsFormSubmitted(true);
    } else {
      showErrorMessage(formatMessage({ id: 'hubspot.common.messageFail' }));
    }
  };

  if (isFormSubmitted) {
    return (
      <div className="py-12 text-center">
        <FormattedMessage id="hubspot.enterpriseForm.thankYou" />
      </div>
    );
  }

  return (
    <Form
      name="hubspot-enterprise-cluster-form"
      aria-label="hubspot-enterprise-cluster-form"
      data-testid="hubspot-enterprise-cluster-form"
      onFinish={onFinish}
      autoComplete="off"
      layout="vertical"
      initialValues={{ email: user?.email, name: user?.name, ...initialValues }}
      form={form}
    >
      {/* name */}
      <Form.Item
        label={<FormattedMessage id="hubspot.common.name" />}
        name="name"
        rules={[INPUT_SANITIZATION.HUBSPOT_NAME]}
      >
        <Input />
      </Form.Item>

      {/* email */}
      <Form.Item
        label={<FormattedMessage id="common.email" />}
        name="email"
        rules={[INPUT_SANITIZATION.GENERIC_EMAIL]}
      >
        <Input />
      </Form.Item>

      {/* phone */}
      <Form.Item
        label={<FormattedMessage id="hubspot.common.phone" />}
        name="phone"
        rules={[INPUT_SANITIZATION.PHONE_OPTIONAL]}
      >
        <Input
          placeholder={formatMessage({
            id: 'hubspot.common.phoneIncludeCountryCode',
          })}
        />
      </Form.Item>

      {/* message */}
      <Form.Item
        label={<FormattedMessage id="hubspot.enterpriseForm.tellUsUseCase" />}
        name="tell_us"
        rules={[INPUT_SANITIZATION.HUBSPOT_MESSAGE]}
      >
        <Input.TextArea
          autoSize={{ minRows: 4, maxRows: 8 }}
          placeholder={formatMessage({
            id: 'hubspot.common.optional',
          })}
        />
      </Form.Item>

      <div className="flex justify-end">
        <Button type={Button.types.SUBMIT} size={Button.sizes.SMALL}>
          <FormattedMessage id="common.ok" />
        </Button>
      </div>
    </Form>
  );
}

export default HubspotFormEnterpriseCluster;
