import React from 'react';
import { useParams } from 'react-router-dom';
import { Code, Heading, Text } from '@cratedb/crate-gc-admin';
import OneClickImport from '../OneClickImport';
import { useGetClustersId } from '../../../../swrHooks';
import {
  renderAnchor,
  renderAnchorLink,
  renderBullet,
  renderSQL,
} from '../articleComponents';
import { useAnalytics } from '../../../../hooks';
import { TUTORIALS } from '../../../../constants/defaults';
import { USER_TRACKING_EVENTS } from '../../../../constants/segment';

const IMPORT_DATA_POST = [
  {
    destination: {
      create_table: true,
      table: 'weather_data',
      table_definition: [
        {
          name: 'timestamp',
          type: 'TIMESTAMP',
        },
        {
          name: 'location',
          type: 'VARCHAR',
        },
        {
          name: 'temperature',
          type: 'DOUBLE',
        },
        {
          name: 'humidity',
          type: 'DOUBLE',
        },
        {
          name: 'wind_speed',
          type: 'DOUBLE',
        },
      ],
    },
    compression: 'gzip',
    format: 'csv',
    type: 'url',
    url: {
      url: 'https://github.com/crate/cratedb-datasets/raw/main/cloud-tutorials/data_weather.csv.gz',
    },
  },
];

function TimeSeriesWeatherArticle() {
  const { trackEvent } = useAnalytics();
  const { clusterId } = useParams();
  const { data: cluster } = useGetClustersId(clusterId);

  const trackRunQuery = id => {
    trackEvent(USER_TRACKING_EVENTS.RUN_QUERY_ADMIN_UI, {
      query: `${TUTORIALS.TIMESERIES_WEATHER} - ${id}`,
    });
  };

  return (
    <div className="flex items-start" data-testid="article-time-series-weather">
      <div>
        {renderAnchor('introduction')}
        <Text className="pb-2">
          CrateDB is a powerful database designed to handle various use cases, one of
          which is managing time series data. Time series data refers to collections
          of data points recorded at specific intervals over time, like the hourly
          temperature of a city or the daily sales of a store.
        </Text>
        <Text className="pb-2">
          For this tutorial, imagine a dataset that captures weather readings from
          CrateDB offices across the globe. Each record includes:
        </Text>
        <ol className="list-decimal pb-2 pl-10">
          {renderBullet('timestamp', 'the exact time of the recording.')}
          {renderBullet('location', 'the location of the weather station.')}
          {renderBullet('temperature', 'the temperature in celsius.')}
          {renderBullet('humidity', 'the humidity as a percentage.')}
          {renderBullet('wind_speed', 'the wind speed in km/h.')}
        </ol>

        {renderAnchor('create-table')}
        <OneClickImport
          buttonContents={
            <>
              <div className="leading-snug">Import weather data</div>
              <div className="text-xs leading-snug opacity-80">
                70,000 rows, 8.9MiB
              </div>
            </>
          }
          cluster={cluster}
          importPOST={IMPORT_DATA_POST}
          manualContent={
            <>
              {/* creating the table */}
              <Heading className="pb-2 pt-4" level={Heading.levels.h4}>
                Creating the table
              </Heading>
              <Text className="pb-2">
                CrateDB uses SQL, a powerful and familiar language for database
                management. To store the weather data, create a table with columns
                tailored to the dataset using the <Code>CREATE TABLE</Code> command:
              </Text>
              {renderSQL(
                cluster,
                `CREATE TABLE "weather_data" (
"timestamp" TIMESTAMP,
"location" VARCHAR,
"temperature" DOUBLE,
"humidity" DOUBLE,
"wind_speed" DOUBLE
);`,
                () => trackRunQuery(1),
              )}
              <Text className="pb-2">
                Run the above SQL command in CrateDB to set up your table. With the
                table ready, you are now set to insert the dataset.
              </Text>

              {/* inserting data */}
              {renderAnchor('insert-data')}
              <Heading className="pb-2 pt-4" level={Heading.levels.h4}>
                Inserting data
              </Heading>
              <Text className="pb-2">
                Insert the data using the <Code>COPY FROM</Code> SQL statement.
              </Text>
              {renderSQL(
                cluster,
                `COPY weather_data
FROM 'https://github.com/crate/cratedb-datasets/raw/main/cloud-tutorials/data_weather.csv.gz'
WITH (format='csv', compression='gzip', empty_string_as_null=true);`,
                () => trackRunQuery(2),
              )}
            </>
          }
          successMessage={<div>Weather data imported</div>}
          tutorialTrackingName={TUTORIALS.TIMESERIES_WEATHER}
        />

        {/* analysing data */}
        {renderAnchor('analyze-data')}
        <Heading className="pb-2 pt-4" level={Heading.levels.h4}>
          Analyzing data
        </Heading>
        <Text className="pb-2">
          Start with a basic <Code>SELECT</Code> statement on all columns, and limit
          the output to display only 10 records, in order to quickly explore a few
          samples worth of data.
        </Text>
        {renderSQL(
          cluster,
          `SELECT *
FROM weather_data
LIMIT 10;`,
          () => trackRunQuery(3),
        )}
        <Text className="pb-2">
          CrateDB is built for fast aggregation using the columnar storage to speed
          up queries. For example, calculate the average temperature for each
          location by using the AVG aggregation function:
        </Text>
        {renderSQL(
          cluster,
          `SELECT location, AVG(temperature) AS avg_temp
FROM weather_data
GROUP BY location;`,
          () => trackRunQuery(4),
        )}
        <Text className="pb-2">
          Computing basic averages is nothing special, but what if you need to answer
          more detailed questions? For example, if you want to know the highest
          temperature for each place and when it occurred.
        </Text>
        <Text className="pb-2">
          Simple groupings might not be enough, but thankfully, CrateDB has enhanced
          tools for time series data. You can use the{' '}
          <Code>max_by(returned_value, maximized_value)</Code> function, which gives
          you a value (like the time) when another value (like the temperature) is at
          its highest.
        </Text>
        <Text className="pb-2">Let’s put this to use with the following query:</Text>
        {renderSQL(
          cluster,
          `SELECT location,
    max(temperature) AS highest_temp,
    max_by(timestamp, temperature) AS time_of_highest_temp
FROM weather_data
GROUP BY location;`,
          () => trackRunQuery(5),
        )}
        <Text className="pb-2">
          You have probably observed by now, that there are gaps in the dataset for
          certain metrics. Such occurrences are common, perhaps due to a sensor
          malfunction or disconnection. To address this, the missing values need to
          be filled in. You can employ another useful tool: window functions paired
          with the <Code>IGNORE NULLS</Code> feature. Within a Common Table
          Expression (CTE), we utilize window functions to spot the next and prior
          non-null temperature recordings, and then compute the arithmetic mean to
          bridge the gap:
        </Text>
        {renderSQL(
          cluster,
          `WITH OrderedData AS (
    SELECT timestamp,
           location,
           temperature,
           LAG(temperature, 1) IGNORE NULLS OVER w AS prev_temp,
           LEAD(temperature, 1) IGNORE NULLS OVER w AS next_temp
    FROM weather_data
    WINDOW w AS (PARTITION BY location ORDER BY timestamp)
)
SELECT timestamp,
       location,
       temperature,
       COALESCE(temperature,(prev_temp + next_temp)) / 2 AS interpolated_temperature
FROM OrderedData
ORDER BY location, timestamp
LIMIT 50;`,
          () => trackRunQuery(6),
        )}
        <Text className="pb-2">
          The <Code>WINDOW</Code> clause defines a window that partitions the data by
          location and orders it by timestamp. This ensures that the
          <Code>LAG</Code> and <Code>LEAD</Code> window functions operate within each
          location group chronologically. If the temperature value is defined as
          <Code>NULL</Code>, the query returns the interpolated value calculated as
          the average of the previous and next available temperature readings.
          Otherwise, it uses the original value.
        </Text>
      </div>
      <div className="hidden min-w-[250px] pl-8 lg:block">
        {renderAnchorLink('top', 'Introduction')}
        {renderAnchorLink('create-table', 'Creating the table')}
        {renderAnchorLink('analyze-data', 'Analyzing data')}
      </div>
    </div>
  );
}

export default TimeSeriesWeatherArticle;
