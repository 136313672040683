import cx from 'classnames';
import {
  CloseOutlined,
  FileDoneOutlined,
  FileSyncOutlined,
  WarningOutlined,
} from '@ant-design/icons';
import { FormattedMessage } from 'react-intl';
import { isNull } from 'lodash/fp';
import { Loader, Text } from '@cratedb/crate-gc-admin';
import { fromBytes, toPercent } from '../../utils';
import { File as CloudFile, ValueOf } from 'src/types';

export const ERROR_TYPES = {
  ERROR_POSTING_FILE: 'ERROR_POSTING_FILE',
  ERROR_FILE_SIZE: 'ERROR_FILE_SIZE',
  ERROR_FILE_FORMAT: 'ERROR_FILE_FORMAT',
} as const;

export type FileErrorInfo = {
  message: string;
  type?: ValueOf<keyof typeof ERROR_TYPES>;
};

export type FileObjectProps = {
  attachedFile: File | CloudFile;
  disabled?: boolean;
  error?: FileErrorInfo;
  onRemove: () => void;
  progress?: number;
};

function FileObject({
  attachedFile,
  disabled = false,
  error = undefined,
  progress,
  onRemove,
}: FileObjectProps) {
  const size = 'size' in attachedFile ? attachedFile.size : attachedFile.file_size;
  const percentageComplete = progress ? toPercent(progress / size) : 0;
  return (
    <div
      className="mb-4 flex flex-row items-center justify-between rounded-lg border border-neutral-200 bg-white p-4"
      data-testid="file-object"
    >
      <div className="mr-6 flex flex-grow">
        <div
          className={cx(
            'flex',
            'h-10',
            'justify-center',
            'items-center',
            'mr-3',
            'rounded-full',
            'text-crate-blue',
            'text-lg',
            'w-10',
            {
              'bg-white': !error,
              border: !error,
              'border-neutral-200': !error,
              'bg-red-500': error,
            },
          )}
        >
          {error && <WarningOutlined className="text-white" />}
          {!error && isNull(progress) && <FileDoneOutlined />}
          {!error && !isNull(progress) && <FileSyncOutlined />}
        </div>
        <div className="flex-grow">
          <div className="text-base font-bold text-neutral-500">
            {attachedFile.name}
          </div>
          {error && (
            <>
              <Text className="text-red-400">{error.message}</Text>
              <Text className="mt-2" pale>
                {error.type === ERROR_TYPES.ERROR_FILE_SIZE && (
                  <FormattedMessage id="cluster.clusterImportFile.sourceDetailsStep.allowedFileSizeText" />
                )}
              </Text>
            </>
          )}

          {!progress && !error && <Text pale>{fromBytes(size).format()}</Text>}

          {typeof progress !== 'undefined' && !error && (
            <>
              <Text pale>
                {`${fromBytes(progress, {
                  basis: 'decimal',
                }).format()} / ${fromBytes(size, {
                  basis: 'decimal',
                }).format()}`}
              </Text>
              <div className="relative ml-0.5 mt-1 h-1 bg-neutral-100">
                <span
                  aria-labelledby="progress-label"
                  aria-valuenow={progress}
                  className="absolute bottom-0 left-0 top-0 h-full rounded-l-sm bg-crate-blue transition-all"
                  role="progressbar"
                  style={{ right: `${100 - percentageComplete}%` }}
                />
              </div>
            </>
          )}
        </div>
      </div>
      {typeof progress !== 'undefined' && !error && (
        <div className="mr-4 flex items-center">
          <Loader color={Loader.colors.PRIMARY} size={Loader.sizes.SMALL} />
          <div className="ml-1.5 text-base text-neutral-500">
            {percentageComplete}%
          </div>
        </div>
      )}
      {!disabled && (
        <button
          className="text-crate-blue"
          disabled={disabled}
          onClick={onRemove}
          type="button"
          data-testid="remove-file-button"
        >
          <CloseOutlined className="text-neutral-500" />
        </button>
      )}
    </div>
  );
}

export default FileObject;
