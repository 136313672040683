import { PropsWithChildren } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Heading, Loader, Text } from '@cratedb/crate-gc-admin';
import { FormattedMessage } from 'react-intl';
import SectionContainer from '../../../../components/SectionContainer';
import { clusterOverview } from '../../../../constants/paths';
import { useGetOrganizationsIdClusters } from '../../../../swrHooks';
import { OrganizationId } from 'src/types';

export type SubscriptionCardProps = PropsWithChildren<{
  logo: string | React.ReactNode;
  reference?: string;
  subscriptionId: string;
  title: string;
}>;

function SubscriptionCard({
  children,
  logo,
  reference,
  subscriptionId,
  title,
}: SubscriptionCardProps) {
  const { organizationId } = useParams();

  const { data: clusters, isLoading: clustersIsLoading } =
    useGetOrganizationsIdClusters(organizationId as OrganizationId);

  const clusterList = clusters?.filter(
    cluster => cluster.subscription_id === subscriptionId,
  );

  return (
    <SectionContainer>
      <div className="grid grid-cols-1 gap-2 xl:grid-cols-[72px,200px,1fr]">
        {/* logo column */}
        <div className="row-span-3 hidden xl:row-span-2 xl:block">{logo}</div>

        {/* title column */}
        <div className="flex justify-between xl:col-span-2">
          <div>
            <Heading className="leading-snug" level="h3">
              {title}
            </Heading>
            {reference && <Text pale>{reference}</Text>}
          </div>
        </div>

        {/* clusters column */}
        <div>
          <Heading level="h5">
            <FormattedMessage id="organization.subscriptionCard.clusters" />
          </Heading>
          {clustersIsLoading && (
            <Loader
              align={Loader.alignment.CENTER}
              color={Loader.colors.PRIMARY}
              size={Loader.sizes.SMALL}
            />
          )}
          {!clustersIsLoading &&
            clusterList &&
            clusterList.length > 0 &&
            clusterList?.map(cluster => (
              <div key={cluster.id}>
                <Link
                  to={clusterOverview.build({
                    organizationId,
                    projectId: cluster.project_id,
                    clusterId: cluster.id,
                  })}
                >
                  {cluster.name}
                </Link>
              </div>
            ))}
          {!clustersIsLoading && clusterList && clusterList.length === 0 && (
            <Text>
              <FormattedMessage id="organization.subscriptionCard.noClusters" />
            </Text>
          )}
        </div>

        {/* content column */}
        <div>{children}</div>
      </div>
    </SectionContainer>
  );
}

export default SubscriptionCard;
