import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { useEffect } from 'react';
import { IntlProvider } from 'react-intl';
import countries from 'i18n-iso-countries';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { SWRConfig } from 'swr';
import { GcAdminAntdProvider } from '@cratedb/crate-gc-admin';
import { routes } from '../../constants/routes';
import sentry from '../../integrations/Sentry';
import NotificationHandler from '../NotificationHandler';
import InitializeApp from '../InitializeApp';
import SegmentLoader from '../SegmentLoader/SegmentLoader';
import {
  STRIPE_DEVELOPMENT_PUBLISHABLE_KEY as developmentKey,
  STRIPE_PRODUCTION_PUBLISHABLE_KEY as productionKey,
} from '../../constants/defaults';
import messages from '../../rootMessages';
import FeatureDetectProvider from '../FeatureDetectProvider';
import isBrowserFeatureCompliant from '../../utils/isBrowserFeatureCompliant';
import swrFetch from '../../utils/swrFetch';
import { useEnvironment } from '../../hooks';
import AntdProvider from '../AntdProvider';

// eslint-disable-next-line
countries.registerLocale(require('i18n-iso-countries/langs/en.json'));

export const intlErrorHandler = (err: unknown) => {
  sentry.callSentry(err);
};

function App() {
  const [isProduction] = useEnvironment();

  useEffect(() => {
    sentry.setupSentry();
  }, []);

  const stripePromise = loadStripe(isProduction ? productionKey : developmentKey);

  return (
    <AntdProvider>
      <GcAdminAntdProvider>
        <FeatureDetectProvider
          featuresSupported={isBrowserFeatureCompliant()}
          render={() => (
            <SWRConfig value={{ fetcher: swrFetch }}>
              <IntlProvider
                locale="en"
                messages={messages.en}
                onError={intlErrorHandler}
              >
                <Elements stripe={stripePromise}>
                  <BrowserRouter>
                    <InitializeApp>
                      <Routes>
                        {routes.map(route => {
                          return (
                            <Route
                              element={<route.component />}
                              key={route.path.path}
                              path={`${route.path.path}${
                                route.withSubroutes ? '/*' : ''
                              }`}
                            />
                          );
                        })}
                      </Routes>
                      <SegmentLoader />
                    </InitializeApp>
                    <NotificationHandler />
                  </BrowserRouter>
                </Elements>
              </IntlProvider>
            </SWRConfig>
          )}
        />
      </GcAdminAntdProvider>
    </AntdProvider>
  );
}

export default App;
