import { FormattedMessage, useIntl } from 'react-intl';
import { Form, Input } from 'antd';
import { Button } from '@cratedb/crate-gc-admin';
import { postHubspotForm } from '../../../api';
import { HUBSPOT_FORMS } from '../../../constants/hubspot';
import { useGetUsersMe } from '../../../swrHooks';
import useMessage from '../../../hooks/useMessage';
import INPUT_SANITIZATION from '../../../constants/inputSanitization';

type HubspotFormGenericRequestFields = {
  email?: string;
  firstname?: string;
  lastname?: string;
  phone?: string;
  tell_us?: string;
};

export type HubspotFormGenericRequestProps = {
  hiddenFields?: { cluster_configuration?: string; description?: string };
  initialValues?: HubspotFormGenericRequestFields;
  onFormCancel: () => void;
  onFormComplete: () => void;
  requestType: string;
  testId?: string;
};

function HubspotFormGenericRequest({
  requestType,
  onFormCancel,
  onFormComplete,
  initialValues,
  hiddenFields,
  testId,
}: HubspotFormGenericRequestProps) {
  const [form] = Form.useForm();
  const { data: user } = useGetUsersMe();
  const { formatMessage } = useIntl();
  const { showErrorMessage, showSuccessMessage } = useMessage();

  const onFinish = async (values: HubspotFormGenericRequestFields) => {
    const response = await postHubspotForm(
      HUBSPOT_FORMS.CLOUD_CONSOLE_GENERIC_REQUEST,
      {
        ...values,
        request_type: requestType,
        ...hiddenFields,
      },
    );

    if (response.success) {
      form.resetFields();
      showSuccessMessage(formatMessage({ id: 'hubspot.common.messageSuccess' }));
      onFormComplete();
    } else {
      showErrorMessage(formatMessage({ id: 'hubspot.common.messageFail' }));
    }
  };

  if (!user) {
    return null;
  }

  return (
    <Form
      form={form}
      name="hubspot-generic-request-form"
      aria-label="hubspot-generic-request-form"
      data-testid={testId}
      onFinish={onFinish}
      autoComplete="off"
      layout="vertical"
      initialValues={{
        email: user.email,
        firstname: '',
        lastname: '',
        ...initialValues,
      }}
    >
      {/* firstname */}
      <Form.Item
        label={<FormattedMessage id="hubspot.common.firstName" />}
        name="firstname"
        rules={[INPUT_SANITIZATION.HUBSPOT_NAME]}
      >
        <Input />
      </Form.Item>

      {/* lastname */}
      <Form.Item
        label={<FormattedMessage id="hubspot.common.lastName" />}
        name="lastname"
        rules={[INPUT_SANITIZATION.HUBSPOT_NAME]}
      >
        <Input />
      </Form.Item>

      {/* email */}
      <Form.Item
        label={<FormattedMessage id="common.email" />}
        name="email"
        rules={[INPUT_SANITIZATION.GENERIC_EMAIL]}
      >
        <Input />
      </Form.Item>

      {/* phone */}
      <Form.Item
        label={<FormattedMessage id="hubspot.common.phone" />}
        name="phone"
        rules={[INPUT_SANITIZATION.PHONE_OPTIONAL]}
      >
        <Input
          placeholder={formatMessage({
            id: 'hubspot.common.phoneIncludeCountryCode',
          })}
        />
      </Form.Item>

      {/* message */}
      <Form.Item
        label={<FormattedMessage id="hubspot.common.message" />}
        name="tell_us"
        rules={[
          {
            ...INPUT_SANITIZATION.HUBSPOT_MESSAGE,
            required: false,
            whitespace: false,
          },
        ]}
      >
        <Input.TextArea autoSize={{ minRows: 4, maxRows: 8 }} />
      </Form.Item>

      <div className="flex justify-end">
        <Button
          type={Button.types.BUTTON}
          onClick={onFormCancel}
          kind={Button.kinds.SECONDARY}
          size={Button.sizes.SMALL}
        >
          <FormattedMessage id="common.cancel" />
        </Button>
        <Button
          type={Button.types.SUBMIT}
          size={Button.sizes.SMALL}
          className="ml-2"
        >
          <FormattedMessage id="common.ok" />
        </Button>
      </div>
    </Form>
  );
}

export default HubspotFormGenericRequest;
