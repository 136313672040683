import cx from 'classnames';
import colors, { TagColorType } from './constants';
import React from 'react';

export type TagProps = {
  color?: TagColorType;
  label: React.ReactNode | React.ReactNode[];
};

function Tag({ label, color = colors.PRIMARY }: TagProps) {
  return (
    <span
      className={cx(
        color,
        'font-bold',
        'px-3',
        'py-1',
        'rounded-full',
        'text-xs',
        'uppercase',
        'text-white',
        'tracking-wide',
        {
          'text-white': color === colors.PRIMARY,
          'text-[#222]': color === colors.SECONDARY,
        },
      )}
    >
      {label}
    </span>
  );
}

Tag.colors = colors;

export default Tag;
