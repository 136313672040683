import React from 'react';
import cx from 'classnames';
import { Loader } from '@cratedb/crate-gc-admin';

export type LoadingContainerAlignment = 'center' | 'left';

export type LoadingContainerProps = {
  loaderAlignment?: LoadingContainerAlignment;
  loading: boolean;
  render: () => React.ReactNode;
  isViewContainer?: boolean;
};

function LoadingContainer({
  loaderAlignment = 'left',
  loading,
  render,
  isViewContainer = false,
}: LoadingContainerProps) {
  const classes = cx('w-full', {
    'pt-[calc(50vh-80px)]': isViewContainer,
  });

  return loading ? (
    <Loader
      align={loaderAlignment}
      color={Loader.colors.PRIMARY}
      size={Loader.sizes.MEDIUM}
      className={classes}
    />
  ) : (
    render()
  );
}

LoadingContainer.loaderAlignment = {
  CENTER: 'center' as LoadingContainerAlignment,
  LEFT: 'left' as LoadingContainerAlignment,
};

export default LoadingContainer;
