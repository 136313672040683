import React from 'react';
import PropTypes from 'prop-types';
// import { FormattedMessage } from 'react-intl';
import { Heading, Text } from '@cratedb/crate-gc-admin';
import { FormattedMessage, useIntl } from 'react-intl';
import SubscriptionCard from '../SubscriptionCard';
import crateLogo from '../../../../assets/logo_shape.svg';

function ContractSubscriptionCard({ subscription }) {
  const { formatMessage } = useIntl();

  return (
    <SubscriptionCard
      logo={<img src={crateLogo} className="mt-1 h-8 w-12" alt="Crate logo" />}
      reference={`ID: ${subscription.id}`}
      subscriptionId={subscription.id}
      title={formatMessage({
        id: 'organization.contractSubscriptionCard.customContract',
      })}
    >
      <div>
        <Heading level={Heading.levels.h5}>
          <FormattedMessage id="organization.contractSubscriptionCard.additionalInfo" />
        </Heading>
        <Text>
          <FormattedMessage id="organization.contractSubscriptionCard.contactUs" />
        </Text>
      </div>
    </SubscriptionCard>
  );
}

ContractSubscriptionCard.propTypes = {
  subscription: PropTypes.object.isRequired,
};

export default ContractSubscriptionCard;
