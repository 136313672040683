import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { Modal } from 'antd';
import { Button, Heading, NoDataView, Text } from '@cratedb/crate-gc-admin';
import countries from 'i18n-iso-countries';
import ConstrainWidth from '../../../components/ConstrainWidth';
import CreditsRemaining from '../../../components/CreditsRemaining';
import CustomerForm from '../../../components/CustomerForm';
import SectionContainer from '../../../components/SectionContainer';
import ViewContainer from '../../../components/ViewContainer';
import AddSubscription from './AddSubscription';
import ContractSubscriptionCard from './ContractSubscriptionCard';
import CreditCardSubscriptionCard from './CreditCardSubscriptionCard';
import MarketplaceSubscriptionCard from './MarketplaceSubscriptionCard';
import BankTransferSubscriptionCard from './BankTransferSubscriptionCard';
import {
  useGetOrganizationsIdCustomer,
  useGetOrganizationsIdSubscriptions,
} from '../../../swrHooks';
import { SUBSCRIPTION_PROVIDER } from '../../../constants/defaults';

function Billing() {
  const { organizationId } = useParams();
  const [showBillingInformationModal, setShowBillingInformationModal] =
    useState(false);
  const [showAddSubscriptionModal, setShowAddSubscriptionModal] = useState(false);

  const { data: customer, isLoading: customerLoading } =
    useGetOrganizationsIdCustomer(organizationId);

  const { data: subscriptions, isLoading: subscriptionsLoading } =
    useGetOrganizationsIdSubscriptions(organizationId);

  // sort subscriptions with bank cards first
  // followed by marketplaces alphabetically,
  // then bank transfer,
  // then contracts last, for no other reason than
  // we've always displayed them this way :)
  const subscriptionSort = {
    [SUBSCRIPTION_PROVIDER.STRIPE]: 1,
    [SUBSCRIPTION_PROVIDER.AWS]: 2,
    [SUBSCRIPTION_PROVIDER.AZURE]: 3,
    [SUBSCRIPTION_PROVIDER.GCP]: 4,
    [SUBSCRIPTION_PROVIDER.STRIPE_BANK_TRANSFER]: 5,
    [SUBSCRIPTION_PROVIDER.CONTRACT]: 6,
  };

  const drawBillingInformation = () => {
    if (!customer?.email) {
      return (
        <Text pale>
          <FormattedMessage id="organization.billing.provideInvoiceDetails" />
        </Text>
      );
    }

    return (
      <div className="grid grid-cols-1 gap-2 md:grid-cols-2 lg:grid-cols-1">
        <div>
          <Text>{customer.name}</Text>
          <Text>{customer.address.line1}</Text>
          {customer.address.line2 && <Text>{customer.address.line2}</Text>}
          <Text>{customer.address.city}</Text>
          <Text>{countries.getName(customer.address.country, 'en')}</Text>
          <Text>{customer.address.postal_code}</Text>
        </div>
        <div className="space-y-2">
          <div>
            <Text>{customer.email}</Text>
            <Text>{customer.phone}</Text>
          </div>
          {customer.tax.tax_id && (
            <div>
              <Text>
                <FormattedMessage id="organization.billing.vatId" />:{' '}
                {customer.tax.tax_id}
              </Text>
            </div>
          )}
        </div>
      </div>
    );
  };

  const drawSubscriptions = () => {
    const activeSubscriptions = subscriptions
      ?.filter(subscription => {
        if (
          subscription.provider === SUBSCRIPTION_PROVIDER.STRIPE ||
          subscription.provider === SUBSCRIPTION_PROVIDER.STRIPE_BANK_TRANSFER
        ) {
          // stripe subscriptions are shown even when inactive
          return true;
        }
        return subscription.active;
      })
      .sort((a, b) => subscriptionSort[a.provider] - subscriptionSort[b.provider]);

    if (!activeSubscriptions || activeSubscriptions.length === 0) {
      return (
        <NoDataView
          description={
            <FormattedMessage id="organization.billing.noSubscriptions" />
          }
        />
      );
    }

    return activeSubscriptions.map(subscription => {
      switch (subscription.provider) {
        case SUBSCRIPTION_PROVIDER.CONTRACT:
          return (
            <ContractSubscriptionCard
              key={subscription.id}
              subscription={subscription}
            />
          );
        case SUBSCRIPTION_PROVIDER.AWS:
        case SUBSCRIPTION_PROVIDER.AZURE:
        case SUBSCRIPTION_PROVIDER.GCP:
          return (
            <MarketplaceSubscriptionCard
              key={subscription.id}
              provider={subscription.provider}
              subscription={subscription}
            />
          );
        case SUBSCRIPTION_PROVIDER.STRIPE_BANK_TRANSFER:
          return (
            <BankTransferSubscriptionCard
              key={subscription.id}
              subscription={subscription}
            />
          );
        default:
          return (
            <CreditCardSubscriptionCard
              key={subscription.id}
              subscription={subscription}
            />
          );
      }
    });
  };

  return (
    <>
      <ConstrainWidth>
        <ViewContainer
          extra={
            <Button
              onClick={() => {
                setShowAddSubscriptionModal(true);
              }}
            >
              <FormattedMessage id="organization.billing.addPaymentMethod" />
            </Button>
          }
          heading={<FormattedMessage id="organization.billing.pageTitle" />}
          loading={customerLoading || subscriptionsLoading}
          render={() => (
            <div className="lg:grid lg:grid-cols-12 lg:gap-4">
              {/* right column */}
              <div className="order-first mb-4 lg:order-last lg:col-span-4">
                <SectionContainer>
                  <div className="mb-2 flex items-baseline justify-between">
                    <Heading level="h3">
                      <FormattedMessage id="organization.billing.billingInformation" />
                    </Heading>
                    <button
                      className="text-crate-blue outline-none"
                      onClick={() => setShowBillingInformationModal(true)}
                      type="button"
                    >
                      <FormattedMessage id="common.edit" />
                    </button>
                  </div>
                  {drawBillingInformation()}
                </SectionContainer>
                <CreditsRemaining />
              </div>

              {/* left column */}
              <div className="space-y-4 lg:order-first lg:col-span-8 lg:mb-0">
                {drawSubscriptions()}
              </div>
            </div>
          )}
        />
      </ConstrainWidth>

      {/* billing information modal */}
      <Modal
        footer={null}
        onCancel={() => setShowBillingInformationModal(false)}
        open={showBillingInformationModal}
        width={600}
      >
        <CustomerForm
          onCancel={() => setShowBillingInformationModal(false)}
          onSuccess={() => setShowBillingInformationModal(false)}
        />
      </Modal>

      {/* add subscription modal */}
      {showAddSubscriptionModal && (
        <Modal
          footer={null}
          onCancel={() => setShowAddSubscriptionModal(false)}
          open
          width={600}
        >
          <AddSubscription
            setShowAddSubscriptionModal={setShowAddSubscriptionModal}
          />
        </Modal>
      )}
    </>
  );
}

export default Billing;
