import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { uniq } from 'lodash/fp';
import { CloseOutlined, RightOutlined } from '@ant-design/icons';
import { Heading, Text } from '@cratedb/crate-gc-admin';
import ConstrainWidth from '../../../components/ConstrainWidth';
import JWTManagerWrapper from '../../../components/JWTManagerWrapper';
import SectionContainer from '../../../components/SectionContainer';
import { LEARN_ARTICLES, LEARN_TAGS } from './data';

function ClusterLearn() {
  // this component allows the user to filter the articles by tag
  // currently, there are only a few articles so better we don't
  // show this option, but if/when we do, the functionality is here
  const USE_FILTERS = false;

  const [selectedArticle, setSelectedArticle] = useState(null);
  const [selectedTag, setSelectedTag] = useState(null);

  const tagsInUse = uniq(
    LEARN_ARTICLES.map(group => group.articles)
      .flat()
      .map(article => article.tags)
      .flat(),
  ).sort();

  let articles = [...LEARN_ARTICLES];
  if (selectedTag) {
    articles = articles
      .map(group => ({
        ...group,
        articles: group.articles.filter(article =>
          article.tags.includes(selectedTag),
        ),
      }))
      .filter(group => group.articles.length > 0);
  }

  const drawArticles = () => (
    <>
      {articles.map(group => (
        <div key={`article_group_${group.title}`} className="mb-4">
          {group.title && <Heading level={Heading.levels.h4}>{group.title}</Heading>}
          {group.description && <Text pale>{group.description}</Text>}
          <div className="my-2 grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
            {group.articles.map(article => (
              <button
                className="flex min-h-[124px] flex-col items-start justify-between rounded bg-crate-blue p-3 text-left text-white"
                onClick={() => setSelectedArticle(article)}
                key={`article_${article.title}`}
                type="button"
              >
                <div>
                  <div className="text-lg leading-snug">{article.dataType}</div>
                  <div className="text-md leading-snug">{article.title}</div>
                </div>
                <div className="text-xs text-white/80">
                  <div>
                    <FormattedMessage
                      id="clusterLearn.readingTime"
                      values={{ count: article.time }}
                    />
                  </div>
                  {USE_FILTERS && (
                    <div className="flex items-center gap-1 text-white">
                      <span>
                        <FormattedMessage id="'clusterLearn.tags'" />:
                      </span>
                      {article.tags.map((tag, index) => (
                        <span key={tag}>
                          {LEARN_TAGS[tag]}
                          {index < article.tags.length - 1 && ','}
                        </span>
                      ))}
                    </div>
                  )}
                </div>
              </button>
            ))}
          </div>
        </div>
      ))}
    </>
  );

  const drawFilters = () => {
    const selectedButtonClass =
      'bg-crate-blue/80 px-2 py-0.5 font-semibold rounded text-white text-xs uppercase whitespace-nowrap hover:bg-crate-blue';
    const unselectedButtonClass =
      'bg-crate-blue/40 px-2 py-0.5 font-semibold rounded text-white text-xs uppercase whitespace-nowrap hover:bg-crate-blue';

    return (
      <div className="mb-8">
        <div className="flex items-center gap-4">
          <div className="text-sm">
            <FormattedMessage id="clusterLearn.filterBySubject" />:{' '}
          </div>
          <div className="flex flex-wrap items-center gap-1">
            {tagsInUse.map(tag => (
              <button
                className={
                  [tag, null].includes(selectedTag)
                    ? selectedButtonClass
                    : unselectedButtonClass
                }
                key={tag}
                onClick={() => setSelectedTag(tag)}
                type="button"
              >
                {LEARN_TAGS[tag]}
              </button>
            ))}
            {selectedTag && (
              <button
                className="flex items-center gap-1 rounded bg-crate-border-light/50 px-2 py-0.5 text-xs font-semibold uppercase hover:bg-crate-border-light"
                onClick={() => setSelectedTag(null)}
                type="button"
              >
                <CloseOutlined />
                <span>
                  <FormattedMessage id="clusterLearn.clear" />
                </span>
              </button>
            )}
          </div>
        </div>
      </div>
    );
  };

  return (
    <JWTManagerWrapper>
      <ConstrainWidth>
        <SectionContainer>
          {!selectedArticle && (
            <>
              <Heading className="mb-2" level={Heading.levels.h2}>
                <FormattedMessage id="clusterLearn.getStartedTitle" />
              </Heading>
              <Text className="mb-4">
                Welcome to the tutorials section, the next step in your CrateDB Cloud
                journey! Here you’ll find comprehensive guides designed to help you
                make the most of your newly set-up cluster. Whether you are just
                getting started, or aim to deepen your understanding, these tutorials
                will walk you through key features and efficient practices to
                optimize your CrateDB experience.
              </Text>
              {USE_FILTERS && drawFilters()}
              {drawArticles()}
            </>
          )}

          {selectedArticle && (
            <>
              <div className="flex items-center gap-2">
                <button
                  className="flex items-center gap-2"
                  onClick={() => setSelectedArticle(null)}
                  type="button"
                >
                  <span className="text-crate-blue">
                    <FormattedMessage id="clusterLearn.backToTutorials" />
                  </span>
                </button>
                <RightOutlined className="text-[10px] text-neutral-500" />
              </div>
              <Heading className="mb-8" level={Heading.levels.h2}>
                {selectedArticle.title}
              </Heading>

              {selectedArticle.component}

              <div className="flex gap-1 py-4">
                <FormattedMessage id="clusterLearn.backToTutorialsLinkPrefix" />
                <button
                  className="flex items-center gap-2"
                  onClick={() => setSelectedArticle(null)}
                  type="button"
                >
                  <span className="text-crate-blue">
                    <FormattedMessage id="clusterLearn.backToTutorialsLinkSuffix" />
                  </span>
                </button>
              </div>
            </>
          )}
        </SectionContainer>
      </ConstrainWidth>
    </JWTManagerWrapper>
  );
}

export default ClusterLearn;
