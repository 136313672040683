import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { message, Popconfirm, Select } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  CloseOutlined,
  DeleteOutlined,
  EditOutlined,
  ReloadOutlined,
  SaveOutlined,
} from '@ant-design/icons';
import moment from 'moment';
import { Button } from '@cratedb/crate-gc-admin';
import SectionContainer from '../../../components/SectionContainer';
import { getUserIsOrganizationAdmin } from '../../../utils/data/user';
import {
  useGetOrganizationsIdInvitations,
  useGetOrganizationsIdUsers,
  useGetRoles,
  useGetUsersMe,
} from '../../../swrHooks';
import { apiDelete, apiPost } from '../../../api';
import useMessage from '../../../hooks/useMessage';
import CloudUITable from '../../../components/CloudUITable';
import AddUserDrawer from './AddUserDrawer';
import sortArrayAlphabetically from '../../../utils/sort';
import { getRoleNameFromRoles } from '../../../utils';

function UserList({ organizationId }) {
  const { formatMessage } = useIntl();

  const { data: loggedInUser } = useGetUsersMe();
  const { data: roles } = useGetRoles();
  const { data: users, mutate: mutateUsers } =
    useGetOrganizationsIdUsers(organizationId);
  const { data: invitations, mutate: mutateInvitations } =
    useGetOrganizationsIdInvitations(organizationId);

  const [addUserDrawerVisible, setAddUserDrawerVisible] = useState(false);
  const [currentlyEditingUser, setCurrentlyEditingUser] = useState(null);
  const [selectedRole, setSelectedRole] = useState(null);

  const { showLoadingMessage, showSuccessMessage } = useMessage();
  const userIsOrgAdmin = useMemo(
    () => getUserIsOrganizationAdmin(loggedInUser, organizationId),
    [loggedInUser, organizationId],
  );

  const getUserIsBeingEdited = user => user.uid === currentlyEditingUser;
  const getIsPendingInvitation = userOrInvitation => userOrInvitation.status;
  const getExpiryState = userOrInvitation =>
    getIsPendingInvitation(userOrInvitation) &&
    moment.utc(userOrInvitation.expiration_date).diff(moment.utc(), 'minutes') < 0;

  const prepareTableData = () => {
    const sortedUsers = sortArrayAlphabetically(users, 'email');
    const sortedInvitations = sortArrayAlphabetically(invitations, 'email');

    return sortedUsers.concat(sortedInvitations);
  };

  const editUser = user => {
    setCurrentlyEditingUser(user.uid);
    setSelectedRole(user.organization_roles[0].role_fqn);
  };

  const exitEditUser = () => {
    setCurrentlyEditingUser('');
    setSelectedRole(null);
  };

  const saveEditedUser = async () => {
    showLoadingMessage(
      formatMessage({ id: 'organization.organizationUsers.updatingUserText' }),
    );

    const { success } = await apiPost(
      `/api/v2/organizations/${organizationId}/users/`,
      { user: currentlyEditingUser, role_fqn: selectedRole },
    );

    if (success) {
      message.destroy();
      showSuccessMessage(
        formatMessage({ id: 'organization.organizationUsers.userUpdatedText' }),
      );
      exitEditUser();
    }
  };

  const removeUserFromOrg = async userOrInvitation => {
    if (getIsPendingInvitation(userOrInvitation)) {
      showLoadingMessage(
        formatMessage(
          { id: 'organization.organizationUsers.cancellingInvitationText' },
          { email: userOrInvitation.email },
        ),
      );

      const { success } = await apiDelete(
        `/api/v2/organizations/${organizationId}/invitations/${userOrInvitation.uid}/`,
        {},
      );

      if (success) {
        message.destroy();
        showSuccessMessage(
          formatMessage(
            { id: 'organization.organizationUsers.invitationCancelledText' },
            { email: userOrInvitation.email },
          ),
        );
        mutateInvitations();
      }
    } else {
      showLoadingMessage(
        formatMessage(
          { id: 'organization.organizationUsers.removingUserText' },
          { email: userOrInvitation.email },
        ),
      );

      const { success } = await apiDelete(
        `/api/v2/organizations/${organizationId}/users/${userOrInvitation.uid}/`,
        {},
      );

      if (success) {
        message.destroy();
        showSuccessMessage(
          formatMessage(
            { id: 'organization.organizationUsers.userRemovedText' },
            { email: userOrInvitation.email },
          ),
        );
        mutateUsers();
      }
    }
  };

  const resendInvitation = async invitation => {
    showLoadingMessage(
      formatMessage(
        { id: 'organization.organizationUsers.reInvitingUserText' },
        { email: invitation.email },
      ),
    );

    const { success } = await apiPost(
      `/api/v2/organizations/${organizationId}/users/`,
      {
        user: invitation.email,
        role_fqn: invitation.organization_roles[0].role_fqn,
      },
    );
    if (success) {
      message.destroy();
      showSuccessMessage(
        formatMessage(
          { id: 'organization.organizationUsers.userReInvitedText' },
          { email: invitation.email },
        ),
      );
      mutateInvitations();
    }
  };

  const renderUserRole = user => {
    const userRole = user.organization_roles[0]?.role_fqn;

    if (getUserIsBeingEdited(user)) {
      return (
        <Select
          defaultValue={userRole}
          onChange={value => setSelectedRole(value)}
          options={roles
            .filter(role => !role.id.startsWith('project')) // hack to hide project admin/member roles
            .map(role => ({ label: role.name, value: role.id }))}
        />
      );
    }

    return getRoleNameFromRoles(userRole, roles);
  };

  const renderUsername = userOrInvitation => {
    return getIsPendingInvitation(userOrInvitation)
      ? '-'
      : userOrInvitation.username;
  };

  const renderUserAuthMethod = userOrInvitation => {
    return formatMessage({
      id: `organization.organizationUsers.authMethod.${userOrInvitation.idp}`,
      defaultMessage: '-',
    });
  };

  const renderUserStatus = userOrInvitation => {
    // only invitations have statuses
    if (getIsPendingInvitation(userOrInvitation)) {
      if (getExpiryState(userOrInvitation)) {
        return formatMessage({
          id: 'organization.organizationUsers.expiredText',
        });
      }

      const expiresInMinutes = moment
        .utc(userOrInvitation.expiration_date)
        .diff(moment.utc(), 'minutes');

      // expires in n minutes
      if (expiresInMinutes < 60) {
        return formatMessage(
          { id: 'organization.organizationUsers.expiresInMinutesText' },
          { expiresInMinutes },
        );
      }

      // expires in n hours
      return formatMessage(
        { id: 'organization.organizationUsers.expiresInText' },
        { expiresInHours: Math.ceil(expiresInMinutes / 60) },
      );
    }

    return formatMessage({
      id: 'organization.organizationUsers.activeText',
    });
  };

  const renderUserActions = user => {
    if (getUserIsBeingEdited(user)) {
      return (
        <>
          <div className="mr-2 inline-block">
            <button
              aria-label={formatMessage({ id: 'common.save' })}
              data-testid="save-icon"
              onClick={saveEditedUser}
              type="button"
            >
              <SaveOutlined className="text-crate-blue" />
            </button>
          </div>
          <div className="mr-2 inline-block">
            <button
              aria-label={formatMessage({ id: 'common.cancel' })}
              data-testid="cancel-icon"
              onClick={exitEditUser}
              type="button"
            >
              <CloseOutlined className="text-crate-blue" />
            </button>
          </div>
        </>
      );
    }

    const userIsLoggedInUser = user.uid === loggedInUser.uid;

    return (
      <>
        {/* edit user button */}
        {!getIsPendingInvitation(user) && (
          <div className="mr-2 inline-block">
            <button
              aria-label={formatMessage({ id: 'common.edit' })}
              data-testid={`edit-user-button-${user.uid}`}
              onClick={() => editUser(user)}
              disabled={userIsLoggedInUser}
              type="button"
            >
              <EditOutlined
                className={
                  userIsLoggedInUser ? 'text-crate-border-mid' : 'text-crate-blue'
                }
              />
            </button>
          </div>
        )}

        {/* resend invitation button */}
        {getExpiryState(user) && (
          <div className="mr-2 inline-block">
            <button
              aria-label={
                <FormattedMessage id="organization.organizationUsers.resendInvitationText" />
              }
              className="w-full"
              data-testid={`resend-invitation-button-${user.uid}`}
              onClick={() => resendInvitation(user)}
              type="button"
            >
              <ReloadOutlined className="text-crate-blue" />
            </button>
          </div>
        )}

        {/* delete button */}
        <div className="inline-block">
          <Popconfirm
            cancelText={<FormattedMessage id="common.no" />}
            disabled={userIsLoggedInUser}
            okText={<FormattedMessage id="common.yes" />}
            onConfirm={() => removeUserFromOrg(user)}
            placement="topLeft"
            title={
              getIsPendingInvitation(user) ? (
                <FormattedMessage id="organization.organizationUsers.cancelInviteText" />
              ) : (
                <FormattedMessage id="organization.organizationUsers.removeUserText" />
              )
            }
          >
            <button
              aria-label={formatMessage({ id: 'common.delete' })}
              data-testid={`delete-user-button-${user.uid}`}
              disabled={userIsLoggedInUser}
              type="button"
            >
              <DeleteOutlined
                className={
                  userIsLoggedInUser ? 'text-crate-border-mid' : 'text-crate-blue'
                }
              />
            </button>
          </Popconfirm>
        </div>
      </>
    );
  };

  const getColumns = () => {
    const columns = [
      {
        title: <FormattedMessage id="common.username" />,
        key: 'username',
        render: renderUsername,
      },
      {
        title: <FormattedMessage id="common.email" />,
        dataIndex: 'email',
        key: 'email',
      },
      {
        title: <FormattedMessage id="organization.organizationUsers.roleTitle" />,
        key: `role`,
        render: renderUserRole,
      },
      {
        title: (
          <FormattedMessage id="organization.organizationUsers.authMethodTitle" />
        ),
        key: `authMethod`,
        render: renderUserAuthMethod,
      },
      {
        title: <FormattedMessage id="organization.organizationUsers.statusTitle" />,
        key: `status`,
        render: renderUserStatus,
      },
    ];

    if (userIsOrgAdmin) {
      columns.push({
        key: `actions`,
        render: renderUserActions,
        width: '70px',
      });
    }

    return columns;
  };

  return (
    <SectionContainer
      actions={
        userIsOrgAdmin && (
          <Button
            kind={Button.kinds.TERTIARY}
            onClick={() => setAddUserDrawerVisible(true)}
          >
            <FormattedMessage id="organization.organizationUsers.addUserButton" />
          </Button>
        )
      }
      loading={!loggedInUser || !invitations || !roles || !users}
      testId="user-list"
      title={<FormattedMessage id="organization.organizationUsers.pageTitle" />}
    >
      <>
        <CloudUITable
          columns={getColumns()}
          dataSource={prepareTableData()}
          emptyText={formatMessage({
            id: 'organization.organizationUsers.noUsersYetText',
          })}
          rowKey="uid"
          showHeader
          testId="users-table"
        />
        {roles && (
          <AddUserDrawer
            onClose={() => setAddUserDrawerVisible(false)}
            organizationId={organizationId}
            roles={roles}
            isVisible={addUserDrawerVisible}
          />
        )}
      </>
    </SectionContainer>
  );
}

UserList.propTypes = {
  organizationId: PropTypes.string.isRequired,
};

export default UserList;
