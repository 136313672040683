import { useEffect, useState } from 'react';
import cx from 'classnames';
import { Collapse } from 'antd';
import { FormattedMessage } from 'react-intl';
import { WarningOutlined } from '@ant-design/icons';
import { Text } from '@cratedb/crate-gc-admin';
import { ExportJob, ValueOf } from 'src/types';

const ALIGNMENT_OPTIONS = {
  DIALOG: 'DIALOG',
  SECTION: 'SECTION',
};
const alignmentOptionsMap = {
  [ALIGNMENT_OPTIONS.DIALOG]: 'pl-0',
  [ALIGNMENT_OPTIONS.SECTION]: 'pl-3',
};

const { Panel } = Collapse;

type AlignmentOption = ValueOf<typeof ALIGNMENT_OPTIONS>;

export type ErrorExportingHelpProps = {
  // the alignment prop
  // is a workaround for the scenario when the ErrorExportingHelp
  // component is displayed underneath the attached file and we
  // want the icons of both components to neatly align, vertically
  alignment?: AlignmentOption;
  defaultOpen?: boolean;
  exportJob: ExportJob;
};

function ErrorExportingHelp({
  alignment = ALIGNMENT_OPTIONS.DIALOG,
  defaultOpen,
  exportJob,
}: ErrorExportingHelpProps) {
  const [helpIsVisible, setHelpIsVisible] = useState(false);

  useEffect(() => {
    setHelpIsVisible(!!defaultOpen);
  }, [defaultOpen]);

  return (
    <Collapse
      defaultActiveKey={defaultOpen ? '1' : undefined}
      onChange={() => setHelpIsVisible(!helpIsVisible)}
    >
      <Panel
        header={
          <div
            className={cx(
              'flex',
              'items-center',
              'justify-between',
              alignmentOptionsMap[alignment],
            )}
          >
            <div className="flex items-center">
              <WarningOutlined
                className="mr-2 text-base text-red-500"
                data-testid="error-icon"
              />
              <div className="text-neutral-500">
                <FormattedMessage
                  id="cluster.clusterExport.detailStatusExportFailedText"
                  values={{
                    records:
                      (exportJob?.progress.records > 0
                        ? exportJob?.progress.records
                        : exportJob?.progress.failed_records) || 0,
                  }}
                />
              </div>
            </div>
            <div className="text-crate-blue">
              {helpIsVisible ? (
                <FormattedMessage id="cluster.clusterExport.helpCollapseOpenButton" />
              ) : (
                <FormattedMessage id="cluster.clusterExport.helpCollapseClosedButton" />
              )}
            </div>
          </div>
        }
        key="1"
        showArrow={false}
      >
        <Text className="break-words" pale>
          {exportJob.progress.message}
        </Text>
      </Panel>
    </Collapse>
  );
}

ErrorExportingHelp.alignment = ALIGNMENT_OPTIONS;

export default ErrorExportingHelp;
