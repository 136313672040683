import React from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { FormattedNumber } from 'react-intl';
import { SettingOutlined } from '@ant-design/icons';
import { DisplayDate, Text } from '@cratedb/crate-gc-admin';
import { fromCents } from '../../../utils';
import { PRICE_OPTIONS_2DP } from '../../../constants/defaults';
import { DATETIME_FORMAT } from '../../../constants/dates';
import { organizationClustersList, clusterOverview } from '../../../constants/paths';
import { auditLogEntryPropType } from '../../../models';
import sentry from '../../../integrations/Sentry';

function AuditLogMessage({ logEntry }) {
  const actorInitial = logEntry.actor?.email ? (
    logEntry.actor.email.charAt(0).toUpperCase()
  ) : (
    <SettingOutlined className="align-baseline text-sm" />
  );

  const renderOrgLink = context => {
    const orgLink = organizationClustersList.build({
      organizationId: context.organization.id,
    });
    return (
      <a href={orgLink} rel="noopener noreferrer" target="_blank">
        {context.organization.name}
      </a>
    );
  };

  const renderClusterLink = context => {
    return (
      <Link
        to={clusterOverview.build({
          organizationId: context.organization.id,
          projectId: context.project.id,
          clusterId: context.cluster.id,
        })}
      >
        {context.cluster.name}
      </Link>
    );
  };

  const renderMessageIfPresent = o =>
    o.message ? <span>with message: {o.message}</span> : '';

  const getUser = ({ name, email }) => name || email;

  const renderActor = actor => {
    return (
      <Text className="font-bold">
        <span className="text-gray-700">{getUser(actor)}</span>
      </Text>
    );
  };

  const renderMessage = () => {
    const { actor, action, context } = logEntry;
    let user;
    switch (action) {
      // Organization Actions
      case 'organization.create':
        return (
          <Text pale>
            {renderActor(actor)} created organization {renderOrgLink(context)}
          </Text>
        );

      case 'organization.edit':
        return (
          <Text pale>
            {renderActor(actor)} edited organization {renderOrgLink(context)}
          </Text>
        );

      case 'organization.add_user':
        user = context.added_user;
        return (
          <Text pale>
            {renderActor(actor)} added {getUser(user)} to organization{' '}
            {renderOrgLink(context)}
          </Text>
        );

      case 'organization.update_user':
        user = context.updated_user;
        return (
          <Text pale>
            {renderActor(actor)} changed {getUser(user)} to role {context.role} in
            organization {renderOrgLink(context)}
          </Text>
        );

      case 'organization.remove_user':
        user = context.removed_user;
        return (
          <Text pale>
            {renderActor(actor)} removed {getUser(user)} from organization{' '}
            {renderOrgLink(context)}
          </Text>
        );

      case 'organization.invite_user':
        // original audit log entries did not have an "invited_user" context key.
        user = context.invited_user || { email: 'unknown' };
        return (
          <Text pale>
            {renderActor(actor)} invited {getUser(user)} to organization{' '}
            {renderOrgLink(context)}
          </Text>
        );

      // Credit Actions
      case 'credit.create':
        return (
          <Text pale>
            <FormattedNumber
              value={fromCents(context.credit.original_amount)}
              style={PRICE_OPTIONS_2DP.style}
              currency={PRICE_OPTIONS_2DP.currency}
            />{' '}
            credits applied to organization {renderOrgLink(context)}. Credits will
            expire by{' '}
            {moment.utc(context.credit.expiration_date).format(DATETIME_FORMAT)}.
          </Text>
        );

      case 'credit.expire':
        return (
          <Text pale>
            <FormattedNumber
              value={fromCents(context.credit.original_amount)}
              style={PRICE_OPTIONS_2DP.style}
              currency={PRICE_OPTIONS_2DP.currency}
            />{' '}
            credits from organization {renderOrgLink(context)} have expired.
          </Text>
        );

      case 'credit.edit':
        return (
          <Text pale>
            Credits of organization {renderOrgLink(context)} have been edited.{' '}
            {(() => {
              const editTexts = [];
              if (context.credit.expiration_date)
                editTexts.push({
                  key: 'exp_date_edited',
                  text: (
                    <>
                      Credits will expire by{' '}
                      {moment
                        .utc(context.credit.expiration_date)
                        .format(DATETIME_FORMAT)}
                      .
                    </>
                  ),
                });
              if (context.credit.amount)
                editTexts.push({
                  key: 'amount_edited',
                  text: (
                    <>
                      The new credit amount is{' '}
                      <FormattedNumber
                        value={fromCents(context.credit.amount)}
                        style={PRICE_OPTIONS_2DP.style}
                        currency={PRICE_OPTIONS_2DP.currency}
                      />
                      .
                    </>
                  ),
                });
              if (context.credit.comment)
                editTexts.push({
                  key: 'comment_edited',
                  text: (
                    <>Comment was set to &quot;{context.credit.comment}&quot;.</>
                  ),
                });
              return editTexts.map((t, i) => (
                <span key={`credit_${t.key}`}>
                  {t.text}
                  {editTexts[i + 1] ? ' ' : ''}
                </span>
              ));
            })()}
          </Text>
        );

      // Project Actions
      case 'project.create':
        return (
          <Text pale>
            {renderActor(actor)} created project {context.project.name}
          </Text>
        );

      case 'project.edit':
        return (
          <Text pale>
            {renderActor(actor)} edited project {context.project.name}
          </Text>
        );

      case 'project.delete':
        return (
          <Text pale>
            {renderActor(actor)} deleted project{' '}
            <span className="text-gray-700">{context.project.name}</span>
          </Text>
        );

      case 'project.add_user':
        user = context.added_user;
        return (
          <Text pale>
            {renderActor(actor)} added {getUser(user)} to project{' '}
            {context.project.name}
          </Text>
        );

      case 'project.update_user':
        user = context.updated_user;
        return (
          <Text pale>
            {renderActor(actor)} changed {getUser(user)} to role {context.role} in
            project {context.project.name}
          </Text>
        );

      case 'project.remove_user':
        user = context.removed_user;
        return (
          <Text pale>
            {renderActor(actor)} removed {getUser(user)} from project{' '}
            {context.project.name}
          </Text>
        );

      case 'project_backup_location.create':
        return (
          <Text pale>
            {renderActor(actor)} configured a custom backup location for project{' '}
            <span className="text-gray-700">{context.project.name}</span>
          </Text>
        );

      // Cluster Actions
      case 'product.initiate_create_cluster':
        return (
          <Text pale>
            {renderActor(actor)} initiated cluster {renderClusterLink(context)}{' '}
            creation
          </Text>
        );
      case 'product.create_cluster':
        return (
          <Text pale>
            Created cluster {renderClusterLink(context)} in {context.project.name}
          </Text>
        );

      case 'product.initiate_scale_cluster':
        return (
          <Text pale>
            {renderActor(actor)} initiated scaling of cluster{' '}
            {renderClusterLink(context)}
          </Text>
        );
      case 'product.scale_cluster':
        if (actor && actor.id) {
          return <Text pale>Scaled cluster {renderClusterLink(context)}</Text>;
        }
        if (context.cluster.status === 'completed') {
          return (
            <Text pale>
              Scaling cluster {renderClusterLink(context)} succeeded{' '}
              {renderMessageIfPresent(context.cluster)}
            </Text>
          );
        }
        if (context.cluster.status === 'failed') {
          return (
            <Text pale>
              Scaling cluster {renderClusterLink(context)} failed{' '}
              {renderMessageIfPresent(context.cluster)}
            </Text>
          );
        }
        return <Text pale />;

      case 'cluster.initiate_update_whitelist':
        return (
          <Text pale>
            {renderActor(actor)} initiated cluster {renderClusterLink(context)} IP
            allowlist change
          </Text>
        );
      case 'cluster.update_whitelist':
        return (
          <Text pale>
            Updated the whitelist for cluster {renderClusterLink(context)}
          </Text>
        );

      case 'cluster.initiate_change_password':
        return (
          <Text pale>
            {renderActor(actor)} initiated password change for cluster{' '}
            {renderClusterLink(context)}
          </Text>
        );
      case 'cluster.change_password':
        return (
          <Text pale>Changed password for cluster {renderClusterLink(context)}</Text>
        );

      case 'cluster.initiate_edit':
        return (
          <Text pale>
            {renderActor(actor)} started editing cluster {renderClusterLink(context)}
          </Text>
        );
      case 'cluster.edit':
        return <Text pale>Edited cluster {renderClusterLink(context)}</Text>;

      case 'cluster.update':
        return (
          <Text pale>
            A temporary failure occurred for cluster {renderClusterLink(context)}:{' '}
            Backup is currently in progress. The previously triggered cluster{' '}
            operation is delayed until the backup is done.
          </Text>
        );

      case 'cluster.initiate_expand_storage':
        return (
          <Text pale>
            {renderActor(actor)} requested a storage capacity increase for cluster{' '}
            {renderClusterLink(context)}
          </Text>
        );
      case 'cluster.expand_storage':
        return (
          <Text pale>
            Increased the storage capacity for cluster {renderClusterLink(context)}
          </Text>
        );

      case 'cluster.initiate_change_compute':
        return (
          <Text pale>
            {renderActor(actor)} initiated plan change for cluster{' '}
            {renderClusterLink(context)}
          </Text>
        );

      case 'cluster.change_compute':
        return (
          <Text pale>
            Change plan succeeded for cluster {renderClusterLink(context)}
          </Text>
        );

      case 'cluster.initiate_backup_schedule_update':
        return (
          <Text pale>
            {renderActor(actor)} initiated updating the backup schedule for cluster{' '}
            {renderClusterLink(context)}
          </Text>
        );

      case 'cluster.backup_schedule_update':
        return (
          <Text pale>
            Updated the backup schedule for cluster {renderClusterLink(context)}
          </Text>
        );

      case 'product.initiate_upgrade_cluster':
        return (
          <Text pale>
            {renderActor(actor)} initiated an upgrade for cluster{' '}
            {renderClusterLink(context)} to CrateDB version {context.cluster.version}
          </Text>
        );
      case 'product.upgrade_cluster':
        if (actor && actor.id) {
          return (
            <Text pale>
              Upgraded cluster {renderClusterLink(context)} to version{' '}
              {context.cluster.version}
            </Text>
          );
        }
        if (context.cluster.status === 'completed') {
          return (
            <Text pale>
              Upgrading cluster {renderClusterLink(context)} to version{' '}
              {context.cluster.version} succeeded{' '}
              {renderMessageIfPresent(context.cluster)}
            </Text>
          );
        }
        if (context.cluster.status === 'failed') {
          return (
            <Text pale>
              Upgrading cluster {renderClusterLink(context)} to version to version{' '}
              {context.cluster.version} failed{' '}
              {renderMessageIfPresent(context.cluster)}
            </Text>
          );
        }
        return <Text pale />;

      case 'product.delete_cluster':
        return (
          <Text pale>
            {renderActor(actor)} deleted cluster{' '}
            <span className="text-gray-700">{context.cluster.name}</span> in{' '}
            {context.project.name}
          </Text>
        );

      case 'product.delete_cluster_unauthorized':
        return (
          <Text pale>
            {renderActor(actor)} attempted to delete protected cluster{' '}
            {renderClusterLink(context)} in {context.project.name}
          </Text>
        );

      case 'cluster.initiate_restore_snapshot':
        return (
          <Text pale>
            {renderActor(actor)} requested snapshot{' '}
            {context.cluster.snapshot.snapshot} from cluster{' '}
            {context.cluster.snapshot.source_cluster_id} to be restored into cluster{' '}
            {renderClusterLink(context)}
          </Text>
        );

      case 'cluster.restore_snapshot':
        if (context.cluster.status === 'completed') {
          return (
            <Text pale>
              Snapshot {context.cluster.source_snapshot} from cluster{' '}
              {context.cluster.source_cluster_id} was restored to cluster{' '}
              {renderClusterLink(context)}
            </Text>
          );
        }
        return (
          <Text pale>
            Snapshot restore into cluster {renderClusterLink(context)} failed with
            message: {context.cluster.message}
          </Text>
        );

      case 'cluster.initiate_suspend':
        if (context.cluster.target_num_nodes > 0) {
          return (
            <Text pale>
              {renderActor(actor)} requested cluster {renderClusterLink(context)} to
              be resumed
            </Text>
          );
        }
        return (
          <Text pale>
            {renderActor(actor)} requested cluster {renderClusterLink(context)} to be
            suspended
          </Text>
        );
      case 'cluster.suspend':
      case 'cluster.suspend_free_clusters':
        if (context.cluster.num_nodes > 0) {
          return <Text pale>Cluster {renderClusterLink(context)} resumed</Text>;
        }
        return <Text pale>Cluster {renderClusterLink(context)} suspended</Text>;
      case 'cluster.inactive':
        return (
          <Text pale>
            Cluster {renderClusterLink(context)} has been inactive for more than 3
            days
          </Text>
        );
      case 'cluster.no_password_update_required':
        return (
          <Text pale>
            Password change did not require any update for cluster{' '}
            {renderClusterLink(context)}
          </Text>
        );
      case 'cluster.no_whitelist_update_required':
        return (
          <Text pale>
            IP allowlist change did not require any update for cluster{' '}
            {renderClusterLink(context)}
          </Text>
        );
      case 'cluster.no_update_required':
        return (
          <Text pale>
            No change was required for cluster {renderClusterLink(context)}
          </Text>
        );
      case 'cluster.restart_node':
        return (
          <Text pale>
            {renderActor(actor)} requested node {context.cluster.ordinal} of cluster{' '}
            {renderClusterLink(context)} to be restarted
          </Text>
        );

      // SAAS actions
      case 'saas.provision':
        return (
          <Text pale>
            {renderActor(actor)} provisioned plan {context.subscription.plan_id} in{' '}
            {context.project.name}
          </Text>
        );

      case 'saas.unsubscribe':
        if (actor && actor.id) {
          return (
            <Text pale>
              {renderActor(actor)} unsubscribed from{' '}
              <span className="text-gray-700">{context.subscription.name}</span>
            </Text>
          );
        }
        return (
          <Text pale>
            Subscription{' '}
            <span className="text-gray-700">{context.subscription.name}</span> has
            been canceled
          </Text>
        );

      case 'saas.subscribe':
        if (actor && actor.id) {
          return (
            <Text pale>
              {renderActor(actor)} created subscription{' '}
              <span className="text-gray-700">{context.subscription.name}</span>
            </Text>
          );
        }
        return (
          <Text pale>
            Subscription{' '}
            <span className="text-gray-700">{context.subscription.name}</span> has
            been created
          </Text>
        );

      case 'saas.subscription.active':
        return (
          <Text pale>
            Subscription{' '}
            <span className="text-gray-700">{context.subscription.name}</span> has
            been activated
          </Text>
        );

      case 'saas.subscription.org_assigned':
        return (
          <Text pale>
            Subscription{' '}
            <span className="text-gray-700">{context.subscription.name}</span> has
            been assigned to the organization
          </Text>
        );

      // Region actions
      case 'region.create':
        return (
          <Text pale>
            {renderActor(actor)} created edge region{' '}
            <span className="text-gray-700">{context.region.description}</span>
          </Text>
        );

      case 'region.delete':
        return (
          <Text pale>
            {renderActor(actor)} deleted edge region{' '}
            <span className="text-gray-700">{context.region.description}</span>
          </Text>
        );

      // Payment actions
      case 'payment.verifycc.failure':
        return (
          <Text pale>
            Card verification payment for card{' '}
            <span className="text-gray-700">{context.card_brand}</span> ending{' '}
            <span className="text-gray-700">{context.card_last4}</span> has failed:
            &quot;
            {context.message}&quot;.
          </Text>
        );
      // import and file actions
      case 'import_job.initiate':
        return (
          <Text pale>
            {renderActor(actor)} initiated a data import into{' '}
            {renderClusterLink(context)}
          </Text>
        );
      case 'import_job.succeeded':
        return (
          <Text pale>
            Data import into {renderClusterLink(context)} completed successfully
          </Text>
        );
      case 'import_job.failed':
        return (
          <Text pale>
            Data import into {renderClusterLink(context)} completed with errors
          </Text>
        );
      case 'import_job.delete':
        return (
          <Text pale>
            {renderActor(actor)} cancelled a data import into{' '}
            {renderClusterLink(context)}
          </Text>
        );
      case 'export_job.initiate':
        return (
          <Text pale>
            {renderActor(actor)} initiated a data export from{' '}
            {renderClusterLink(context)}
          </Text>
        );
      case 'export_job.succeeded':
        return (
          <Text pale>
            Data export into {renderClusterLink(context)} completed successfully
          </Text>
        );
      case 'export_job.failed':
        return (
          <Text pale>
            Data export into {renderClusterLink(context)} completed with errors
          </Text>
        );
      case 'export_job.delete':
        return (
          <Text pale>
            {renderActor(actor)} cancelled a data export from{' '}
            {renderClusterLink(context)}
          </Text>
        );
      case 'file.create':
        return (
          <Text pale>
            {renderActor(actor)} created file{' '}
            <span className="text-gray-700">{context.file.name}</span>
          </Text>
        );
      case 'file.delete':
        return (
          <Text pale>
            {renderActor(actor)} deleted file{' '}
            <span className="text-gray-700">{context.file.name}</span>
          </Text>
        );
      default:
        sentry.callSentryWithMessage(
          `No translation for audit log action ${action}`,
        );
        return <Text pale>{action}</Text>;
    }
  };

  return (
    <div
      className="flex items-center border-t py-1"
      data-testid={`auditlog_${logEntry.id}`}
    >
      {/* user initial */}
      <div className="px-4 py-3">
        <div className="m-1 flex h-10 w-10 items-center justify-center rounded-full border border-gray-200">
          <div className="text-sm leading-5 tracking-tight text-gray-500">
            {actorInitial}
          </div>
        </div>
      </div>

      {/* log entry details */}
      <div className="grow">
        <div className="mb-1 text-sm text-gray-500">
          <DisplayDate isoDate={logEntry.created} format={DATETIME_FORMAT} />
        </div>
        {renderMessage()}
      </div>
    </div>
  );
}

AuditLogMessage.propTypes = {
  logEntry: auditLogEntryPropType.isRequired,
};

export default AuditLogMessage;
