import { Text } from '@cratedb/crate-gc-admin';

export type NotificationDescriptionMessage =
  | { keyName: string; text: string }[]
  | string;

export type NotificationDescriptionProps = {
  messages: NotificationDescriptionMessage;
};

function NotificationDescription({ messages }: NotificationDescriptionProps) {
  return Array.isArray(messages) ? (
    <div className="mt-2">
      {messages.map(message => (
        <div key={message.keyName} className="mb-4">
          <Text className="font-bold">{`${message.keyName}: `}</Text>
          <Text>{message.text}</Text>
        </div>
      ))}
    </div>
  ) : (
    messages
  );
}

export default NotificationDescription;
