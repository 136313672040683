import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { Popconfirm } from 'antd';
import { CopyOutlined, EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';
import { Button, CopyToClipboard, Heading, Text } from '@cratedb/crate-gc-admin';
import { clusterOverview } from '../../constants/paths';
import { clusterPropType } from '../../models';
import useSessionStore from '../../state/session';

import SectionContainer from '../SectionContainer';

function ClusterCredentials({ cluster, organizationId, password, username }) {
  const unsetClusterPassword = useSessionStore(state => state.unsetClusterPassword);
  const [passwordIsVisible, setPasswordIsVisible] = useState(false);

  const downloadContent = () => {
    return encodeURIComponent(
      [
        `Host:              ${cluster.fqdn.slice(0, -1)}`,
        `Port (PostgreSQL): 5432`,
        `Port (HTTPS):      4200`,
        `Database:          crate`,
        `Username:          ${username}`,
        `Password:          ${password}`,
      ].join('\n'),
    );
  };

  return (
    <SectionContainer
      className="border-4 border-orange-600"
      testId="cluster-credentials"
    >
      <div className="flex items-start justify-between gap-4">
        <p>
          <FormattedMessage id="clusterCredentials.intro1" />
          <Link
            to={{
              pathname: clusterOverview.build({
                clusterId: cluster.id,
                projectId: cluster.project_id,
                organizationId,
              }),
            }}
          >
            <FormattedMessage id="clusterCredentials.intro2" />
          </Link>{' '}
          <FormattedMessage id="clusterCredentials.intro3" />
        </p>
        <div className="flex justify-between gap-2">
          <a
            href={`data:text/plain;charset=utf-8,${downloadContent()}`}
            download={`${cluster.name}.txt`}
          >
            <Button>
              <FormattedMessage id="common.download" />
            </Button>
          </a>
          <Popconfirm
            title={
              <FormattedMessage id="clusterCredentials.haveYouCopiedYourPassword" />
            }
            okText={<FormattedMessage id="common.yes" />}
            onConfirm={() => unsetClusterPassword(cluster.id)}
          >
            <Button type={Button.types.BUTTON}>
              <FormattedMessage id="common.close" />
            </Button>
          </Popconfirm>
        </div>
      </div>
      <Heading className="mb-2 mt-6" level={Heading.levels.h4}>
        <FormattedMessage id="common.username" />
      </Heading>
      <div className="my-2.5 flex w-full justify-between rounded-sm bg-neutral-100 p-2.5">
        <Text>{username}</Text>
        <CopyToClipboard textToCopy={username}>
          <div className="mr-2 flex items-center text-crate-blue">
            <CopyOutlined className="mr-1" />
            <Text displayAs={Text.elements.span}>
              <FormattedMessage id="common.copy" />
            </Text>
          </div>
        </CopyToClipboard>
      </div>
      <Heading className="mb-2 mt-6" level={Heading.levels.h4}>
        <FormattedMessage id="common.password" />
      </Heading>
      <div className="my-2.5 flex w-full justify-between rounded-sm bg-neutral-100 p-2.5">
        <Text>{passwordIsVisible ? password : '•'.repeat(password.length)}</Text>
        <div className="flex">
          <button
            className="mr-4 flex items-center text-crate-blue"
            onClick={() => setPasswordIsVisible(!passwordIsVisible)}
            type="button"
          >
            {passwordIsVisible ? (
              <EyeInvisibleOutlined className="mr-1" />
            ) : (
              <EyeOutlined className="mr-1" />
            )}
            <Text displayAs={Text.elements.span}>
              <FormattedMessage
                id={passwordIsVisible ? 'common.hide' : 'common.show'}
              />
            </Text>
          </button>
          <CopyToClipboard textToCopy={password}>
            <div className="mr-2 flex items-center text-crate-blue">
              <CopyOutlined className="mr-1" />
              <Text displayAs={Text.elements.span}>
                <FormattedMessage id="common.copy" />
              </Text>
            </div>
          </CopyToClipboard>
        </div>
      </div>
    </SectionContainer>
  );
}

ClusterCredentials.propTypes = {
  cluster: clusterPropType.isRequired,
  organizationId: PropTypes.string.isRequired,
  password: PropTypes.string.isRequired,
  username: PropTypes.string.isRequired,
};

export default ClusterCredentials;
