import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button, Card, Heading } from '@cratedb/crate-gc-admin';
import { Modal } from 'antd';
import ConstrainWidth from '../../../components/ConstrainWidth';
import SectionContainer from '../../../components/SectionContainer';
import { INTEGRATION_STATUS, INTEGRATION_TYPE } from './constants';
import useIntegrations from './hooks/useIntegrations';
import HubspotFormGenericRequest from '../../../components/HubspotForms/HubspotFormGenericRequest';

function ClusterIntegrations() {
  const { formatMessage } = useIntl();
  const integrations = useIntegrations();
  const { organizationId } = useParams();
  const [showRequestIntegrationModal, setShowRequestIntegrationModal] =
    useState(false);
  const [showContactUsModal, setShowContactUsModal] = useState(null);

  const managedIntegrations = integrations.filter(
    integration => integration.type === INTEGRATION_TYPE.MANAGED,
  );
  const regularIntegrations = integrations.filter(
    integration => integration.type === INTEGRATION_TYPE.REGULAR,
  );

  const drawIntegrations = integrationList => {
    return (
      <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3">
        {integrationList.map(integration => {
          return (
            <Card
              key={integration.name}
              testId={integration.name}
              className="flex flex-col border p-4"
              title={
                <div className="mb-2 flex items-center">
                  <img src={integration.logo} alt="logo" className="mr-2 h-6 w-6" />
                  <div className="text-lg font-semibold">
                    {integration.name}{' '}
                    {integration.status === INTEGRATION_STATUS.DEVELOPMENT &&
                      integration.type === INTEGRATION_TYPE.MANAGED && (
                        <>
                          (<FormattedMessage id="clusterIntegrations.preview" />)
                        </>
                      )}
                  </div>
                </div>
              }
            >
              <div className="flex grow flex-col">
                {/* Description */}
                <div className="mb-4 text-sm">{integration.description}</div>

                {/* Status */}
                {integration.status === INTEGRATION_STATUS.DEVELOPMENT &&
                  integration.type === INTEGRATION_TYPE.MANAGED && (
                    <div className="mt-2">
                      <Button
                        kind={Button.kinds.TERTIARY}
                        size={Button.sizes.SMALL}
                        onClick={() => {
                          setShowContactUsModal(integration.name);
                        }}
                      >
                        <FormattedMessage id="clusterIntegrations.contactUs" />
                      </Button>
                    </div>
                  )}
              </div>

              {/* Action */}
              {integration.action && <div>{integration.action}</div>}
            </Card>
          );
        })}
      </div>
    );
  };

  const handleCloseRequestIntegrationModal = () => {
    setShowRequestIntegrationModal(false);
  };

  const handleCloseContactUsModal = () => {
    setShowContactUsModal(null);
  };

  return (
    <ConstrainWidth>
      <SectionContainer>
        <div className="mb-4 flex items-center justify-between">
          <Heading level={Heading.levels.h2}>
            <FormattedMessage id="clusterIntegrations.integrations" />
          </Heading>
          <Button onClick={() => setShowRequestIntegrationModal(true)} type="button">
            <FormattedMessage id="clusterIntegrations.requestIntegration" />
          </Button>
        </div>

        <div data-testid="real-time-integrations">
          <Heading className="mb-4" level={Heading.levels.h3}>
            <FormattedMessage id="clusterIntegrations.realTime" />
          </Heading>
          {drawIntegrations(managedIntegrations)}
        </div>

        <div data-testid="regular-integrations">
          <Heading className="mb-4 mt-8" level={Heading.levels.h3}>
            <FormattedMessage id="clusterIntegrations.regular" />
          </Heading>
          {drawIntegrations(regularIntegrations)}
        </div>
      </SectionContainer>

      {/* request integration modal */}
      <Modal
        footer={null}
        onCancel={handleCloseRequestIntegrationModal}
        open={showRequestIntegrationModal}
        title={
          <FormattedMessage id="clusterIntegrations.hubspotRequestIntegration.requestIntegration" />
        }
      >
        <HubspotFormGenericRequest
          testId="hubspot-request-integration-form"
          onFormComplete={handleCloseRequestIntegrationModal}
          onFormCancel={handleCloseRequestIntegrationModal}
          hiddenFields={{
            cluster_configuration: `organization: ${organizationId}`,
          }}
          requestType={formatMessage({
            id: 'clusterIntegrations.hubspotRequestIntegration.requestIntegration',
          })}
        />
      </Modal>

      {/* contact us modal */}
      <Modal
        footer={null}
        onCancel={handleCloseContactUsModal}
        open={showContactUsModal !== null}
        title={
          <FormattedMessage
            id="clusterIntegrations.hubspotContactUs.contactUs"
            values={{
              integrationName: `${showContactUsModal} (${formatMessage({
                id: 'clusterIntegrations.preview',
              })})`,
            }}
          />
        }
      >
        <HubspotFormGenericRequest
          testId="hubspot-contact-us-form"
          onFormComplete={handleCloseContactUsModal}
          onFormCancel={handleCloseContactUsModal}
          hiddenFields={{
            cluster_configuration: `organization: ${organizationId}`,
          }}
          requestType={formatMessage(
            {
              id: 'clusterIntegrations.hubspotContactUs.contactUs',
            },
            {
              integrationName: `${showContactUsModal} (${formatMessage({
                id: 'clusterIntegrations.preview',
              })})`,
            },
          )}
        />
      </Modal>
    </ConstrainWidth>
  );
}

export default ClusterIntegrations;
