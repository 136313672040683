import { Text } from '@cratedb/crate-gc-admin';
import { COMPRESSION_OPTIONS, FORMAT_OPTIONS } from '../../constants';
import { CreateImportJobFileBody } from './form';
import { useIntl } from 'react-intl';
import { File as CloudFile } from 'src/types';

export type SourceDetailsStepSummaryProps = {
  attachedFile: CloudFile | File | null;
  values: CreateImportJobFileBody;
};

function SourceDetailsStepSummary({
  attachedFile,
  values,
}: SourceDetailsStepSummaryProps) {
  const { formatMessage } = useIntl();
  const fileName = attachedFile!.name!;
  const format = FORMAT_OPTIONS.find(
    option => option.value === values.format,
  )!.label;
  const compression = COMPRESSION_OPTIONS.find(
    option => option.value === values.compression,
  )!.label;

  const renderValue = (label: string, value: string) => {
    return (
      <div className="flex gap-2">
        <Text className="font-bold">{label}:</Text>
        <Text>{value}</Text>
      </div>
    );
  };

  return (
    <div>
      {renderValue(
        formatMessage({
          id: 'cluster.clusterImportFile.sourceStep.fileName',
        }),
        fileName,
      )}
      {renderValue(
        formatMessage({
          id: 'cluster.clusterImportFile.sourceStep.format',
        }),
        format,
      )}
      {renderValue(
        formatMessage({
          id: 'cluster.clusterImportFile.sourceStep.compression',
        }),
        compression,
      )}
    </div>
  );
}

export default SourceDetailsStepSummary;
