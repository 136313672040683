import { identity, isEmpty } from 'lodash/fp';
import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { Heading } from '@cratedb/crate-gc-admin';
import { locationPathname, locationSearch } from 'src/utils';
import TextDivider from '../../../components/TextDivider';
import LoginWithAzureButton from '../../../components/LoginButtons/LoginWithAzureButton';
import LoginWithCognitoButton from '../../../components/LoginButtons/LoginWithCognitoButton';
import LoginWithGithubButton from '../../../components/LoginButtons/LoginWithGithubButton';
import LoginWithGoogleButton from '../../../components/LoginButtons/LoginWithGoogleButton';
import logo from '../../../assets/login_logo.svg';
import { useAnalytics } from '../../../hooks';
import { getCookie } from '../../../utils/auth';
import { LOGIN_OPTIONS_COOKIE } from '../../../constants/defaults';

function Login() {
  const { trackPageView } = useAnalytics();

  useEffect(() => {
    trackPageView({ message: 'Page view' });
  });

  const loginOptionsCookie = getCookie(LOGIN_OPTIONS_COOKIE);
  const supportIdentityProviderLoginOptions = ['github', 'google', 'azuread'];

  // if there is a cookie then filter supported options by cookie content otherwise just return identity
  const loginOptionsFilterPredicate = loginOptionsCookie
    ? loginOption => loginOptionsCookie.split(',').includes(loginOption)
    : identity;

  const filteredLoginOptions = supportIdentityProviderLoginOptions.filter(
    loginOptionsFilterPredicate,
  );

  const multiLoginOptionsAvailable = !isEmpty(filteredLoginOptions);

  let redirect = encodeURIComponent(
    new URL(document.location).searchParams.get('rd'),
  );
  if (redirect === 'null') {
    redirect = locationPathname() + locationSearch();
  }
  const loginOptionsMap = {
    azuread: <LoginWithAzureButton redirectUri={redirect} />,
    cognito: <LoginWithCognitoButton redirectUri={redirect} />,
    github: <LoginWithGithubButton redirectUri={redirect} />,
    google: <LoginWithGoogleButton redirectUri={redirect} />,
  };

  return (
    <div className="flex h-screen flex-col lg:flex-row" data-testid="login-page">
      <div className="flex flex-col items-stretch bg-white lg:basis-1/2 lg:overflow-auto">
        <div className="basis-6 pl-6 pr-10 lg:fixed lg:items-start lg:pl-12">
          <img alt="CrateDB Cloud logo" className="my-8 w-64 xl:w-72" src={logo} />
        </div>
        <div className="hidden grow py-24 lg:block">
          <div className="flex h-full items-center justify-center">
            <div>
              <Heading
                className="pb-2 font-poppins text-4xl lg:text-4xl"
                level={Heading.levels.h1}
              >
                <FormattedMessage id="account.login.strapline" />
              </Heading>
              <Heading
                className="pb-2 font-poppins text-2xl lg:text-2xl"
                level={Heading.levels.h2}
              >
                <FormattedMessage id="account.login.strapSubline" />
              </Heading>
              <ul className="my-5 list-inside list-disc font-inter text-base font-normal text-gray-500 xl:text-lg">
                <li className="my-3">
                  <FormattedMessage id="account.login.bulletPoint1Text" />
                  <div className="pl-7">
                    <ul className="list-inside list-disc text-sm font-light xl:text-base">
                      <li className="my-2">
                        <FormattedMessage id="account.login.bulletPoint1Subpoint1Text" />
                      </li>
                      <li className="my-2">
                        <FormattedMessage id="account.login.bulletPoint1Subpoint2Text" />
                      </li>
                      <li className="my-2">
                        <FormattedMessage id="account.login.bulletPoint1Subpoint3Text" />
                      </li>
                      <li className="my-2">
                        <FormattedMessage id="account.login.bulletPoint1Subpoint4Text" />
                      </li>
                    </ul>
                  </div>
                </li>
                <li className="my-3">
                  <FormattedMessage id="account.login.bulletPoint2Text" />
                </li>
                <li className="my-3">
                  <FormattedMessage id="account.login.bulletPoint3Text" />
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="flex basis-full items-center justify-center overflow-auto bg-sky-400 bg-login-squares bg-cover lg:basis-1/2">
        <div className="mx-8 max-w-sm rounded bg-white drop-shadow-xl">
          <Heading className="border-b p-4 font-inter" level={Heading.levels.h2}>
            <FormattedMessage id="account.login.signInTitle" />
          </Heading>

          {/* login options */}
          <div className="p-4 font-inter">
            {multiLoginOptionsAvailable ? (
              <>
                <p className="text-gray-500">
                  <FormattedMessage id="account.login.signInMultiOptionText" />
                </p>
                {filteredLoginOptions.map(loginOption => (
                  <div key={loginOption} className="my-4">
                    {loginOptionsMap[loginOption]}
                  </div>
                ))}

                <TextDivider text="OR" className="my-7 text-gray-500" />
              </>
            ) : (
              <p className="pb-3 text-gray-500">
                <FormattedMessage id="account.login.signInSingleOptionText" />
              </p>
            )}
            <div className="mb-3">{loginOptionsMap.cognito}</div>
          </div>

          {/* terms and conditions */}
          <div className="border-t p-4 font-inter">
            <FormattedMessage id="account.login.disclaimerText" />
            <a
              href="https://cratedb.com/master-hosted-service-agreement/"
              target="blank"
            >
              <FormattedMessage id="account.login.disclaimerTermsOfServicePartial" />
            </a>
            <FormattedMessage id="account.login.disclaimerPartial" />
            <a href="https://cratedb.com/legal/privacy-policy/" target="blank">
              <FormattedMessage id="account.login.disclaimerPrivacyPolicyPartial" />
            </a>
            .
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
