import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { Loader } from '@cratedb/crate-gc-admin';
import ClusterChecklist from './ClusterChecklist';
import ClusterFooter from './ClusterFooter';
import ClusterHeader from './ClusterHeader';
import SectionContainer from '../SectionContainer';
import Sparkline from '../Sparkline';
import {
  useGetClustersId,
  useGetClustersIdMetricsCratecpuseconds,
  useGetClustersIdMetricsCratediskusage,
  useGetClustersIdMetricsCratequerycount,
} from '../../swrHooks';
import { clusterOverview } from '../../constants/paths';
import { clusterPropType, projectPropType, regionPropType } from '../../models';
import {
  getClusterAsyncDeployInProgress,
  getClusterRegion,
  getClusterTotalStorage,
} from '../../utils/data/cluster';

function ClusterSummary({ cluster, organizationId, projects, regions, isListMode }) {
  const region = getClusterRegion(cluster, projects, regions);

  // fetch data for this cluster
  const { data: metricCPU } = useGetClustersIdMetricsCratecpuseconds(cluster.id);
  const { data: metricQPS } = useGetClustersIdMetricsCratequerycount(cluster.id);
  const { data: metricStorage } = useGetClustersIdMetricsCratediskusage(cluster.id);

  // preload the cluster detail into the SWR cache so clicking through
  // to the cluster detail from the list screen is instant. if the cluster
  // is already in the cache, no extra API call will be made
  // eslint-disable-next-line no-unused-vars
  const { data: preloadCluster } = useGetClustersId(cluster.id);

  const cpuWarningColorThreshold = 75;
  const storageWarningColorThreshold = 85;

  const getClusterTitle = () => (
    <div className="flex items-center gap-2">
      {isListMode ? (
        <Link
          className="text-[#000000d9]"
          to={{
            pathname: clusterOverview.build({
              clusterId: cluster.id,
              projectId: cluster.project_id,
              organizationId,
            }),
          }}
        >
          {cluster.name}
        </Link>
      ) : (
        cluster.name
      )}
    </div>
  );

  const normalizePercentage = value => Math.max(0, Math.min(100, value));

  const cpuUsageToPercentage = (value, numCores) => {
    return normalizePercentage(Math.round((value / numCores) * 100));
  };

  const getCPUMetricValue = numCores => {
    if (
      cluster &&
      metricCPU?.data?.result?.length > 0 &&
      metricCPU.data.result[0].values?.length > 0
    ) {
      const cpuUsed =
        metricCPU.data.result[0].values[
          metricCPU.data.result[0].values.length - 1
        ][1];
      return cpuUsageToPercentage(cpuUsed, numCores);
    }

    return null;
  };

  const getQPSMetricValue = () => {
    if (
      cluster &&
      metricQPS?.data?.result?.length > 0 &&
      metricQPS.data.result[0].values?.length > 0
    ) {
      const queriesPerMinute = metricQPS.data.result[0].values.slice(-1)[0][1];
      const queriesPerSecond = queriesPerMinute / 60;

      // if qps less than 10, return number to one decimal place
      if (queriesPerSecond < 10) {
        const segments = queriesPerSecond.toFixed(1).split('.');
        return (
          <>
            {segments[0]}
            <small className="text-4xl">.{segments[1]}</small>
          </>
        );
      }

      return Math.round(queriesPerSecond);
    }

    return null;
  };

  const getStorageMetricValue = () => {
    if (
      cluster &&
      metricStorage?.data?.result?.length > 0 &&
      metricStorage.data.result[0].values?.length > 0
    ) {
      const diskUsed =
        metricStorage.data.result[0].values[
          metricStorage.data.result[0].values.length - 1
        ][1];

      return normalizePercentage(
        Math.round((diskUsed / getClusterTotalStorage(cluster)) * 100),
      );
    }

    return null;
  };

  const renderMetric = (
    label,
    value,
    uom,
    suffixText,
    sparkline,
    sparklineMin,
    sparklineMax,
    warningColor,
    testId,
  ) => {
    return (
      <div
        className="relative aspect-square w-44 min-w-[110px] max-w-44 overflow-hidden rounded bg-crate-body-background"
        data-testid={testId}
      >
        {sparkline && (
          <Sparkline
            data={sparkline}
            dataMin={sparklineMin}
            dataMax={sparklineMax}
            minLength={60}
            color={warningColor ? 'high' : 'normal'}
          />
        )}

        <div className="absolute top-0">
          <div
            className={`mx-2 mt-2 text-xs font-bold ${
              warningColor ? 'text-crate-border-dark' : 'text-crate-border-mid'
            }`}
          >
            {label}
          </div>
          <div className="mx-2 mt-1 flex flex-row items-start">
            <span className="text-5xl font-bold tracking-tighter text-crate-border-dark">
              {value !== null && value !== undefined ? (
                <span>{value}</span>
              ) : (
                <span>-</span>
              )}
            </span>
            <span className="text-xl font-bold tracking-tighter text-crate-border-dark">
              {value !== null && value !== undefined ? <span>{uom}</span> : null}
            </span>
          </div>
          {suffixText && (
            <div className="mx-2 text-xs text-crate-border-dark">{suffixText}</div>
          )}
        </div>
      </div>
    );
  };

  const renderCPUMetric = () => {
    if (cluster.suspended) {
      return renderMetric(
        <FormattedMessage id="cluster.clusterOverview.cpuUtilization" />,
        null,
        null,
        <FormattedMessage id="cluster.clusterOverview.averagePerNode" />,
        null,
        null,
        null,
        false,
        'cpu-metric',
      );
    }

    const cpuPercent = getCPUMetricValue(cluster.hardware_specs.cpus_per_node);

    return renderMetric(
      <FormattedMessage id="cluster.clusterOverview.cpuUtilization" />,
      cpuPercent,
      '%',
      <FormattedMessage id="cluster.clusterOverview.averagePerNode" />,
      metricCPU?.data?.result?.length > 0
        ? metricCPU.data.result[0].values
            .map(value =>
              Math.min(
                cpuUsageToPercentage(
                  parseFloat(value[1]),
                  cluster.hardware_specs.cpus_per_node,
                ),
                100,
              ),
            )
            .slice(-60)
        : [],
      0,
      100,
      cpuPercent > cpuWarningColorThreshold,
      'cpu-metric',
    );
  };

  const renderQPSMetric = () => {
    if (cluster.suspended) {
      return renderMetric(
        <FormattedMessage id="cluster.clusterOverview.queriesPerSecond" />,
        null,
        null,
        null,
        null,
        null,
        null,
        false,
        'qps-metric',
      );
    }

    // extract the latest 60 minutes so we can provide a sensible
    // "dataMax" value below.
    const qpsSparklineValues =
      metricQPS?.data?.result?.length > 0
        ? metricQPS?.data.result[0].values
            .map(value => parseFloat(value[1]))
            .slice(-60)
        : [];

    return renderMetric(
      <FormattedMessage id="cluster.clusterOverview.queriesPerSecond" />,
      getQPSMetricValue(),
      null,
      null,
      qpsSparklineValues,
      0,
      Math.max(Math.max(...qpsSparklineValues), 6000), // 6000 queries per min = 100 queries per second
      false,
      'qps-metric',
    );
  };

  const renderStorageMetric = () => {
    if (cluster.suspended) {
      return renderMetric(
        <FormattedMessage id="common.storage" />,
        null,
        null,
        null,
        null,
        null,
        null,
        false,
        'storage-metric',
      );
    }

    const storagePercent = getStorageMetricValue();

    return renderMetric(
      <FormattedMessage id="common.storage" />,
      storagePercent,
      '%',
      null,
      metricStorage?.data?.result?.length > 0
        ? metricStorage.data.result[0].values
            .map(value => parseFloat(value[1]))
            .slice(-60)
        : [],
      0,
      getClusterTotalStorage(cluster),
      storagePercent > storageWarningColorThreshold,
      'storage-metric',
    );
  };

  return (
    <SectionContainer key={cluster.id} testId={cluster.id}>
      {region && (
        <ClusterHeader cluster={cluster} region={region} title={getClusterTitle()} />
      )}
      {getClusterAsyncDeployInProgress(cluster) ? (
        <Loader color={Loader.colors.PRIMARY} className="py-4" />
      ) : (
        <div className="flex flex-row flex-wrap items-start gap-4">
          {renderCPUMetric()}
          {renderStorageMetric()}
          {renderQPSMetric()}
          <ClusterChecklist organizationId={organizationId} clusterId={cluster.id} />
        </div>
      )}
      <ClusterFooter
        cluster={cluster}
        organizationId={organizationId}
        showViewClusterButton={isListMode}
      />
    </SectionContainer>
  );
}

ClusterSummary.propTypes = {
  cluster: clusterPropType.isRequired,
  isListMode: PropTypes.bool.isRequired,
  organizationId: PropTypes.string.isRequired,
  projects: PropTypes.arrayOf(projectPropType).isRequired,
  regions: PropTypes.arrayOf(regionPropType).isRequired,
};

export default ClusterSummary;
