import { ValueOf } from 'src/types';

export const STEVEDORE_FILE_FORMATS = {
  CSV: 'csv',
  GZIP: 'gzip',
  JSON: 'json',
  PARQUET: 'parquet',
};
export type StevedoreFileFormat = ValueOf<typeof STEVEDORE_FILE_FORMATS>;

export const FIELD_NAMES = {
  COMPRESSION_OPTIONS: 'compression',
  DESTINATION_NAMESPACE: 'destination',
  FORMAT_OPTIONS: 'format',
  SOURCE_NAMESPACE: 'source',
  TABLE_OR_VIEW_NAME: 'table',
  TABLE_NAME: '',
};

export const FORMAT_OPTIONS = [
  {
    label: 'CSV',
    value: STEVEDORE_FILE_FORMATS.CSV,
  },
  {
    label: 'JSON',
    value: STEVEDORE_FILE_FORMATS.JSON,
  },
  {
    label: 'Parquet',
    value: STEVEDORE_FILE_FORMATS.PARQUET,
  },
];

export const COMPRESSION_OPTIONS = [
  {
    label: 'none',
    value: 'none',
  },
  {
    label: 'Gzip',
    value: STEVEDORE_FILE_FORMATS.GZIP,
  },
];

export const DEFAULT_FIELD_VALUES = {
  [FIELD_NAMES.COMPRESSION_OPTIONS]: STEVEDORE_FILE_FORMATS.GZIP,
  [FIELD_NAMES.FORMAT_OPTIONS]: STEVEDORE_FILE_FORMATS.CSV,
  [FIELD_NAMES.TABLE_NAME]: '',
};
