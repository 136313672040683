import { Form } from 'antd';
import { Button } from '@cratedb/crate-gc-admin';
import { FormattedMessage, useIntl } from 'react-intl';
import SecretSelect from '../../common/SecretSelect/SecretSelect';
import { SECRET_TYPES } from 'src/constants/defaults';
import { useParams } from 'react-router-dom';
import { FIELD_NAMES } from '../../constants';
import { CreateImportJobAzureBody } from './form';
import ConstrainStepWidth from '../../../../../components/StepLayout/ConstrainStepWidth';

export type ConnectionDetailsStepProps = {
  defaultValues: CreateImportJobAzureBody;
  onNext: (result: CreateImportJobAzureBody) => void;
};

function ConnectionDetailsStep({
  defaultValues,
  onNext,
}: ConnectionDetailsStepProps) {
  const { organizationId } = useParams();
  const { formatMessage } = useIntl();
  const [form] = Form.useForm<CreateImportJobAzureBody>();

  const onFormFinish = (values: CreateImportJobAzureBody) => {
    onNext(values);
  };

  return (
    <ConstrainStepWidth>
      <Form<CreateImportJobAzureBody>
        autoComplete="off"
        form={form}
        initialValues={{
          ...defaultValues,
        }}
        layout="vertical"
        name="import-data-from-azure-connection-form"
        id="import-data-from-azure-connection-form"
        aria-label="import-data-from-azure-connection-form"
        onFinish={onFormFinish}
        requiredMark="optional"
      >
        {/* secret */}
        <div className="flex items-center justify-between">
          <Form.Item
            className="w-full"
            label={
              <FormattedMessage id="cluster.clusterImportFile.connectionDetailsStep.secretField" />
            }
            name={[FIELD_NAMES.AZURE_NAMESPACE, FIELD_NAMES.AZURE_SECRET_ID]}
            rules={[
              {
                required: true,
                message: formatMessage({
                  id: 'cluster.clusterImportFile.secretRequiredText',
                }),
              },
            ]}
          >
            <SecretSelect
              type={SECRET_TYPES.AZURE}
              organizationId={organizationId!}
            />
          </Form.Item>
        </div>
        <div className="flex">
          <Button type={Button.types.SUBMIT}>
            <FormattedMessage id="common.next" />
          </Button>
        </div>
      </Form>
    </ConstrainStepWidth>
  );
}

export default ConnectionDetailsStep;
