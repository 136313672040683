import * as SentryBrowser from '@sentry/browser';
import { locationHostname } from 'src/utils';
import { getEnvironment } from '../constants/environment';
import packageInfo from '../../package.json';

const CLOUD_UI_VERSION = packageInfo.version;

export const beforeSend = event => {
  if (!event.exception || !event.exception.values) {
    return event;
  }
  if (event.exception.values[0].mechanism.handled) {
    return null;
  }
  return event;
};

const sentry = {
  sentryEnabled: !!(
    process.env.REACT_APP_SENTRY_DSN && process.env.REACT_APP_SENTRY_DSN !== ''
  ),
  setupSentry: () => {
    if (sentry.sentryEnabled) {
      SentryBrowser.init({
        beforeSend,
        dsn: process.env.REACT_APP_SENTRY_DSN,
        release: CLOUD_UI_VERSION,
        environment: getEnvironment(locationHostname()),
      });
    }
  },
  callSentry: error => {
    if (sentry.sentryEnabled) {
      // Send event to Sentry
      SentryBrowser.captureException(error);
    }
  },
  callSentryWithMessage: msg => {
    if (sentry.sentryEnabled) {
      // Send event to Sentry
      SentryBrowser.captureMessage(msg);
    }
  },
};

export default sentry;
