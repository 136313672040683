import { FormattedMessage, useIntl } from 'react-intl';
import { Form, Input, Select } from 'antd';
import { Button } from '@cratedb/crate-gc-admin';
import { postHubspotForm } from '../../../api';
import { HUBSPOT_FORMS } from '../../../constants/hubspot';
import { useGetUsersMe } from '../../../swrHooks';
import useMessage from '../../../hooks/useMessage';
import INPUT_SANITIZATION from '../../../constants/inputSanitization';

type HubspotFormRequestAddPrivateLinkFields = {
  email?: string;
  name?: string;
  phone?: string;
  provider?: string;
  tell_us?: string;
};

export type HubspotFormRequestAddPrivateLinkProps = {
  hiddenFields?: { cluster_configuration?: string; description?: string };
  initialValues?: HubspotFormRequestAddPrivateLinkFields;
  onFormCancel: () => void;
  onFormComplete: () => void;
};

function HubspotFormRequestAddPrivateLink({
  onFormCancel,
  onFormComplete,
  initialValues,
  hiddenFields,
}: HubspotFormRequestAddPrivateLinkProps) {
  const { data: user } = useGetUsersMe();
  const { formatMessage } = useIntl();
  const { showErrorMessage, showSuccessMessage } = useMessage();

  const onFinish = async (values: HubspotFormRequestAddPrivateLinkFields) => {
    const response = await postHubspotForm(
      HUBSPOT_FORMS.CLOUD_CONSOLE_REQUEST_ADD_PRIVATE_LINK,
      {
        ...values,
        ...hiddenFields,
      },
    );

    if (response.success) {
      showSuccessMessage(formatMessage({ id: 'hubspot.common.messageSuccess' }));
      onFormComplete();
    } else {
      showErrorMessage(formatMessage({ id: 'hubspot.common.messageFail' }));
    }
  };

  if (!user) {
    return null;
  }

  return (
    <Form
      name="hubspot-request-private-link-form"
      aria-label="hubspot-request-private-link-form"
      data-testid="hubspot-request-private-link-form"
      onFinish={onFinish}
      layout="vertical"
      autoComplete="off"
      labelWrap
      initialValues={{ email: user.email, name: user.name, ...initialValues }}
    >
      <p className="pb-4">
        <FormattedMessage id="cluster.clusterManage.privateLinksModalTitle" />
      </p>

      {/* name */}
      <Form.Item
        label={<FormattedMessage id="hubspot.common.name" />}
        name="name"
        rules={[INPUT_SANITIZATION.HUBSPOT_NAME]}
      >
        <Input />
      </Form.Item>

      {/* email */}
      <Form.Item
        label={<FormattedMessage id="common.email" />}
        name="email"
        rules={[INPUT_SANITIZATION.GENERIC_EMAIL]}
      >
        <Input />
      </Form.Item>

      {/* phone */}
      <Form.Item
        label={<FormattedMessage id="hubspot.common.phone" />}
        name="phone"
        rules={[INPUT_SANITIZATION.PHONE_OPTIONAL]}
      >
        <Input
          placeholder={formatMessage({
            id: 'hubspot.common.phoneIncludeCountryCode',
          })}
        />
      </Form.Item>

      {/* provider */}
      <Form.Item
        label={
          <FormattedMessage id="cluster.clusterManage.privateLinksModalProviderLabel" />
        }
        name="0-2/provider"
        rules={[
          {
            required: true,
            message: formatMessage({
              id: 'cluster.clusterManage.privateLinksModalProviderRequiredText',
            }),
          },
        ]}
      >
        <Select
          options={[
            {
              label: (
                <FormattedMessage id="cluster.clusterManage.privateLinksModalProviderAWSOption" />
              ),
              value: 'aws',
            },
            {
              label: (
                <FormattedMessage id="cluster.clusterManage.privateLinksModalProviderAzureOption" />
              ),
              value: 'azure',
            },
          ]}
        />
      </Form.Item>

      {/* message */}
      <Form.Item
        label={
          <FormattedMessage id="cluster.clusterManage.privateLinksModalMessageLabel" />
        }
        name="tell_us"
        rules={[INPUT_SANITIZATION.HUBSPOT_MESSAGE]}
      >
        <Input.TextArea autoSize={{ minRows: 4, maxRows: 8 }} />
      </Form.Item>

      <div className="flex justify-end">
        <Button
          type={Button.types.BUTTON}
          onClick={onFormCancel}
          kind={Button.kinds.SECONDARY}
          size={Button.sizes.SMALL}
        >
          <FormattedMessage id="common.cancel" />
        </Button>
        <Button
          type={Button.types.SUBMIT}
          size={Button.sizes.SMALL}
          className="ml-2"
        >
          <FormattedMessage id="common.ok" />
        </Button>
      </div>
    </Form>
  );
}

export default HubspotFormRequestAddPrivateLink;
