import { Text } from '@cratedb/crate-gc-admin';
import { FIELD_NAMES } from '../../constants';
import { useIntl } from 'react-intl';
import { CreateIntegrationMongoDBBody } from './forms';

export type CollectionDetailsStepSummaryProps = {
  values: CreateIntegrationMongoDBBody;
};

function CollectionDetailsStepSummary({
  values,
}: CollectionDetailsStepSummaryProps) {
  const { formatMessage } = useIntl();
  const dbName =
    values[FIELD_NAMES.MONGODB_NAMESPACE][FIELD_NAMES.MONGODB_DATABASE_NAME];
  const collectionName =
    values[FIELD_NAMES.MONGODB_NAMESPACE][FIELD_NAMES.MONGODB_COLLECTION_NAME];

  const renderValue = (label: string, value: string) => {
    return (
      <div className="flex gap-2">
        <Text className="font-bold">{label}:</Text>
        <Text>{value}</Text>
      </div>
    );
  };

  return (
    <div>
      {renderValue(
        formatMessage({
          id: 'cluster.clusterImportIntegration.collectionDetailsStep.databaseLabel',
        }),
        dbName,
      )}
      {renderValue(
        formatMessage({
          id: 'cluster.clusterImportIntegration.collectionDetailsStep.collectionLabel',
        }),
        collectionName,
      )}
    </div>
  );
}

export default CollectionDetailsStepSummary;
