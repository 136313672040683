import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Modal } from 'antd';
import { Button, Text } from '@cratedb/crate-gc-admin';
import { useGetUsersMe } from '../../../swrHooks';
import HubspotFormRequestDeleteAccount from '../../../components/HubspotForms/HubspotFormRequestDeleteAccount';
import SectionContainer from '../../../components/SectionContainer';

function DeleteAccount() {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { data: user } = useGetUsersMe();

  return (
    <>
      <SectionContainer
        title={<FormattedMessage id="account.deleteAccount.deleteAccountTitle" />}
        description={
          <Text>
            <FormattedMessage id="account.deleteAccount.deleteAccountText" />
          </Text>
        }
        actions={
          <Button
            kind={Button.kinds.TERTIARY}
            onClick={() => setIsModalVisible(true)}
            warn
          >
            <FormattedMessage id="account.deleteAccount.submitRequestButton" />
          </Button>
        }
      />
      <Modal
        closable={false}
        footer={null}
        onCancel={() => setIsModalVisible(false)}
        open={isModalVisible}
      >
        <HubspotFormRequestDeleteAccount
          onFormComplete={() => setIsModalVisible(false)}
          onFormCancel={() => setIsModalVisible(false)}
          hiddenFields={{
            cluster_configuration: `user: ${user?.uid}`,
          }}
        />
      </Modal>
    </>
  );
}

export default DeleteAccount;
