import React, { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import { FormattedMessage, useIntl } from 'react-intl';
import { DatePicker, Select } from 'antd';
import { Button, Loader } from '@cratedb/crate-gc-admin';
import ConstrainWidth from '../../../components/ConstrainWidth/ConstrainWidth';
import AuditLogMessage from './AuditLogMessage';
import ViewContainer from '../../../components/ViewContainer';
import {
  useGetOrganizationsIdClusters,
  useGetOrganizationsIdAuditlogs,
} from '../../../swrHooks';
import { AUDIT_LOGS_PAGINATION_LIMIT } from '../../../constants/defaults';
import {
  clusterActions,
  orgActions,
  projectActions,
  creditActions,
  subscriptionsActions,
} from '../../../constants/logActions';
import SectionContainer from '../../../components/SectionContainer';

const { RangePicker } = DatePicker;

function AuditLogs() {
  const { organizationId } = useParams();
  const { formatMessage } = useIntl();
  const [filters, setFilters] = useState({});
  const { data: clusters } = useGetOrganizationsIdClusters(organizationId);
  const {
    data: auditLogs,
    isValidating: isLoading,
    size: auditLogsSize,
    setSize: setAuditLogsSize,
  } = useGetOrganizationsIdAuditlogs(organizationId, filters);

  const filterOption = (input, option) =>
    (option?.label?.toLowerCase() ?? '').includes(input.toLowerCase());

  const actionOptions = useMemo(
    () => [
      {
        value: 'all',
        label: formatMessage({ id: 'organization.auditLogs.allActionsOption' }),
      },
      {
        label: formatMessage({ id: 'organization.auditLogs.organizationOption' }),
        options: orgActions.map(action => {
          return { label: formatMessage({ id: action.label }), value: action.value };
        }),
      },
      {
        label: formatMessage({ id: 'organization.auditLogs.projectOption' }),
        options: projectActions.map(action => {
          return { label: formatMessage({ id: action.label }), value: action.value };
        }),
      },
      {
        label: formatMessage({ id: 'organization.auditLogs.clusterOption' }),
        options: clusterActions.map(action => {
          return { label: formatMessage({ id: action.label }), value: action.value };
        }),
      },
      {
        label: formatMessage({ id: 'organization.auditLogs.creditsOption' }),
        options: creditActions.map(action => {
          return { label: formatMessage({ id: action.label }), value: action.value };
        }),
      },
      {
        label: formatMessage({ id: 'organization.auditLogs.subscriptionsOption' }),
        options: subscriptionsActions.map(action => {
          return { label: formatMessage({ id: action.label }), value: action.value };
        }),
      },
    ],
    [formatMessage],
  );

  const clusterOptions = useMemo(() => {
    const options = [
      {
        value: 'all',
        label: formatMessage({ id: 'organization.auditLogs.allClustersOption' }),
      },
    ];

    if (clusters && clusters.length > 0) {
      options.push({
        label: formatMessage({ id: 'organization.auditLogs.clusterOption' }),
        options: clusters.map(cluster => {
          return { label: cluster.name, value: cluster.id };
        }),
      });
    }

    return options;
  }, [clusters, formatMessage]);

  const filterByAction = action => {
    setFilters({ ...filters, action: action === 'all' ? null : action });
  };

  const filterByCluster = clusterId => {
    setFilters({
      ...filters,
      cluster_id: clusterId === 'all' ? null : clusterId,
    });
  };

  const filterByDateRange = dates => {
    setFilters({
      ...filters,
      from: dates ? dates[0].format('YYYY-MM-DDT00:00:00.000Z') : null,
      to: dates ? dates[1].format('YYYY-MM-DDT23:59:59.999Z') : null,
    });
  };

  const renderFilters = () => (
    <div className="mb-4 grid gap-4 md:grid-cols-2 xl:grid-cols-4">
      <Select
        className="h-8"
        data-testid="action-filter"
        defaultValue="all"
        filterOption={filterOption}
        onChange={filterByAction}
        options={actionOptions}
        placeholder={
          <FormattedMessage id="organization.auditLogs.selectActionLabel" />
        }
        showSearch
      />
      <Select
        className="h-8"
        data-testid="cluster-filter"
        defaultValue="all"
        filterOption={filterOption}
        onChange={filterByCluster}
        options={clusterOptions}
        placeholder={
          <FormattedMessage id="organization.auditLogs.selectClusterLabel" />
        }
        showSearch
      />
      <div className="h-8 md:col-span-2" data-testid="date-filter">
        <RangePicker
          disabledDate={current => current && current > moment()}
          onChange={filterByDateRange}
          picker="date"
          style={{ width: '100%' }}
        />
      </div>
    </div>
  );

  const logsEntriesFound = auditLogs && auditLogs[0].length > 0;
  const moreDataAvailable =
    auditLogs &&
    auditLogs[auditLogs.length - 1].length === AUDIT_LOGS_PAGINATION_LIMIT;

  return (
    <ConstrainWidth>
      <ViewContainer
        heading={<FormattedMessage id="organization.auditLogs.pageTitle" />}
        render={() => (
          <SectionContainer>
            {renderFilters()}
            {logsEntriesFound && (
              <div className="border-b pt-4">
                {auditLogs.map(page =>
                  page.map(logEntry => (
                    <AuditLogMessage
                      logEntry={logEntry}
                      key={`log_${logEntry.id}`}
                    />
                  )),
                )}
              </div>
            )}
            {isLoading && !logsEntriesFound && (
              <div className="py-6 text-center">
                <Loader color={Loader.colors.PRIMARY} />
              </div>
            )}
            {!isLoading && !logsEntriesFound && (
              <div className="py-12 text-center text-crate-gray30">
                <FormattedMessage id="organization.auditLogs.noData" />
              </div>
            )}
            {moreDataAvailable && (
              <div className="py-4 text-center" data-testid="load-more-button">
                <Button
                  disabled={isLoading}
                  kind={Button.kinds.PRIMARY}
                  loading={isLoading}
                  onClick={() => setAuditLogsSize(auditLogsSize + 1)}
                >
                  {isLoading ? (
                    <FormattedMessage id="organization.auditLogs.loadingMoreButton" />
                  ) : (
                    <FormattedMessage id="organization.auditLogs.loadMoreButton" />
                  )}
                </Button>
              </div>
            )}
          </SectionContainer>
        )}
      />
    </ConstrainWidth>
  );
}

export default AuditLogs;
