import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Form, Select } from 'antd';
import { FormattedMessage } from 'react-intl';
import { Button } from '@cratedb/crate-gc-admin';
import ConstrainWidth from '../../components/ConstrainWidth/ConstrainWidth';
import ViewContainer from '../../components/ViewContainer';
import { CRFREE_USAGE_REASONS } from '../../constants/deploy';

function UsageQuestionnaire({ submitHandler }) {
  const [usageReason, setUsageReason] = useState(null);

  const handleFormFinish = () => {
    if (usageReason) {
      submitHandler({ usageReason });
    }
  };

  return (
    <ConstrainWidth>
      <ViewContainer
        heading={<FormattedMessage id="deploy.questionnaire.tellUseCase" />}
        render={() => (
          <Form onFinish={handleFormFinish}>
            <div className="mx-auto flex w-full max-w-[400px] flex-col items-end">
              <p className="w-full pb-1">
                <FormattedMessage id="deploy.questionnaire.usageReason" />
              </p>
              <Form.Item
                className="mb-2 w-full"
                name="usageReason"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Select
                  options={Object.entries(CRFREE_USAGE_REASONS).map(
                    ([key, value]) => {
                      return { label: value, value: key };
                    },
                  )}
                  onChange={value => {
                    setUsageReason(value);
                  }}
                  placeholder="Select an option"
                />
              </Form.Item>
              <Form.Item>
                <Button
                  kind={Button.kinds.PRIMARY}
                  type={Button.types.SUBMIT}
                  size={Button.sizes.MEDIUM}
                  disabled={!usageReason}
                >
                  <FormattedMessage id="deploy.deployOverview.deployClusterButton" />
                </Button>
              </Form.Item>
            </div>
          </Form>
        )}
      />
    </ConstrainWidth>
  );
}

UsageQuestionnaire.propTypes = {
  submitHandler: PropTypes.func.isRequired,
};

export default UsageQuestionnaire;
