import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate, useParams } from 'react-router-dom';
import { message, Modal } from 'antd';
import {
  AVAILABLE_LIGHT_COLORS,
  Button,
  COLOR_STYLES_MAP,
  ConfirmDelete,
  Heading,
  SolidLight,
} from '@cratedb/crate-gc-admin';
import CloudUISyntaxHighlighter from '../../../components/CloudUISyntaxHighlighter';
import RegionDescription from '../../../components/RegionDescription';
import SectionContainer from '../../../components/SectionContainer';
import { useAnalytics } from '../../../hooks';
import { regionPropType } from '../../../models';
import { getDomain } from '../../../constants/cookieOptions';
import { getUserIsOrganizationAdmin } from '../../../utils/data/user';
import {
  useGetOrganizationsIdRegions,
  useGetRegionsNameInstalltoken,
  useGetUsersMe,
} from '../../../swrHooks';
import {
  QUERY_PARAM_KEY_REGION,
  REGION_STATES,
  SINGLE_SCREEN_DEPLOYMENT_ANALYTICS_ORIGIN,
} from '../../../constants/defaults';
import { USER_TRACKING_EVENTS } from '../../../constants/segment';
import useMessage from '../../../hooks/useMessage';
import { apiDelete } from '../../../api';

const installActions = {
  install: {
    btnText: 'organization.regions.installEdgeRegionButton',
    modalTitle: 'organization.regions.installRegionCommandTitle',
  },
  upgrade: {
    btnText: 'organization.regions.upgradeEdgeRegionButton',
    modalTitle: 'organization.regions.upgradeRegionCommandTitle',
  },
};

const statusColors = {
  DOWN: COLOR_STYLES_MAP[AVAILABLE_LIGHT_COLORS.RED],
  UNKNOWN: COLOR_STYLES_MAP[AVAILABLE_LIGHT_COLORS.GRAY],
  UP: COLOR_STYLES_MAP[AVAILABLE_LIGHT_COLORS.GREEN],
};

const statusLabels = {
  DOWN: 'organization.regions.downStatusText',
  UNKNOWN: 'organization.regions.unknownStatusText',
  UP: 'organization.regions.upStatusText',
};

function EdgeRegion({ region }) {
  const navigate = useNavigate();
  const { formatMessage } = useIntl();
  const { organizationId } = useParams();
  const { showLoadingMessage, showSuccessMessage } = useMessage();
  const [showDeleteRegionModal, setShowDeleteRegionModal] = useState(false);
  const [showInstallRegionModal, setShowInstallRegionModal] = useState(false);
  const [submitFormInProgress, setSubmitFormInProgress] = useState(false);
  const { trackEvent } = useAnalytics();
  const { data: user } = useGetUsersMe();
  const { mutate: mutateRegions } = useGetOrganizationsIdRegions(organizationId);
  const { data: installToken } = useGetRegionsNameInstalltoken(region.name);
  const userIsOrgAdmin = getUserIsOrganizationAdmin(user, organizationId);

  const getInstallStatus = () => {
    if (installToken) {
      if (region.status === REGION_STATES.UP && region.upgrade_available) {
        return 'upgrade';
      }
      return 'install';
    }

    return null;
  };

  const handleDeleteRegion = async () => {
    if (!submitFormInProgress) {
      setSubmitFormInProgress(true);

      showLoadingMessage(
        formatMessage({
          id: 'organization.regions.deleteRegionStartedText',
        }),
      );

      const { success } = await apiDelete(`/api/v2/regions/${region.name}/`);

      if (success) {
        message.destroy();
        showSuccessMessage(
          formatMessage({
            id: 'organization.regions.deleteRegionSuccessText',
          }),
        );
        mutateRegions();
        setShowDeleteRegionModal(false);
      }

      setSubmitFormInProgress(false);
    }
  };

  return (
    <>
      <SectionContainer key={region.name}>
        <div
          className="grid grid-cols-3 items-center gap-x-4 xl:grid-cols-5"
          data-testid="edge-region"
        >
          <div className="col-span-2">
            <RegionDescription region={region} />
          </div>

          {/* status */}
          <div className="flex items-center justify-end xl:justify-start">
            <SolidLight className={statusColors[region.status]} />
            <div className="pl-1">
              <FormattedMessage id={statusLabels[region.status]} />
            </div>
          </div>

          {/* deploy cluster button */}
          <div className="col-span-3 flex justify-end align-middle xl:col-span-2">
            <Button
              disabled={!userIsOrgAdmin || region.status === REGION_STATES.DOWN}
              kind={Button.kinds.TERTIARY}
              onClick={() => {
                trackEvent(USER_TRACKING_EVENTS.CLICKED_DEPLOY, {
                  origin: 'regions',
                  destination: SINGLE_SCREEN_DEPLOYMENT_ANALYTICS_ORIGIN,
                });

                navigate(
                  `/account/organizations/${organizationId}/deploy?${QUERY_PARAM_KEY_REGION}=${region.name}`,
                );
              }}
            >
              <FormattedMessage id="organization.regions.deployClusterButton" />
            </Button>

            {/* install region button */}
            {getInstallStatus() && (
              <Button
                className="ml-8"
                kind={Button.kinds.TERTIARY}
                onClick={() => setShowInstallRegionModal(region)}
              >
                <FormattedMessage id={installActions[getInstallStatus()].btnText} />
              </Button>
            )}

            {/* delete region button */}
            <Button
              className="ml-8"
              kind={Button.kinds.TERTIARY}
              onClick={() => setShowDeleteRegionModal(true)}
            >
              <FormattedMessage id="organization.regions.deleteEdgeRegionButton" />
            </Button>
          </div>
        </div>
      </SectionContainer>

      {/* delete region modal */}
      <ConfirmDelete
        onCancel={() => setShowDeleteRegionModal(false)}
        visible={showDeleteRegionModal}
        confirmText={region.description}
        onConfirm={handleDeleteRegion}
        title={formatMessage({ id: 'organization.regions.deleteEdgeRegionTitle' })}
        prompt={formatMessage({
          id: 'organization.regions.confirmDeletionButton',
        })}
        disclaimer={formatMessage({
          id: 'organization.regions.deleteEdgeRegionDisclaimerText',
        })}
      >
        <Heading className="mb-2" level={Heading.levels.h3}>
          <FormattedMessage id="organization.regions.deleteRegionInstructionsStep1Title" />
        </Heading>
        <FormattedMessage id="organization.regions.cleanupEdgeHelp" />
        <CloudUISyntaxHighlighter
          language="bash"
          title={<FormattedMessage id="organization.regions.cleanupEdgeTitle" />}
        >
          {`bash <(wget -qO- https://console.${getDomain()}/edge/uninstall-cratedb-cloud-edge.sh)`}
        </CloudUISyntaxHighlighter>
        <Heading className="mb-2 mt-6" level={Heading.levels.h3}>
          <FormattedMessage id="organization.regions.deleteRegionInstructionsStep2Title" />
        </Heading>
      </ConfirmDelete>

      {/* install region modal */}
      <Modal
        closable={false}
        footer={null}
        onCancel={() => setShowInstallRegionModal(false)}
        open={showInstallRegionModal}
        width={900}
      >
        {getInstallStatus() && (
          <>
            <CloudUISyntaxHighlighter
              language="bash"
              title={
                showInstallRegionModal && (
                  <FormattedMessage
                    id={installActions[getInstallStatus()].modalTitle}
                  />
                )
              }
            >
              {`bash <(wget -qO- https://console.${getDomain()}/edge/cratedb-cloud-edge.sh) ${
                installToken.token
              }`}
            </CloudUISyntaxHighlighter>
            <div className="mt-4 text-right">
              <Button
                onClick={() => {
                  setShowInstallRegionModal(false);
                }}
              >
                <FormattedMessage id="common.close" />
              </Button>
            </div>
          </>
        )}
      </Modal>
    </>
  );
}

EdgeRegion.propTypes = {
  region: regionPropType.isRequired,
};

export default EdgeRegion;
