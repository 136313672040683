import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Switch } from 'antd';
import { LockOutlined, WarningOutlined } from '@ant-design/icons';
import { useParams } from 'react-router-dom';
import { Text } from '@cratedb/crate-gc-admin';
import SectionContainer from '../../../components/SectionContainer';
import { useGetClustersId } from '../../../swrHooks';
import { getClusterAsyncInProgress } from '../../../utils/data/cluster';
import { apiPut } from '../../../api';

function DeletionProtection() {
  const { clusterId } = useParams();
  const { data: cluster, mutate: mutateCluster } = useGetClustersId(clusterId);
  const [saveInProgress, setSaveInProgress] = useState(false);
  const asyncInProgress = getClusterAsyncInProgress(cluster);

  const toggleSwitch = async checked => {
    if (checked !== cluster.deletion_protected) {
      setSaveInProgress(true);

      const { success, data } = await apiPut(
        `/api/v2/clusters/${clusterId}/deletion-protection/`,
        {
          deletion_protected: checked,
        },
      );

      if (success) {
        mutateCluster(data);
      }

      setSaveInProgress(false);
    }
  };

  return (
    <SectionContainer
      actions={
        cluster && (
          <Switch
            defaultChecked={cluster?.deletion_protected}
            disabled={
              saveInProgress || asyncInProgress || cluster.product_name === 'crfree'
            }
            onChange={toggleSwitch}
          />
        )
      }
      loading={saveInProgress}
      title={<FormattedMessage id="cluster.clusterManage.deletionProtectionTitle" />}
    >
      <div className="flex items-start">
        {cluster?.deletion_protected && (
          <>
            <LockOutlined
              className="mr-4 mt-1 text-xl text-crate-border-mid"
              data-testid="protection-enabled-icon"
            />
            <Text>
              <FormattedMessage id="cluster.clusterManage.deletionProtectionEnabledText" />
            </Text>
          </>
        )}
        {!cluster?.deletion_protected && (
          <>
            <WarningOutlined
              className="mr-4 mt-1 text-xl text-red-500"
              data-testid="protection-disabled-icon"
            />
            <Text>
              <FormattedMessage id="cluster.clusterManage.deletionProtectionDisabledText" />
            </Text>
          </>
        )}
      </div>
    </SectionContainer>
  );
}

export default DeletionProtection;
