import { useParams } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import { CheckCircleOutlined } from '@ant-design/icons';
import { useGetProductsClustersPrice } from '../../../swrHooks';
import { PRICE_OPTIONS_3DP } from '../../../constants/defaults';
import { Cluster, Product, Region } from 'src/types';

export type ScaleStorageOptionProps = {
  bytes: number;
  cluster: Cluster;
  currentClusterProduct: Product;
  label: string;
  region: Region;
  selectedStorageBytes?: number;
};

function ScaleStorageOption({
  bytes,
  cluster,
  currentClusterProduct,
  label,
  region,
  selectedStorageBytes,
}: ScaleStorageOptionProps) {
  const { formatNumber } = useIntl();
  const { organizationId } = useParams();
  const { data: price } = useGetProductsClustersPrice(
    organizationId,
    region,
    currentClusterProduct,
    0,
    bytes,
  );

  const isCurrentOption = bytes === cluster.hardware_specs!.disk_size_per_node_bytes;
  const isSelectedOption = bytes === selectedStorageBytes;

  return (
    <button
      className="flex w-full items-center justify-between text-left"
      type="button"
    >
      <div className="flex w-[87%] items-center">
        <div className="w-[20%] px-2">{label}</div>
        {price ? (
          <div className="w-[80%] p-1 leading-tight">
            {formatNumber(
              price.storage_price?.price_per_hour || 0,
              PRICE_OPTIONS_3DP,
            )}{' '}
            <span className="whitespace-nowrap text-xs opacity-80">
              <FormattedMessage id="cluster.clusterScale.perNodePerHour" />
            </span>
          </div>
        ) : null}
      </div>
      <div className="flex w-[13%] items-center justify-end px-2">
        {isCurrentOption && (
          <div className="inline-block rounded bg-crate-blue px-1.5 py-1 text-[10px] font-semibold uppercase leading-tight text-white">
            current
          </div>
        )}
        {!isCurrentOption && isSelectedOption && (
          <CheckCircleOutlined
            className="text-lg text-crate-blue"
            data-testid="selected-storage-icon"
          />
        )}
      </div>
    </button>
  );
}

export default ScaleStorageOption;
