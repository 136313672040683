import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { FormattedMessage, useIntl } from 'react-intl';
import { Modal } from 'antd';
import { Button, DisplayDate } from '@cratedb/crate-gc-admin';
import CloudUITable from '../../../../components/CloudUITable';
import { exportJobPropType } from '../../../../models';
import { fromBytes } from '../../../../utils';
import ExportJobsListActions from './ExportJobsListActions';
import StatusIndicator from '../../../../components/StatusIndicator/StatusIndicator';
import { OPERATION_STATES } from '../../../../constants/defaults';
import Tag from '../../../../components/Tag';
import ErrorExportingHelp from '../ErrorExportingHelp';

function ExportJobsList({ exportJobs, onDelete }) {
  const { formatNumber } = useIntl();
  const [exportJobBeingViewed, setexportJobBeingViewed] = useState(null);
  const [showDetailsDialog, setShowDetailsDialog] = useState();
  const [progressMessageVariant, setProgressMessageVariant] = useState(0);

  const quickStatusMessageMap = exportJob => {
    return {
      [OPERATION_STATES.SENT]: (
        <FormattedMessage id="cluster.clusterExport.quickStatusSettingUpExportText" />
      ),
      [OPERATION_STATES.REGISTERED]: (
        <FormattedMessage id="cluster.clusterExport.quickStatusSettingUpExportText" />
      ),
      [OPERATION_STATES.IN_PROGRESS]: (
        <FormattedMessage
          id="cluster.clusterExport.quickStatusExportInProgressRecordsText"
          values={{
            records: formatNumber(exportJob.progress.records, {
              notation: 'compact',
            }),
          }}
        />
      ),
      [OPERATION_STATES.SUCCEEDED]: (
        <FormattedMessage id="cluster.clusterExport.quickStatusExportSucceededText" />
      ),
      [OPERATION_STATES.FAILED]: (
        <FormattedMessage id="cluster.clusterExport.quickStatusExportFailedText" />
      ),
    };
  };

  const combinedData = useMemo(
    () =>
      exportJobs
        // decorate the export jobs with the corresponding
        // file data to allow rendering the view
        .map(exportJob => {
          const correspondingFile = exportJob.destination.file;

          return {
            ...exportJob,
            file_size: correspondingFile?.file_size
              ? fromBytes(correspondingFile.file_size).format()
              : '-',
            download_url: correspondingFile?.download_url,
          };
        }),
    [exportJobs],
  );

  useEffect(() => {
    const messageUpdater = setTimeout(() => {
      setProgressMessageVariant(progressMessageVariant === 0 ? 1 : 0);
    }, 8000);

    return function clearMessageUpdater() {
      clearTimeout(messageUpdater);
    };
  }, [progressMessageVariant]);

  const handleShowDetails = exportJob => {
    setexportJobBeingViewed(exportJob);
    setShowDetailsDialog(true);
  };

  const handleHideDetails = () => setShowDetailsDialog(false);

  const handleDeleteExportJob = exportJob => {
    onDelete(exportJob);
    setShowDetailsDialog(false);
  };

  const columns = [
    {
      responsive: ['lg'],
      render: exportJob => (
        <Tag color={Tag.colors.SECONDARY} label={exportJob.destination.format} />
      ),
      width: '6%',
    },
    {
      dataIndex: ['source', 'table'],
      title: (
        <FormattedMessage id="cluster.clusterExport.relationColumnHeaderText" />
      ),
      width: '15%',
    },
    {
      dataIndex: 'file_size',
      title: (
        <FormattedMessage id="cluster.clusterExport.fileSizeColumnHeaderText" />
      ),
      width: '10%',
    },
    {
      render: exportJob => <DisplayDate isoDate={exportJob.dc.created} />,
      responsive: ['lg'],
      title: (
        <FormattedMessage id="cluster.clusterExport.dateExportedColumnHeaderText" />
      ),
      width: '24%',
    },
    {
      render: exportJob => (
        <StatusIndicator
          message={quickStatusMessageMap(exportJob)[exportJob.status]}
          status={exportJob.status}
        />
      ),
      responsive: ['md'],
      title: <FormattedMessage id="cluster.clusterImport.statusColumnHeaderText" />,
      width: '25%',
    },
    {
      render: exportJob => (
        <ExportJobsListActions
          exportJob={exportJob}
          onDelete={onDelete}
          onShowDetails={handleShowDetails}
        />
      ),
      width: '20%',
    },
  ];

  return (
    <>
      <CloudUITable
        columns={columns}
        dataSource={combinedData}
        pagination={combinedData.length > 10}
        rowKey="id"
        showHeader
      />
      {exportJobBeingViewed && (
        <Modal
          onCancel={handleHideDetails}
          open={showDetailsDialog}
          title={
            <FormattedMessage
              id="cluster.clusterExport.detailsModalTitle"
              values={{
                relation: exportJobBeingViewed.source.table,
                date: <DisplayDate isoDate={exportJobBeingViewed.dc.created} />,
              }}
            />
          }
          footer={
            <>
              <Button
                kind={Button.kinds.SECONDARY}
                className="mr-2"
                onClick={handleHideDetails}
              >
                <FormattedMessage id="common.close" />
              </Button>
              <Button onClick={() => handleDeleteExportJob(exportJobBeingViewed)}>
                <FormattedMessage id="cluster.clusterImport.removeFromHistoryButton" />
              </Button>
            </>
          }
          width={900}
        >
          <div className="mb-6">
            <span>
              <Tag label={exportJobBeingViewed.destination.format} />
            </span>
          </div>
          <div
            className={cx({
              'ml-3.5': exportJobBeingViewed.status === OPERATION_STATES.FAILED,
              'ml-0.5': exportJobBeingViewed.status !== OPERATION_STATES.FAILED,
            })}
          >
            {exportJobBeingViewed.status === OPERATION_STATES.SUCCEEDED && (
              <div className="flex">
                <StatusIndicator
                  message={
                    <FormattedMessage
                      id="cluster.clusterExport.detailStatusExportSucceededText"
                      values={{
                        records: formatNumber(exportJobBeingViewed.progress.records),
                      }}
                    />
                  }
                  status={exportJobBeingViewed.status}
                />
                <a
                  className="ml-1 text-crate-blue"
                  href={exportJobBeingViewed.download_url}
                >
                  <FormattedMessage id="cluster.clusterExport.downloadExportLink" />
                </a>
              </div>
            )}
          </div>

          {exportJobBeingViewed.status === OPERATION_STATES.FAILED && (
            <div className="mt-4">
              <ErrorExportingHelp defaultOpen exportJob={exportJobBeingViewed} />
            </div>
          )}
        </Modal>
      )}
    </>
  );
}

ExportJobsList.propTypes = {
  exportJobs: PropTypes.arrayOf(exportJobPropType).isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default ExportJobsList;
