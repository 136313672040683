import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Link, useParams } from 'react-router-dom';
import { Tabs } from 'antd';
import { CopyToClipboard, Heading, Loader, Text } from '@cratedb/crate-gc-admin';
import { clusterPropType } from '../../../models';
import { useAnalytics } from '../../../hooks';
import { USER_TRACKING_EVENTS } from '../../../constants/segment';
import connectToClusterCodeSnippets from '../../../constants/connectToClusterCodeSnippets';
import CloudUISyntaxHighlighter from '../../../components/CloudUISyntaxHighlighter';
import {
  clusterBackup,
  clusterImport,
  clusterManage,
  clusterTutorials,
} from '../../../constants/paths';
import {
  CRATEDB_CLOUD_METRICS_TUTORIAL_DOCS,
  ORGANIZATION_PROMETHEUS_METRICS_API_DOCS,
} from '../../../constants/links';
import { getClusterIsEdge } from '../../../utils/data/cluster';
import useSessionStore from '../../../state/session';

function NextSteps({ cluster, loading }) {
  const clusterPasswords = useSessionStore(state => state.clusterPasswords);
  const { clusterId, projectId, organizationId } = useParams();
  const { trackEvent } = useAnalytics();

  const getCodeSnippets = () => {
    const isEdgeClusterWithNoExternalIP =
      getClusterIsEdge(cluster) && !cluster.external_ip;

    return connectToClusterCodeSnippets.filter(snippet =>
      isEdgeClusterWithNoExternalIP ? true : snippet.reachableClusterOnly,
    );
  };

  const getSnippetCode = snippet => {
    let output = snippet.code(cluster);
    if (output && clusterId in clusterPasswords) {
      output = output.replaceAll('<PASSWORD>', clusterPasswords[clusterId]);
    }
    return output;
  };

  return loading ? (
    <Loader
      color={Loader.colors.PRIMARY}
      size={Loader.sizes.SMALL}
      message={<FormattedMessage id="cluster.nextSteps.loadingText" />}
    />
  ) : (
    <div className="grid grid-cols-1 gap-8 lg:grid-cols-3">
      <div>
        <Heading level={Heading.levels.h2} className="mb-4">
          <FormattedMessage id="cluster.nextSteps.title" />
        </Heading>

        <Text pale>
          <FormattedMessage
            id="cluster.nextSteps.exploreTutorialLink"
            values={{
              tutorialLink: (
                <Link
                  to={{
                    pathname: clusterTutorials.build({
                      organizationId,
                      projectId,
                      clusterId,
                    }),
                  }}
                >
                  <button
                    type="button"
                    onClick={() => {
                      trackEvent(USER_TRACKING_EVENTS.CLICKED_BEGINNER_TUTORIAL);
                    }}
                  >
                    <FormattedMessage id="cluster.nextSteps.exploreTutorialLinkPartial" />
                  </button>
                </Link>
              ),
            }}
          />
        </Text>

        <Text pale className="mt-4">
          <FormattedMessage
            id="cluster.nextSteps.learnToImportLink"
            values={{
              link: (
                <Link
                  className="block"
                  to={{
                    pathname: clusterImport.build({
                      organizationId,
                      projectId,
                      clusterId,
                    }),
                  }}
                >
                  <button
                    type="button"
                    onClick={() => {
                      trackEvent(
                        USER_TRACKING_EVENTS.CLICKED_LEARN_IMPORT_DEMO_DATA,
                      );
                    }}
                  >
                    <FormattedMessage id="cluster.nextSteps.howToImportLinkPartial" />
                  </button>
                </Link>
              ),
            }}
          />
        </Text>

        <Text pale className="mt-4">
          <FormattedMessage
            id="cluster.nextSteps.cloneClusterLink"
            values={{
              link: (
                <Link
                  className="block"
                  to={{
                    pathname: clusterBackup.build({
                      organizationId,
                      projectId,
                      clusterId,
                    }),
                  }}
                >
                  <FormattedMessage id="cluster.nextSteps.cloneClusterLinkPartial" />
                </Link>
              ),
            }}
          />
        </Text>

        <Text pale className="mt-4">
          <FormattedMessage
            id="cluster.nextSteps.metricsAPIDocsHelp"
            values={{
              apiDocsLink: (
                <a
                  href={ORGANIZATION_PROMETHEUS_METRICS_API_DOCS}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <FormattedMessage id="cluster.nextSteps.metricsAPIDocsLinkPartial" />
                </a>
              ),
              tutorialLink: (
                <a
                  href={CRATEDB_CLOUD_METRICS_TUTORIAL_DOCS}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <FormattedMessage id="cluster.nextSteps.metricsTutorialLinkPartial" />
                </a>
              ),
            }}
          />
        </Text>
      </div>
      <div className="col-span-2">
        <Heading level={Heading.levels.h2}>
          <FormattedMessage id="cluster.nextSteps.connectingToYourCluster" />
        </Heading>

        <Tabs
          animated={false}
          onChange={tabName =>
            trackEvent(USER_TRACKING_EVENTS.CLICKED_LEARN_HOW_TO_CONNECT, {
              tab: tabName,
            })
          }
          items={getCodeSnippets().map(codeSnippet => ({
            label: codeSnippet.displayName,
            key: codeSnippet.displayName,
            children: (
              <>
                {codeSnippet.intro}
                {codeSnippet.snippets.map(snippet => (
                  <div key={snippet.name}>
                    {snippet?.code && (
                      <CloudUISyntaxHighlighter
                        language={codeSnippet.language}
                        title={snippet.name}
                        helptext={
                          <>
                            {snippet.docsLink && (
                              <FormattedMessage
                                id="accessCluster.modalLearnMoreLink"
                                values={{
                                  what: snippet.docsLinkName || snippet.name,
                                  link: (
                                    <a href={snippet.docsLink}>
                                      <FormattedMessage id="accessCluster.modalLearnMoreLinkPartial" />
                                    </a>
                                  ),
                                }}
                              />
                            )}
                            {snippet.description && snippet.description}
                          </>
                        }
                      >
                        {getSnippetCode(snippet)}
                      </CloudUISyntaxHighlighter>
                    )}
                    {snippet?.password && (
                      <>
                        <Heading level={Heading.levels.h4} className="mb-2 mt-6">
                          {snippet.name}
                        </Heading>
                        <div className="rounded-lg bg-neutral-100 py-1 pl-2 pr-4">
                          <div className="flex items-center justify-between gap-4">
                            <pre className="p-2">
                              &bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;
                            </pre>
                            <div className="flex items-center justify-between gap-4">
                              <Link
                                to={{
                                  pathname: clusterManage.build({
                                    clusterId: cluster.id,
                                    projectId: cluster.project_id,
                                    organizationId,
                                  }),
                                }}
                              >
                                <Text className="py-2 text-sm text-crate-blue">
                                  <FormattedMessage id="common.update" />
                                </Text>
                              </Link>
                              {clusterId in clusterPasswords && (
                                <CopyToClipboard
                                  textToCopy={clusterPasswords[clusterId]}
                                >
                                  <Text className="py-2 text-sm text-crate-blue">
                                    <FormattedMessage id="common.copy" />
                                  </Text>
                                </CopyToClipboard>
                              )}
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                ))}
              </>
            ),
          }))}
        />
      </div>
    </div>
  );
}

NextSteps.propTypes = {
  cluster: clusterPropType.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default NextSteps;
