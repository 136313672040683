import { FormattedMessage, useIntl } from 'react-intl';
import { LoadingOutlined } from '@ant-design/icons';
import { PRICE_OPTIONS_2DP, PRICE_OPTIONS_3DP } from '../../constants/defaults';
import { Price } from 'src/types';
import { FormatNumberOptions } from 'react-intl';

export type PricingBreakdownProps = {
  computeText?: string;
  loading: boolean;
  price?: Price;
  storageText?: string;
  title?: string;
};

function PricingBreakdown({
  loading,
  price,
  title,
  computeText,
  storageText,
}: PricingBreakdownProps) {
  const { formatNumber } = useIntl();

  const displayPrice = (
    key: 'cluster_price' | 'storage_price' | 'total_price',
    unit: 'hour' | 'month',
    options: FormatNumberOptions,
  ) => {
    if (!(key in price!)) {
      return 'n/a';
    }

    const regularPrice = price![key]?.[`price_per_${unit}`];
    const promotionPrice = price![key]?.promotion_price?.[`price_per_${unit}`];
    const totalPrice = promotionPrice ?? regularPrice;

    if (totalPrice === undefined) {
      return 'n/a';
    }

    return `${unit === 'month' ? '~' : ''}${formatNumber(totalPrice, options)}`;
  };

  return (
    <table className="w-full" data-testid="pricing-breakdown">
      <thead>
        <tr>
          <th className="w-full text-left">{title}</th>
          <th className="py-1.5 pl-6 text-right text-crate-gray30">
            <FormattedMessage id="common.hourly" />
          </th>
          <th className="py-1.5 pl-6 text-right">
            <FormattedMessage id="common.monthly" />
          </th>
        </tr>
      </thead>
      {!loading && price && (
        <tbody>
          <tr>
            <td className="py-1.5">
              <FormattedMessage id="common.compute" />
              {computeText && (
                <div className="text-xs text-crate-gray30">{computeText}</div>
              )}
            </td>
            <td
              className="py-1.5 pl-6 text-right text-crate-gray30"
              data-testid="price-compute-hour"
            >
              {displayPrice('cluster_price', 'hour', PRICE_OPTIONS_3DP)}
            </td>
            <td className="py-1.5 pl-6 text-right" data-testid="price-compute-month">
              {displayPrice('cluster_price', 'month', PRICE_OPTIONS_2DP)}
            </td>
          </tr>
          <tr>
            <td className="pb-2.5 pt-1.5">
              <FormattedMessage id="common.storage" />
              {storageText && (
                <div className="text-xs text-crate-gray30">{storageText}</div>
              )}
            </td>
            <td
              className="pb-2.5 pl-6 pt-1.5 text-right text-crate-gray30"
              data-testid="price-storage-hour"
            >
              {displayPrice('storage_price', 'hour', PRICE_OPTIONS_3DP)}
            </td>
            <td
              className="pb-2.5 pl-6 pt-1.5 text-right"
              data-testid="price-storage-month"
            >
              {displayPrice('storage_price', 'month', PRICE_OPTIONS_2DP)}
            </td>
          </tr>
          <tr>
            <td className="border-t-2 pb-1.5 pt-2.5">
              <FormattedMessage id="common.total" />
            </td>
            <td
              className="border-t-2 pb-1.5 pl-6 pt-2.5 text-right text-crate-gray30"
              data-testid="price-total-hour"
            >
              {displayPrice('total_price', 'hour', PRICE_OPTIONS_3DP)}
            </td>
            <td
              className="border-t-2 pb-1.5 pl-6 pt-2.5 text-right"
              data-testid="price-total-month"
            >
              {displayPrice('total_price', 'month', PRICE_OPTIONS_2DP)}
            </td>
          </tr>
        </tbody>
      )}
      {(loading || !price) && (
        <tbody>
          <tr>
            <td className="py-1.5">
              <FormattedMessage id="common.compute" />
            </td>
            <td colSpan={2} rowSpan={3} className="p-2 text-center align-middle">
              <LoadingOutlined spin data-testid="loading-icon" />
            </td>
          </tr>
          <tr>
            <td className="pb-2.5 pt-1.5">
              <FormattedMessage id="common.storage" />
            </td>
          </tr>
          <tr>
            <td className="pb-1.5 pt-3">
              <FormattedMessage id="common.total" />
            </td>
          </tr>
        </tbody>
      )}
    </table>
  );
}

export default PricingBreakdown;
