import { useState, useMemo, Ref } from 'react';
import { useParams } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';
import { Input, Form, Select } from 'antd';
import countries from 'i18n-iso-countries';
import { Text } from '@cratedb/crate-gc-admin';
import stripeLogo from '../../assets/icon_stripe_powered_by.svg';
import {
  useGetFeaturesStatus,
  useGetOrganizationsIdPaymentmethods,
} from '../../swrHooks';
import { useAnalytics } from '../../hooks';
import getFeatureFlag from '../../utils/data/features';
import featureToggleNames from '../../constants/featureToggleNames';
import FeatureToggle from '../FeatureToggle';
import { apiPost } from '../../api';
import useMessage from '../../hooks/useMessage';
import { USER_TRACKING_EVENTS } from '../../constants/segment';
import { FormInstance } from 'antd/es/form/Form';
import { StripeElementChangeEvent } from '@stripe/stripe-js';

const CARD_OPTIONS = {
  style: {
    base: {
      letterSpacing: '0.025em',
      '::placeholder': {
        color: '#aab7c4',
      },
      '::selection': {
        backgroundColor: '#FFF',
      },
      fontSize: '17px',
    },
    invalid: {
      color: '#9e2146',
    },
  },
};

export type PaymentEntryFormProps = {
  footer?: React.ReactNode | React.ReactNode[];
  formRef?: Ref<FormInstance>;
  onSubmitCallback: (success: boolean) => void;
};

type StripeValidationState = {
  values: {
    cardNumber: string | undefined;
    cardExpiry: string | undefined;
    cardCvc: string | undefined;
  };
  errors: {
    cardNumber?: { code: string };
    cardExpiry?: { code: string };
    cardCvc?: { code: string };
  };
};

function PaymentEntryForm({
  footer,
  formRef,
  onSubmitCallback,
}: PaymentEntryFormProps) {
  const { formatMessage } = useIntl();
  const stripe = useStripe();
  const elements = useElements();
  const { trackEvent } = useAnalytics();
  const { showErrorMessage, showLoadingMessage, showSuccessMessage } = useMessage();
  const { organizationId } = useParams();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [stripeValidationState, setStripeValidationState] =
    useState<StripeValidationState>({
      values: {
        cardNumber: undefined,
        cardExpiry: undefined,
        cardCvc: undefined,
      },
      errors: {},
    });
  const [mandateOptInIsChecked, setMandateOptInIsChecked] = useState(false);
  const [paymentOptInIsChecked, setPaymentOptInIsChecked] = useState(false);
  const [mandateOptInValidationIsVisible, setMandateOptInValidationIsVisible] =
    useState(false);
  const [paymentOptInValidationIsVisible, setPaymentOptInValidationIsVisible] =
    useState(false);

  const { mutate: mutatePaymentMethods } =
    useGetOrganizationsIdPaymentmethods(organizationId);
  const { data: featureFlags } = useGetFeaturesStatus();
  const usePaymentIntent = getFeatureFlag(
    featureFlags,
    featureToggleNames.FEATURE_USE_PAYMENT_INTENT,
  );

  const [form] = Form.useForm();

  const disableForm = (isDisabled: boolean) => {
    elements!.getElement(CardNumberElement)!.update({ disabled: isDisabled });
    elements!.getElement(CardExpiryElement)!.update({ disabled: isDisabled });
    elements!.getElement(CardCvcElement)!.update({ disabled: isDisabled });
    setIsSubmitting(isDisabled);
  };

  const handleStripeInputChange = ({
    elementType,
    error,
    ...rest
  }: StripeElementChangeEvent) => {
    // React state updates are batched so if we
    // receive several updates to Stripe inputs in a
    // short space of time, e.g. Autocomplete
    // we may be referencing state state values
    // which may overwrite up to date values when the
    // state updates are batched together.
    // Passing an arrow function here guarantees
    // that we get access to the state updates
    // from the previous elementType updates
    setStripeValidationState(previousValidationState => ({
      values: {
        ...previousValidationState.values,
        [elementType]: rest,
      },
      errors: {
        ...previousValidationState.errors,
        [elementType]: error,
      },
    }));
  };

  const mappedCountries = useMemo(
    () =>
      Object.entries(countries.getNames('en', { select: 'official' })).flatMap(
        ([value, label]) => ({ value, label }),
      ),
    [],
  );

  const handleMandateOptInChange = () => {
    setMandateOptInIsChecked(!mandateOptInIsChecked);
    setMandateOptInValidationIsVisible(mandateOptInIsChecked);
  };

  const handlePaymentOptInChange = () => {
    setPaymentOptInIsChecked(!paymentOptInIsChecked);
    setPaymentOptInValidationIsVisible(paymentOptInIsChecked);
  };

  const handleFormFinishFailed = () => {
    setMandateOptInValidationIsVisible(!mandateOptInIsChecked);
    setPaymentOptInValidationIsVisible(usePaymentIntent && !paymentOptInIsChecked);
  };

  const handleFormFinish = async (values: {
    cardName: string;
    line1: string;
    line2: string;
    city: string;
    postal_code: string;
    country: string;
  }) => {
    // bail if the form is not ready
    if (!stripe || !elements) {
      onSubmitCallback(false);
      return;
    }

    // bail if checkbox(es) not checked
    if (!mandateOptInIsChecked || (usePaymentIntent && !paymentOptInIsChecked)) {
      setMandateOptInValidationIsVisible(!mandateOptInIsChecked);
      setPaymentOptInValidationIsVisible(usePaymentIntent && !paymentOptInIsChecked);
      onSubmitCallback(false);
      return;
    }

    // update the UI: show loading message and disable the form
    showLoadingMessage(
      formatMessage({ id: 'organization.paymentMethods.addingBankCardText' }),
    );
    disableForm(true);

    // init vars
    const billingDetails = { ...values, mandateOptIn: mandateOptInIsChecked };
    const paymentSetupUrl = usePaymentIntent
      ? `/api/v2/stripe/card/organizations/${organizationId}/setup-payment/`
      : `/api/v2/stripe/card/organizations/${organizationId}/setup/`;
    const stripeSetupMethod = usePaymentIntent
      ? stripe.confirmCardPayment
      : stripe.confirmCardSetup;

    // create PaymentIntent or SetupIntent
    const response = await apiPost<{
      client_secret: string;
      payment_intent: string;
    }>(paymentSetupUrl);

    if (!response.data) {
      showErrorMessage(
        formatMessage({
          id: 'organization.paymentMethods.addingBankCardFailureText',
        }),
      );
      disableForm(false);
      onSubmitCallback(false);
      return;
    }
    const { client_secret: clientSecret, payment_intent: paymentIntent } =
      response.data;

    // create card
    const { error } = await stripeSetupMethod(clientSecret, {
      payment_method: {
        card: elements.getElement(CardNumberElement)!,
        billing_details: {
          name: billingDetails.cardName,
          address: {
            line1: billingDetails.line1,
            line2: billingDetails.line2,
            city: billingDetails.city,
            postal_code: billingDetails.postal_code,
            country: billingDetails.country,
          },
        },
      },
    });

    if (error) {
      showErrorMessage(
        formatMessage({
          id: 'organization.paymentMethods.addingBankCardFailureText',
        }),
      );
      disableForm(false);
      onSubmitCallback(false);
      return;
    }

    if (paymentIntent) {
      // the response from this api call determines if the $1 payment was successful
      // so we can validate the card and refund the payment
      const { success: isCardValid } = await apiPost(
        `/api/v2/stripe/organizations/${organizationId}/validate-card/`,
        {
          payment_intent: paymentIntent,
        },
      );

      if (!isCardValid) {
        showErrorMessage(
          formatMessage({
            id: 'organization.paymentMethods.addingBankCardFailureText',
          }),
        );
        disableForm(false);
        onSubmitCallback(false);
        return;
      }
    }

    // clear the form
    elements.getElement(CardNumberElement)!.clear();
    elements.getElement(CardExpiryElement)!.clear();
    elements.getElement(CardCvcElement)!.clear();
    form.resetFields();

    // track the event, show a success message and send the callback
    trackEvent(USER_TRACKING_EVENTS.USER_ADD_BANK_CARD);
    mutatePaymentMethods();
    showSuccessMessage(
      formatMessage({
        id: 'organization.paymentMethods.addingBankCardSuccessText',
      }),
    );
    disableForm(false);
    onSubmitCallback(true);
  };

  return (
    <Form
      onFinish={handleFormFinish}
      onFinishFailed={handleFormFinishFailed}
      aria-label="payment entry form"
      name="payment entry form"
      layout="vertical"
      form={form}
      ref={formRef}
      disabled={isSubmitting}
      data-testid="payment-entry-form"
    >
      <div className="-mx-5 h-full px-5">
        {/* stipe elements */}
        <fieldset data-testid="stripe-elements">
          <div className="lg:grid lg:grid-cols-12 lg:gap-4">
            <div className="mb-6 lg:col-span-6">
              <div className="relative mb-2 whitespace-nowrap before:mr-1 before:inline-block before:font-simsun before:text-sm before:leading-4 before:text-red-400 before:content-['*']">
                <FormattedMessage id="paymentEntryForm.creditCardLabel" />
              </div>
              <div
                className={`rounded border-2 border-crate-border-light px-[11px] py-[7px] ${
                  isSubmitting ? 'bg-crate-form-disabled' : ''
                }`}
              >
                <CardNumberElement
                  onChange={handleStripeInputChange}
                  options={CARD_OPTIONS}
                />
              </div>
              <div className="hidden md:block">
                {stripeValidationState.errors.cardNumber && (
                  <div
                    className="mt-1 text-sm leading-5 text-red-500"
                    key={stripeValidationState.errors.cardNumber.code}
                  >
                    <FormattedMessage
                      id={`paymentEntryForm.${stripeValidationState.errors.cardNumber.code}Error`}
                    />
                  </div>
                )}
              </div>
            </div>
            <div className="mb-6 lg:col-span-4">
              <div className="relative mb-2 whitespace-nowrap before:mr-1 before:inline-block before:font-simsun before:text-sm before:leading-4 before:text-red-400 before:content-['*']">
                <FormattedMessage id="paymentEntryForm.expiryLabel" />
              </div>
              <div
                className={`rounded border-2 border-crate-border-light px-[11px] py-[7px] ${
                  isSubmitting ? 'bg-crate-form-disabled' : ''
                }`}
              >
                <CardExpiryElement
                  onChange={handleStripeInputChange}
                  options={CARD_OPTIONS}
                />
              </div>
              <div className="hidden md:block">
                {stripeValidationState.errors.cardExpiry && (
                  <div
                    className="mt-1 text-sm leading-5 text-red-500"
                    key={stripeValidationState.errors.cardExpiry.code}
                  >
                    <FormattedMessage
                      id={`paymentEntryForm.${stripeValidationState.errors.cardExpiry.code}Error`}
                    />
                  </div>
                )}
              </div>
            </div>
            <div className="mb-6 lg:col-span-2">
              <div className="relative mb-2 whitespace-nowrap before:mr-1 before:inline-block before:font-simsun before:text-sm before:leading-4 before:text-red-400 before:content-['*']">
                <FormattedMessage id="paymentEntryForm.cvcLabel" />
              </div>
              <div
                className={`rounded border-2 border-crate-border-light px-[11px] py-[7px] ${
                  isSubmitting ? 'bg-crate-form-disabled' : ''
                }`}
              >
                <CardCvcElement
                  onChange={handleStripeInputChange}
                  options={CARD_OPTIONS}
                />
              </div>
              <div className="hidden md:block">
                {stripeValidationState.errors.cardCvc && (
                  <div
                    className="mt-1 text-sm leading-5 text-red-500"
                    key={stripeValidationState.errors.cardCvc.code}
                  >
                    <FormattedMessage
                      id={`paymentEntryForm.${stripeValidationState.errors.cardCvc.code}Error`}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </fieldset>

        {/* regular fields */}
        <fieldset className="mb-4">
          <Form.Item
            name="cardName"
            label={formatMessage({
              id: 'paymentEntryForm.cardNameLabel',
            })}
            colon={false}
            rules={[
              {
                max: 50,
                message: formatMessage({
                  id: 'paymentEntryForm.cardNameError',
                }),
                required: true,
              },
            ]}
          >
            <Input className="border-2 border-crate-border-light" />
          </Form.Item>

          <Form.Item
            name="line1"
            label={formatMessage({
              id: 'paymentEntryForm.line1Label',
            })}
            colon={false}
            rules={[
              {
                max: 150,
                message: formatMessage({
                  id: 'paymentEntryForm.line1Error',
                }),
                required: true,
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="line2"
            label={formatMessage({
              id: 'paymentEntryForm.line2Label',
            })}
            colon={false}
            rules={[
              {
                max: 150,
              },
            ]}
          >
            <Input />
          </Form.Item>

          <div className="lg:grid lg:grid-cols-12 lg:gap-4">
            <div className="lg:col-span-6">
              <Form.Item
                name="city"
                label={formatMessage({
                  id: 'paymentEntryForm.cityLabel',
                })}
                colon={false}
                rules={[
                  {
                    max: 50,
                    message: formatMessage({
                      id: 'paymentEntryForm.cityError',
                    }),
                    required: true,
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </div>
            <div className="lg:col-span-3">
              <Form.Item
                name="postal_code"
                label={formatMessage({
                  id: 'paymentEntryForm.postal_codeLabel',
                })}
                colon={false}
                rules={[
                  {
                    max: 16,
                    message: formatMessage({
                      id: 'paymentEntryForm.postal_codeError',
                    }),
                    required: true,
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </div>
            <div className="lg:col-span-3">
              <Form.Item
                name="country"
                label={formatMessage({
                  id: 'paymentEntryForm.countryLabel',
                })}
                colon={false}
                rules={[
                  {
                    required: true,
                    message: formatMessage({
                      id: 'paymentEntryForm.countryError',
                    }),
                  },
                ]}
              >
                <Select
                  showSearch
                  filterOption={(input, option) =>
                    (option?.label?.toLowerCase() ?? '').includes(
                      input.toLowerCase(),
                    )
                  }
                  virtual={false}
                  options={mappedCountries.map(country => {
                    return { label: country.label, value: country.value };
                  })}
                />
              </Form.Item>
            </div>
          </div>
        </fieldset>

        {/* checkboxes */}
        <fieldset className="mb-4">
          {/* authorization mandate */}
          <div className="flex">
            <div className="mr-4">
              <input
                aria-labelledby="input-authorization-mandate"
                className="cursor-pointer"
                onChange={handleMandateOptInChange}
                type="checkbox"
                checked={mandateOptInIsChecked}
                disabled={isSubmitting}
              />
            </div>
            <button
              className="text-left"
              onClick={handleMandateOptInChange}
              type="button"
            >
              <Text pale id="input-authorization-mandate">
                <FormattedMessage id="paymentEntryForm.paymentEntryMandateTermsText" />
              </Text>
              {mandateOptInValidationIsVisible && (
                <div className="mt-1 text-sm leading-5 text-red-500">
                  <FormattedMessage id="paymentEntryForm.uncheckedMandateOptInError" />
                </div>
              )}
            </button>
          </div>

          {/* $1 test payment intent */}
          <FeatureToggle featureName={featureToggleNames.FEATURE_USE_PAYMENT_INTENT}>
            <div className="mb-4 mt-4 flex">
              <div className="mr-4">
                <input
                  aria-labelledby="input-payment-mandate"
                  className="cursor-pointer"
                  onChange={handlePaymentOptInChange}
                  type="checkbox"
                  checked={paymentOptInIsChecked}
                  disabled={isSubmitting}
                />
              </div>
              <button
                className="text-left"
                onClick={handlePaymentOptInChange}
                type="button"
              >
                <Text pale id="input-payment-mandate">
                  <FormattedMessage id="paymentEntryForm.paymentEntryTestPaymentText" />
                </Text>
                {paymentOptInValidationIsVisible && (
                  <div className="mt-1 text-sm leading-5 text-red-500">
                    <FormattedMessage id="paymentEntryForm.uncheckedPaymentOptInError" />
                  </div>
                )}
              </button>
            </div>
          </FeatureToggle>
        </fieldset>

        <div className="flex flex-col items-center justify-start md:flex-row md:justify-between">
          <div className="mb-4 flex min-w-[140px] items-center md:mb-0">
            <img src={stripeLogo} alt="powered by Stripe secure payments" />
          </div>

          <div className="flex items-center">{footer}</div>
        </div>
      </div>
    </Form>
  );
}

export default PaymentEntryForm;
