/* eslint-disable camelcase */
// required for the API POST

import { useState } from 'react';
import { Form, Input, Select } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import { Loader, SubmitButtonGroup } from '@cratedb/crate-gc-admin';
import { useGetOrganizationsIdSecrets } from 'src/swrHooks';
import CloudUIDrawer, {
  CloudUIDrawerFooterControlsContainer,
} from '../../../components/CloudUIDrawer';
import { apiPost } from '../../../api';
import { SECRET_TYPES, SecretType } from '../../../constants/defaults';
import useMessage from '../../../hooks/useMessage';
import { Secret, SecretId } from 'src/types';

export type AddSecretDrawerProps = {
  onClose: (secretId?: SecretId) => void;
  organizationId: string;
  isVisible: boolean;
  secretType?: SecretType;
};

function AddSecretDrawer({
  onClose,
  organizationId,
  isVisible,
  secretType,
}: AddSecretDrawerProps) {
  const [form] = Form.useForm();
  const selectedSecretType = Form.useWatch('type', form);
  const { formatMessage } = useIntl();
  const { showSuccessMessage } = useMessage();
  const [submitInProgress, setSubmitInProgress] = useState(false);
  const { resetFields } = form;
  const { mutate: mutateSecrets } = useGetOrganizationsIdSecrets(organizationId);

  const handleClose = (keyId?: SecretId) => {
    resetFields(['name', 'access_key', 'secret_key', 'connection_string']);
    onClose(keyId);
  };

  const handleFormFinish = async ({
    name,
    access_key,
    secret_key,
    connection_string,
    type,
  }: {
    name: string;
    access_key: string;
    secret_key: string;
    connection_string: string;
    type: SecretType;
  }) => {
    // prevent the user submitting the form multiple times
    if (!submitInProgress) {
      setSubmitInProgress(true);

      // generate the POST payload
      const payload: {
        data: {
          aws_secret?: { access_key: string; secret_key: string };
          azure_secret?: { connection_string: string };
        };
        name: string;
        type: string;
      } = {
        data: {},
        name,
        type,
      };
      switch (type) {
        case SECRET_TYPES.AWS:
          payload.data.aws_secret = {
            access_key: access_key.trim(),
            secret_key: secret_key.trim(),
          };
          break;
        case SECRET_TYPES.AZURE:
          payload.data.azure_secret = {
            connection_string: connection_string.trim(),
          };
          break;
        default:
          return;
      }

      // POST to API
      const { success, data: newSecret } = await apiPost<Secret>(
        `/api/v2/organizations/${organizationId}/secrets/`,
        payload,
      );

      // update UI on success
      if (success) {
        showSuccessMessage(
          formatMessage({ id: 'organization.secrets.secretAddedText' }, { name }),
        );
        mutateSecrets();
        handleClose(newSecret!.id);
      }

      setSubmitInProgress(false);
    }
  };

  return (
    <CloudUIDrawer
      onClose={handleClose}
      title={<FormattedMessage id="organization.secrets.addNewSecretTitle" />}
      visible={isVisible}
    >
      {submitInProgress ? (
        <div className="flex h-full w-full items-center justify-center">
          <Loader color={Loader.colors.PRIMARY} />
        </div>
      ) : (
        <Form
          layout="vertical"
          aria-label="add a new access secret"
          form={form}
          onFinish={handleFormFinish}
          initialValues={{
            type: secretType !== null ? secretType : SECRET_TYPES.AWS,
          }}
        >
          <Form.Item
            colon={false}
            extra={
              <FormattedMessage id="organization.secrets.secretNameDescriptionText" />
            }
            name="name"
            label={<FormattedMessage id="organization.secrets.secretNameLabel" />}
            hasFeedback
            rules={[
              {
                max: 500,
                message: formatMessage({
                  id: 'organization.secrets.secretNameRequiredFieldText',
                }),
                required: true,
              },
            ]}
          >
            <Input
              placeholder={formatMessage({
                id: 'organization.secrets.secretNameLabel',
              })}
              autoComplete="off"
            />
          </Form.Item>

          <Form.Item
            colon={false}
            name="type"
            label={<FormattedMessage id="organization.secrets.typeLabel" />}
            data-testid="secret-type"
          >
            <Select
              options={[
                { value: SECRET_TYPES.AWS, label: 'AWS' },
                { value: SECRET_TYPES.AZURE, label: 'Azure' },
              ]}
              disabled={!!secretType}
            />
          </Form.Item>

          {selectedSecretType === SECRET_TYPES.AWS && (
            <>
              <Form.Item
                colon={false}
                name="access_key"
                label={
                  <FormattedMessage id="organization.secrets.accessKeyIdLabel" />
                }
                hasFeedback
                rules={[
                  {
                    max: 500,
                    message: formatMessage({
                      id: 'organization.secrets.accessKeyRequiredFieldText',
                    }),
                    required: true,
                  },
                ]}
              >
                <Input
                  placeholder={formatMessage({
                    id: 'organization.secrets.accessKeyIdLabel',
                  })}
                  autoComplete="off"
                />
              </Form.Item>
              <Form.Item
                colon={false}
                name="secret_key"
                label={<FormattedMessage id="organization.secrets.secretKeyLabel" />}
                hasFeedback
                rules={[
                  {
                    max: 500,
                    message: formatMessage({
                      id: 'organization.secrets.secretKeyRequiredFieldText',
                    }),
                    required: true,
                  },
                ]}
              >
                <Input.Password
                  placeholder={formatMessage({
                    id: 'organization.secrets.secretKeyLabel',
                  })}
                  autoComplete="off"
                />
              </Form.Item>
            </>
          )}

          {selectedSecretType === SECRET_TYPES.AZURE && (
            <Form.Item
              colon={false}
              name="connection_string"
              label={
                <FormattedMessage id="organization.secrets.connectionStringLabel" />
              }
              hasFeedback
              rules={[
                {
                  max: 500,
                  message: formatMessage({
                    id: 'organization.secrets.connectionStringRequiredFieldText',
                  }),
                  required: true,
                },
              ]}
            >
              <Input.Password
                placeholder={formatMessage({
                  id: 'organization.secrets.connectionStringLabel',
                })}
                autoComplete="off"
              />
            </Form.Item>
          )}

          <CloudUIDrawerFooterControlsContainer>
            <SubmitButtonGroup
              confirmLabel={formatMessage({ id: 'common.save' })}
              onCancel={handleClose}
            />
          </CloudUIDrawerFooterControlsContainer>
        </Form>
      )}
    </CloudUIDrawer>
  );
}

export default AddSecretDrawer;
