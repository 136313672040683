import { DEV, LOCAL, PROD, getEnvironment } from './environment';

export const getDomain = (): string => {
  switch (getEnvironment()) {
    case PROD:
      return 'cratedb.cloud';
    case DEV:
      return 'cratedb-dev.cloud';
    case LOCAL:
      return 'localhost';
    default:
      return 'cratedb.cloud';
  }
};

const defaultCookieOptions = {
  domain: getDomain(),
  path: '/',
} as const;

export const expiringCookieOptions = {
  ...defaultCookieOptions,
  expires: 7, // 7 days in the future
} as const;

export const expiring24hCookieOptions = {
  ...defaultCookieOptions,
  expires: 1, // 24hr in the future
} as const;
