import cx from 'classnames';
import {
  BulbOutlined,
  InfoCircleOutlined,
  WarningOutlined,
} from '@ant-design/icons';
import NOTIFICATION_TYPES, { NotificationType } from './constants';

export type NotificationAsideProps = {
  type: NotificationType;
  message: string; // Accept a string for message and not a node to ensure consistent presentation
  link?: React.ReactNode;
};

function NotificationAside({
  type,
  message,
  link = undefined,
}: NotificationAsideProps) {
  const typeMap = {
    [NOTIFICATION_TYPES.TOOLTIP]: {
      icon: <BulbOutlined className="left-0 mr-2 text-base" />,
      bgClass: 'bg-white',
      fontClass: 'font-bold',
    },
    [NOTIFICATION_TYPES.INFO]: {
      icon: <InfoCircleOutlined className="left-0 mr-2 text-base text-amber-600" />,
      bgClass: 'bg-white',
      fontClass: '',
    },
    [NOTIFICATION_TYPES.WARN]: {
      icon: <WarningOutlined className="left-0 mr-2 text-base text-amber-600" />,
      bgClass: 'bg-amber-200',
      fontClass: '',
    },
  };

  return (
    <div className={cx('p-4', 'rounded-lg', 'w-full', typeMap[type].bgClass)}>
      <div
        className={cx('flex', 'items-center', {
          'justify-between': type !== NOTIFICATION_TYPES.TOOLTIP,
        })}
      >
        <div className="mr-2 flex items-center">
          {typeMap[type].icon}
          <div className={`mr-1 ${typeMap[type].fontClass} `}>{message}</div>
        </div>
        {link && link}
      </div>
    </div>
  );
}

NotificationAside.types = NOTIFICATION_TYPES;

export default NotificationAside;
