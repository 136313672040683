import {
  MONGODB_COMPLETE_SCRAM_CONNECTION_STRING_REGEX,
  MONGODB_X509_CONNECTION_STRING_REGEX,
} from 'src/features/clusters/ClusterImportIntegration/constants';

export default {
  // https://learn.microsoft.com/en-us/rest/api/storageservices/naming-and-referencing-containers--blobs--and-metadata
  // this regex doesn't cover every possibility but more than enough for our use case
  AZURE_CONTAINER_NAME: {
    max: 63,
    message: 'Please enter a valid container name',
    min: 3,
    required: true,
    validator: /^[a-z0-9][a-z0-9-]+[a-z0-9]$/,
  },
  CLUSTER_NAME: {
    max: 63,
    message:
      'Only lowercase letters, numbers, and dashes are allowed. Begin with a letter and end with a letter or a number. Minimum 3 characters.',
    min: 3,
    required: true,
    pattern: /^[a-z][a-z0-9-]+[a-z0-9]$/,
  },
  GENERIC_EMAIL: {
    message: 'Please input your email address',
    required: true,
    type: 'email',
  },
  GENERIC_FILE_PATH: {
    max: 255,
    message: 'Please enter a valid file path',
    pattern: /^.+\..+$/,
    required: true,
  },
  GENERIC_OPTIONAL_TEXT: {
    max: 150,
  },
  GENERIC_REQUIRED_TEXT: {
    max: 150,
    required: true,
  },
  GENERIC_URL: {
    message: 'Please input a valid URL',
    required: true,
    type: 'url',
  },
  HUBSPOT_NAME: {
    max: 50,
    message: 'Please input your name',
    required: true,
    whitespace: true,
  },
  HUBSPOT_MESSAGE: {
    max: 500,
    message: 'Please enter a message',
    required: true,
    whitespace: true,
  },
  PHONE_OPTIONAL: {
    max: 20,
  },
  PHONE_REQUIRED: {
    max: 20,
    message: 'Please enter your phone number including the country code.',
    required: true,
    whitespace: true,
  },
  ORGANIZATION_NAME: {
    max: 63,
    message: 'Please enter an organization name',
    required: true,
    whitespace: true,
  },
  // https://docs.aws.amazon.com/AmazonS3/latest/userguide/bucketnamingrules.html
  // this regex doesn't cover every possibility but more than enough for our use case
  S3_BUCKET_NAME: {
    max: 63,
    message: 'Please enter a valid bucket name',
    min: 3,
    required: true,
    validator: /^[a-z0-9][a-z0-9.-]+[a-z0-9]$/,
  },
  // https://cratedb.com/docs/crate/reference/en/latest/general/ddl/create-table.html#id3
  TABLE_NAME: {
    max: 255,
    message: 'Please enter a valid name',
    pattern: /^(?:(?:"[^".]+"|\w+)\.)?"[^".]+"|\w+$/,
    required: true,
  },
  // https://www.mongodb.com/docs/manual/reference/limits/#std-label-restrictions-on-db-names
  MONGODB_DATABASE_NAME: {
    max: 64,
    message: 'Please enter a valid database name',
    pattern: /^[^/\\."*<>:|?]{1,64}$/,
    required: true,
  },
  // https://www.mongodb.com/docs/manual/reference/limits/#mongodb-limit-Restriction-on-Collection-Names
  MONGODB_COLLECTION_NAME: {
    max: 120,
    message: 'Please enter a valid collection name',
    pattern: /^[^\0]{1,119}[^\0.]$/,
    required: true,
  },
  MONGODB_SCRAM_CONNECTION_STRING: {
    required: true,
    pattern: MONGODB_COMPLETE_SCRAM_CONNECTION_STRING_REGEX,
    message: 'Enter a valid connection string',
  },
  MONGODB_X509_CONNECTION_STRING: {
    required: true,
    pattern: MONGODB_X509_CONNECTION_STRING_REGEX,
    message: 'Enter a valid connection string',
  },
} as const;
