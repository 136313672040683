import { FormattedMessage, useIntl } from 'react-intl';
import { useShallow } from 'zustand/shallow';
import { Form, Input } from 'antd';
import CloudConfiguration from './CloudConfiguration';
import EdgeConfiguration from './EdgeConfiguration';
import GroupSelector from './GroupSelector';
import RegionSelector from './RegionSelector';
import HubspotFormEnterpriseCluster from '../../../components/HubspotForms/HubspotFormEnterpriseCluster/HubspotFormEnterpriseCluster';
import TestEventHandler from '../../../components/TestEventHandler';
import useDeployStore from '../state';
import { CLOUD_GROUPS } from '../../../constants/deploy';
import INPUT_SANITIZATION from '../../../constants/inputSanitization';
import { Cluster, OrganizationId, Product, Region } from 'src/types';

export type ClusterSpecificationProps = {
  clusters?: Cluster[];
  organizationId: OrganizationId;
  products?: Product[];
  regions?: Region[];
  validateCustomBackupLocation: () => void;
  onNameSubmit: () => void;
};

function ClusterSpecification({
  clusters,
  organizationId,
  products,
  regions,
  validateCustomBackupLocation,
  onNameSubmit,
}: ClusterSpecificationProps) {
  const [form] = Form.useForm();
  const { formatMessage } = useIntl();
  const {
    cloudGroup,
    clusterName,
    clusterNameIsUnique,
    clusterNameIsValid,
    deployRegion,
    setClusterName,
    setClusterNameIsValid,
    setClusterNameIsUnique,
  } = useDeployStore(useShallow(state => state));

  const displayClusterNameStatus = () => {
    if (!clusterName) {
      return (
        <div
          className="ant-form-item-explain-error"
          data-testid="cluster-name-empty-error"
        >
          <FormattedMessage id="deploy.clusterSpecification.clusterNameMissingValidationError" />
        </div>
      );
    }

    if (!clusterNameIsValid) {
      return (
        <div
          className="ant-form-item-explain-error"
          data-testid="cluster-name-invalid-error"
        >
          <FormattedMessage id="deploy.clusterSpecification.clusterNameURLSafeValidationError" />
        </div>
      );
    }

    if (!clusterNameIsUnique) {
      return (
        <div
          className="ant-form-item-explain-error"
          data-testid="cluster-name-duplicate-error"
        >
          <FormattedMessage
            id="deploy.clusterSpecification.clusterNameUniqueValidationError"
            values={{ name: clusterName }}
          />
        </div>
      );
    }

    return (
      <div className="pt-0.5 text-right text-xs opacity-50">
        <FormattedMessage id="deploy.clusterSpecification.clusterNameLabelHelp" />
      </div>
    );
  };

  if (cloudGroup === CLOUD_GROUPS.ENTERPRISE) {
    return (
      <>
        <GroupSelector />
        <HubspotFormEnterpriseCluster
          hiddenFields={{
            cluster_configuration: [`organization: ${organizationId}`].join('\n'),
          }}
        />
      </>
    );
  }

  return (
    <Form form={form} layout="vertical" data-testid="cluster-specificaton">
      {/* group selector */}
      {deployRegion && !deployRegion.is_edge_region && <GroupSelector />}

      {/* region */}
      <RegionSelector organizationId={organizationId} regions={regions} />

      {/* edge config */}
      {deployRegion && deployRegion.is_edge_region && (
        <EdgeConfiguration
          validateCustomBackupLocation={validateCustomBackupLocation}
        />
      )}

      {/* cloud config */}
      {deployRegion && !deployRegion.is_edge_region && (
        <CloudConfiguration clusters={clusters!} products={products!} />
      )}

      {/* cluster name */}
      <Form.Item
        label={
          <FormattedMessage id="deploy.clusterSpecification.clusterNameLabel" />
        }
        rules={[INPUT_SANITIZATION.CLUSTER_NAME]}
      >
        <Input
          onChange={e => setClusterName(e.target.value)}
          placeholder={formatMessage({
            id: 'deploy.clusterSpecification.clusterNamePlaceholder',
          })}
          value={clusterName as string}
          data-testid="cluster-name-input"
          onPressEnter={onNameSubmit}
        />
        {displayClusterNameStatus()}
      </Form.Item>

      <TestEventHandler
        fn={() => {
          setClusterName('-----');
          setClusterNameIsValid(false);
        }}
        testId="invalid-cluster-name-test-event-handler"
      />
      <TestEventHandler
        fn={() => {
          setClusterName('cluster');
          setClusterNameIsUnique(false);
        }}
        testId="duplicate-cluster-name-test-event-handler"
      />
    </Form>
  );
}

export default ClusterSpecification;
