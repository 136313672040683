export const locationAssign = (url: string) => {
  window.location.assign(url);
};

export const locationHostname = () => {
  return window.location.hostname;
};

export const locationPathname = () => {
  return window.location.pathname;
};

export const locationSearch = () => {
  return window.location.search;
};

export const locationPort = () => {
  return window.location.port;
};
