export const createFullClusterRestoreBackupSnippet = ({
  repository,
  snapshot,
}: {
  repository: string;
  snapshot: string;
}) =>
  `RESTORE SNAPSHOT ${repository}."${snapshot}" ALL WITH (wait_for_completion = true);`;

export const createTablesRestoreBackupSnippet = ({
  repository,
  snapshot,
  tables,
}: {
  repository: string;
  snapshot: string;
  tables: string[];
}) => {
  const quotedTables = tables.map(table => {
    const parts = table.split('.');
    if (parts.length === 2) {
      const [schema, tableName] = parts;
      return `"${schema}"."${tableName}"`;
    } else {
      return `"${parts[0]}"`;
    }
  });

  return `RESTORE SNAPSHOT ${repository}."${snapshot}" TABLE ${quotedTables.join(
    ', ',
  )} WITH (wait_for_completion = true);`;
};
