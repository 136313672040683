import { DownOutlined, UpOutlined } from '@ant-design/icons';
import { useState, useRef } from 'react';
import cx from 'classnames';

export type NumberStepperProps = {
  initialValue: number;
  min: number;
  max: number;
  step: number;
  id: string;
  onValueChange: (input: number) => void;
  formatter: (input: number) => number;
  parser: (input: number) => number;
  testId?: string;
};

function NumberStepper({
  initialValue,
  min,
  max,
  step,
  id,
  onValueChange,
  formatter,
  parser,
  testId = 'stepper-input',
}: NumberStepperProps) {
  const numberInput = useRef<HTMLInputElement>(null);
  const [value, setValue] = useState(Math.round(initialValue));
  const [isValid, setIsValid] = useState(true);

  const updateValidity = () => {
    if (
      !numberInput.current ||
      numberInput.current?.value === '' ||
      Number.isNaN(Number(numberInput.current?.value))
    ) {
      setIsValid(false);
      return;
    }

    const inputValue = parseInt(numberInput.current.value, 10);

    if (
      inputValue < formatter(Math.round(min)) ||
      inputValue > formatter(Math.round(max))
    ) {
      setIsValid(false);
      return;
    }

    if ((inputValue - formatter(Math.round(min))) % Math.round(step) > 0) {
      setIsValid(false);
      return;
    }

    setIsValid(true);
    onValueChange(parser(inputValue));
    setValue(parser(inputValue));
  };

  const updateManual = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(parseInt(e.target.value, 10));
    updateValidity();
  };

  return (
    <div
      className={cx(
        'border-2',
        'border-crate-border-light',
        'flex',
        'overflow-hidden',
        'rounded',
        'w-[100px]',
        {
          'bg-white': isValid,
          'bg-red-600': !isValid,
        },
      )}
    >
      <input
        type="number"
        value={isValid ? formatter(value) : value}
        min={formatter(min)}
        max={formatter(max)}
        step={step}
        ref={numberInput}
        onChange={updateManual}
        data-testid={testId}
        id={id}
        style={{ MozAppearance: 'textfield' }} // this must be kept inline for firefox
        className={cx(
          'bg-transparent',
          'border-0',
          'flex-auto',
          'mx-1.5',
          'my-0.5',
          'outline-0',
          'w-[60px]',
          { 'text-black': isValid, 'text-white': !isValid },
        )}
      />
      <div className="flex flex-col border-l border-crate-border-light">
        <button
          onClick={() => {
            numberInput.current?.stepUp();
            updateValidity();
          }}
          data-testid="step-up-button"
          type="button"
          className="align-center flex h-[17px] w-[22px] cursor-pointer items-center justify-center border-0 border-b border-crate-border-light bg-white"
        >
          <UpOutlined className="text-xs" />
        </button>
        <button
          onClick={() => {
            numberInput.current?.stepDown();
            updateValidity();
          }}
          data-testid="step-down-button"
          type="button"
          className="align-center flex h-[17px] w-[22px] cursor-pointer items-center justify-center border-0 bg-white"
        >
          <DownOutlined className="text-xs" />
        </button>
      </div>
    </div>
  );
}

export default NumberStepper;
