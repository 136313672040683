import { Form, Input } from 'antd';
import { CreateImportJobS3Body } from './form';
import { Button } from '@cratedb/crate-gc-admin';
import { FormattedMessage, useIntl } from 'react-intl';
import SecretSelect from '../../common/SecretSelect/SecretSelect';
import { SECRET_TYPES } from 'src/constants/defaults';
import { useParams } from 'react-router-dom';
import { FIELD_NAMES } from '../../constants';
import ConstrainStepWidth from '../../../../../components/StepLayout/ConstrainStepWidth';
import INPUT_SANITIZATION from '../../../../../constants/inputSanitization';

export type ConnectionDetailsStepProps = {
  defaultValues: CreateImportJobS3Body;
  onNext: (result: CreateImportJobS3Body) => void;
};

function ConnectionDetailsStep({
  defaultValues,
  onNext,
}: ConnectionDetailsStepProps) {
  const { organizationId } = useParams();
  const { formatMessage } = useIntl();
  const [form] = Form.useForm<CreateImportJobS3Body>();

  const onFormFinish = (values: CreateImportJobS3Body) => {
    onNext(values);
  };

  return (
    <ConstrainStepWidth>
      <Form<CreateImportJobS3Body>
        autoComplete="off"
        form={form}
        initialValues={{
          ...defaultValues,
        }}
        layout="vertical"
        name="import-data-from-s3-connection-form"
        id="import-data-from-s3-connection-form"
        aria-label="import-data-from-s3-connection-form"
        onFinish={onFormFinish}
        requiredMark="optional"
      >
        {/* secret */}
        <Form.Item
          className="w-full"
          label={
            <FormattedMessage id="cluster.clusterImportFile.connectionDetailsStep.secretField" />
          }
          name={[FIELD_NAMES.S3_NAMESPACE, FIELD_NAMES.S3_SECRET_ID]}
          rules={[
            {
              required: true,
              message: formatMessage({
                id: 'cluster.clusterImportFile.secretRequiredText',
              }),
            },
          ]}
        >
          <SecretSelect type={SECRET_TYPES.AWS} organizationId={organizationId!} />
        </Form.Item>

        {/* endpoint */}
        <Form.Item
          className="col-span-2"
          colon={false}
          label={
            <FormattedMessage id="cluster.clusterImportFile.connectionDetailsStep.endpointField" />
          }
          name={[FIELD_NAMES.S3_NAMESPACE, FIELD_NAMES.S3_ENDPOINT]}
          rules={[{ ...INPUT_SANITIZATION.GENERIC_URL, required: false }]}
        >
          <Input
            placeholder={formatMessage({
              id: 'cluster.clusterImportFile.connectionDetailsStep.endpointPlaceholder',
            })}
          />
        </Form.Item>
        <div className="flex">
          <Button type={Button.types.SUBMIT}>
            <FormattedMessage id="common.next" />
          </Button>
        </div>
      </Form>
    </ConstrainStepWidth>
  );
}

export default ConnectionDetailsStep;
