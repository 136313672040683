import cx from 'classnames';
import { Radio } from 'antd';
import { Text } from '@cratedb/crate-gc-admin';

export type RichRadioInputProps = {
  ariaControls?: string;
  checked: boolean;
  description: React.ReactNode;
  disabled?: boolean;
  disabledDescription?: React.ReactNode;
  onClick: () => void;
  title: React.ReactNode;
  value: number | string;
};

function RichRadioInput({
  ariaControls,
  checked,
  description,
  disabled = false,
  disabledDescription,
  onClick,
  title,
  value,
}: RichRadioInputProps) {
  return (
    <div className="mb-6 flex flex-1 items-center rounded-lg border border-neutral-300 px-6 py-4">
      <Radio
        aria-controls={ariaControls}
        checked={checked}
        disabled={disabled}
        onClick={onClick}
        value={value}
      >
        <div className="sr-only">{title}</div>
      </Radio>
      <div>
        <Text
          className={cx('font-bold', 'block', {
            'text-neutral-400': disabled,
          })}
        >
          {title}
        </Text>
        <Text pale>
          {disabled && disabledDescription ? disabledDescription : description}
        </Text>
      </div>
    </div>
  );
}

export default RichRadioInput;
