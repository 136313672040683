import cloudUISyntaxHighlighterEN from './CloudUISyntaxHighlighter/messages.en';
import cloudUITableEN from './CloudUITable/messages.en';
import clusterCredentialsEN from './ClusterCredentials/messages.en';
import clusterHealthEN from './ClusterHealth/messages.en';
import customerFormEN from './CustomerForm/messages.en';
import errorScreenEN from './ErrorScreen/messages.en';
import featureDetectionEN from './FeatureDetectProvider/messages.en';
import jwtManagerWrapperEN from './JWTManagerWrapper/messages.en';
import hubspotFormsEN from './HubspotForms/messages.en';
import paymentEntryFormEN from './PaymentEntryForm/messages.en';
import paymentMethodsEN from './PaymentMethods/messages.en';
import pricingBreakdownEN from './PricingBreakdown/messages.en';
import showTemporaryCredentialsEN from './ShowTemporaryCredentials/messages.en';
import sideMenuEN from './SideMenu/messages.en';

const messages = {
  ...cloudUISyntaxHighlighterEN,
  ...cloudUITableEN,
  ...clusterCredentialsEN,
  ...clusterHealthEN,
  ...customerFormEN,
  ...errorScreenEN,
  ...featureDetectionEN,
  ...jwtManagerWrapperEN,
  ...hubspotFormsEN,
  ...paymentEntryFormEN,
  ...paymentMethodsEN,
  ...pricingBreakdownEN,
  ...showTemporaryCredentialsEN,
  ...sideMenuEN,
};

export default messages;
