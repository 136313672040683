import React from 'react';
import { SQLConsole } from '@cratedb/crate-gc-admin';
import JWTManagerWrapper from 'src/components/JWTManagerWrapper';
import { useAnalytics } from '../../../hooks';
import SectionContainer from '../../../components/SectionContainer';
import { USER_TRACKING_EVENTS } from '../../../constants/segment';

function ClusterSQL() {
  const { trackEvent } = useAnalytics();

  const onQuery = () => {
    trackEvent(USER_TRACKING_EVENTS.CLICKED_SQL_CONSOLE_QUERY);
  };

  const onViewHistory = () => {
    trackEvent(USER_TRACKING_EVENTS.CLICKED_SQL_CONSOLE_HISTORY);
  };

  return (
    <JWTManagerWrapper>
      <SectionContainer className="absolute h-full w-full rounded-none !p-0">
        <SQLConsole onQuery={onQuery} onViewHistory={onViewHistory} />
      </SectionContainer>
    </JWTManagerWrapper>
  );
}

export default ClusterSQL;
