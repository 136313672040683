import {
  CrateTabsShad,
  Heading,
  NodesMetrics,
  StatsUpdater,
  TablesShardsMetrics,
} from '@cratedb/crate-gc-admin';
import { FormattedMessage } from 'react-intl';
import JWTManagerWrapper from 'src/components/JWTManagerWrapper';
import SectionContainer from 'src/components/SectionContainer';

const tabs = [
  {
    label: <FormattedMessage id="clusterMonitoring.tablesTab" />,
    key: 'tables',
    content: <TablesShardsMetrics />,
  },
  {
    label: <FormattedMessage id="clusterMonitoring.nodesTab" />,
    key: 'nodes',
    content: (
      <div className="mt-4">
        <NodesMetrics />
      </div>
    ),
  },
];

function ClusterMonitoring() {
  return (
    <JWTManagerWrapper>
      <SectionContainer className="absolute !mb-0 h-full w-full overflow-x-auto rounded-none !pb-0">
        <Heading className="mb-2" level={Heading.levels.h2}>
          <FormattedMessage id="clusterMonitoring.clusterMonitoring" />
        </Heading>
        <div className="grow overflow-hidden">
          <CrateTabsShad items={tabs} stickyTabBar />
        </div>
      </SectionContainer>
      <StatsUpdater />
    </JWTManagerWrapper>
  );
}

export default ClusterMonitoring;
